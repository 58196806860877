
import { defineComponent, inject, reactive, ref, computed } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers, invoiceHelpers, paymentHelpers } from '@/modules/helpers'
import getInvoicesQuery from '@/graphql/invoice/gets.gql'

import searchInvoicesQuery from '@/graphql/invoice/search.gql'
import ElementUpdateModal from '@/components/modals/InvoiceUpdate.vue'
import ElementDisplay from '@/components/element_displays/Invoice.vue'

import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Invoices',
  components: {
    ElementUpdateModal,
    ElementDisplay
  },
  setup() {
    // get Global properties
    const emitter = inject('$emitter') as Emitter

    // Invoices list
    const invoiceListPaging = reactive({
      start: 0,
      limit: 100,
      max: 0
    })
    const { result: getInvoicesResult, loading: getInvoicesLoading, error: getInvoicesError, refetch: refetchInvoices } = useQuery(getInvoicesQuery, () => ({start: invoiceListPaging.start, limit: invoiceListPaging.limit}))

    const invoices = useResult(getInvoicesResult, null, data => data.invoices)
    const invoicesCount = useResult(getInvoicesResult, null, data => data.invoicesConnection.aggregate.count)
    invoiceListPaging.max = invoicesCount as any

    emitter.on('invoices-refresh', () => {
      refetchInvoices()
    })

    // Search invoices
    const searchListPaging = reactive({
      max: 0,
      searchTerm: ref('')
    })
    const searchInvoicesOptions = reactive({enabled : false})
    const { result: _searchInvoices } = useQuery(searchInvoicesQuery, searchListPaging, searchInvoicesOptions)
    const searchedInvoices = useResult(_searchInvoices, null, data => data.invoices)

    const searchInvoices = (event: KeyboardEvent) => {
      searchInvoicesOptions.enabled = true
      console.log(searchListPaging.searchTerm)
    }

    // Invoice ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id 
      emitter.emit("open-modal-invoice-update")
    }

    const listToDisplay = computed(() => {
      if(searchListPaging.searchTerm.length > 1)
        return searchedInvoices
      else
        return invoices
    })

    return {
      updateId,
      setupEditModal,

      invoices,
      invoicesCount,
      getInvoicesLoading,
      getInvoicesError,
      
      searchInvoices,
      searchedInvoices,
      searchListPaging,

      invoiceListPaging,
      listToDisplay,

      helpers, userHelpers, invoiceHelpers, paymentHelpers
    }
  }
})
