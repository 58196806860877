
import { defineComponent } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import getCompanyInfosQuery from '@/graphql/companyInformation/get.gql'
import LocaleSwitch from '../components/LocaleSwitch.vue'

export default defineComponent({
  name: 'Home',
  components: { LocaleSwitch },
  setup() {
    ////////// Field : CompanyInfos - Gets
    const { result: getCompanyInfosResult } = useQuery(getCompanyInfosQuery)
    const companyInfos = useResult(getCompanyInfosResult, null, data => data['companyInformation'])

    return {
      companyInfos
    }
  }
})
