
import { defineComponent } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getManagerHomeDataQuery from '@/graphql/views/get_ManagerHomeData.gql'
import SumUp_Authorize from '@/graphql/sumupCalls/authorize.gql'
import SumUp_getProfile from '@/graphql/sumupCalls/getProfile.gql'
import SumUp_getPayments from '@/graphql/sumupCalls/getPayments.gql'
import SumUp_getPayoutSettings from '@/graphql/sumupCalls/getPayoutSettings.gql'
import SumUp_createCheckout from '@/graphql/sumupCalls/createCheckout.gql'

export default defineComponent({
  name: 'User',
  setup() {

    const date = new Date()
    const lastMonth = new Date(date.getTime() - (30*86400000))

    ////////// GET AUTHENTICATED USER
    const { result: getManagerHomeData } = useQuery(getManagerHomeDataQuery, () => ({recentThreshold: helpers.dateobjToISO(lastMonth)}))
    const userCount = useResult(getManagerHomeData, null, data => data.userCount.aggregate.count)
    const userRecentCount = useResult(getManagerHomeData, null, data => data.userRecentCount.aggregate.count)
    const invoiceCount = useResult(getManagerHomeData, null, data => data.invoiceCount.aggregate.count)
    const invoiceRecentCount = useResult(getManagerHomeData, null, data => data.invoiceRecentCount.aggregate.count)
    const paymentCount = useResult(getManagerHomeData, null, data => data.paymentCount.aggregate.count)
    const paymentRecentCount = useResult(getManagerHomeData, null, data => data.paymentRecentCount.aggregate.count)
    const appointmentCount = useResult(getManagerHomeData, null, data => data.appointmentCount.aggregate.count)
    const appointmentRecentCount = useResult(getManagerHomeData, null, data => data.appointmentRecentCount.aggregate.count)

    //const { result: sumupAuthorize} = useQuery(SumUp_Authorize)

    //const { result: sumupProfile } = useQuery(SumUp_getProfile)

    //const { result: sumupPayments } = useQuery(SumUp_getPayments)

    //const { result: sumupPayoutSettings } = useQuery(SumUp_getPayoutSettings)
    
    //const { result: sumupcreateCheckout } = useQuery(SumUp_createCheckout)

    console.log(userCount)

    return {
      //managerHomeData,
      userCount, userRecentCount, invoiceCount, invoiceRecentCount, paymentCount, paymentRecentCount, appointmentCount, appointmentRecentCount,
      //sumupAuthorize,
      //sumupProfile,
      //sumupPayments,
      //sumupPayoutSettings
      //sumupcreateCheckout
    }
  }
});
