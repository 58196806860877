
import { defineComponent, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult } from '@vue/apollo-composable'

import getCurrenciesQuery from '@/graphql/currency/gets.gql'
import getProductCategoriesQuery from '@/graphql/productCategory/gets.gql'

export default defineComponent({
  name: 'ProductForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const { t, locale } = useI18n({ useScope: 'global' })

    const formRef = ref<null | HTMLFormElement>(null)
    
    const form: { [Key: string]: any } = reactive({
      name:'',
      price: 0,
      type: 'card',
      productCategoryId: 1,
      currencyId: 1
    })

    // Fields
    ////////// Field : Currency - Gets
    const { result: getCurrenciesResult } = useQuery(getCurrenciesQuery)
    const currencies = useResult(getCurrenciesResult, null, data => data.currencies)

    ////////// Field : ProductCategory - Gets
    const { result: getProductCategoriesResult } = useQuery(getProductCategoriesQuery)
    const productCategories = useResult(getProductCategoriesResult, null, data => data.productCategories)


    const validate = () => {
      if(formRef.value != null)
        return formRef.value.validateForm(form)
    }

    // Testing purposes
    const fillForm = () => {
      console.log("Signup 'Fill Form' button clicked.")
      // With validation
      form.first_name.model= 'a_test_first_name'
      form.second_name.model = 'test_second_name'
      form.last_name.model = 'a_test_last_name'
    }

    return {
      form,
      formRef,

      currencies,
      productCategories,

      fillForm,
      validate
    }
  }
});
