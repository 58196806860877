<template>
  <JbCForm :validation="form" ref="formRef">
    <div class="form-row">
      <label :for="'name-'+$.uid">{{ $t('ui.product.fields.name') }}</label>
      <div><input type="text" :id="'name-'+$.uid" name="name" v-model.number="form.name" :placeholder="$t('ui.product.fields.name')"></div>
    </div>
    <div class="form-row">
      <label :for="'category-'+$.uid">{{ $t('ui.product.fields.category') }}</label>
      <div>
        <select v-if="productCategories" :id="'category-'+$.uid" v-model="form.productCategoryId">
          <option value="">No category</option>
          <option v-for="productCategory of productCategories" :key="productCategory" :value="productCategory.id">
            {{ productCategory.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label :for="'type-'+$.uid">{{ $t('ui.product_type.name') }}</label>
      <div>
        <select :id="'type-'+$.uid" v-model="form.type">
          <option value="consultation">{{ $t('ui.product_type.consultation') }}</option>
          <option value="card">{{ $t('ui.product_type.card') }}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label :for="'price-'+$.uid">{{ $t('ui.product.fields.price') }}</label>
      <div><input type="text" :id="'price-'+$.uid" name="price" v-model.number="form.price" :placeholder="$t('ui.product.fields.price')"></div>
    </div>
    <div class="form-row">
      <label :for="'currencyID-'+$.uid">{{ $t('ui.product.fields.currency') }}</label>
      <div>
        <select v-if="currencies" :id="'currencyID-'+$.uid" v-model="form.currencyId">
          <option v-for="currency of currencies" :key="currency" :value="currency.id">
            {{ currency.name }} ({{ currency.symbol }})
          </option>
        </select>
      </div>
    </div>
    
  </JbCForm>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult } from '@vue/apollo-composable'

import getCurrenciesQuery from '@/graphql/currency/gets.gql'
import getProductCategoriesQuery from '@/graphql/productCategory/gets.gql'

export default defineComponent({
  name: 'ProductForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const { t, locale } = useI18n({ useScope: 'global' })

    const formRef = ref<null | HTMLFormElement>(null)
    
    const form: { [Key: string]: any } = reactive({
      name:'',
      price: 0,
      type: 'card',
      productCategoryId: 1,
      currencyId: 1
    })

    // Fields
    ////////// Field : Currency - Gets
    const { result: getCurrenciesResult } = useQuery(getCurrenciesQuery)
    const currencies = useResult(getCurrenciesResult, null, data => data.currencies)

    ////////// Field : ProductCategory - Gets
    const { result: getProductCategoriesResult } = useQuery(getProductCategoriesQuery)
    const productCategories = useResult(getProductCategoriesResult, null, data => data.productCategories)


    const validate = () => {
      if(formRef.value != null)
        return formRef.value.validateForm(form)
    }

    // Testing purposes
    const fillForm = () => {
      console.log("Signup 'Fill Form' button clicked.")
      // With validation
      form.first_name.model= 'a_test_first_name'
      form.second_name.model = 'test_second_name'
      form.last_name.model = 'a_test_last_name'
    }

    return {
      form,
      formRef,

      currencies,
      productCategories,

      fillForm,
      validate
    }
  }
});
</script>


<style scoped lang="scss">

</style>