
import { defineComponent, inject, ref, reactive, watch, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import { toSearchListElement, searchValueNulllIfZero } from '@/modules/search.ts'
import getElementQuery from '@/graphql/payment/get.gql'
import updateElementMutation from '@/graphql/payment/update.gql'
import deleteElementMutation from '@/graphql/payment/delete.gql'

import PaymentForm from '@/components/forms/Payment.form.vue'

import getPaymentsQuery from '@/graphql/payment/gets.gql'

export default defineComponent({
  name: 'PaymentUpdate',
  props: ['elementId', 'listPaging'],
  components: {
    PaymentForm
  },
  setup(props) {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    const { t } = useI18n({ useScope: 'global' })

    const paymentFormRef = ref<null | any>(null)

    ////////// MODAL EDIT ID
    const editingElement = reactive({id: props.elementId})
    const editingElementOptions = reactive({enabled : false})
    const { result: getElement, loading: editingElementLoading, onResult: editingElement_onResult } = useQuery(getElementQuery, editingElement, editingElementOptions)
    const updateElementInfos = useResult(getElement, null, data => data.payment)

    watch(() => props.elementId, (newValue) => {
      //console.log(newValue)
      editingElement.id = newValue
      editingElementOptions.enabled = true
    })


    editingElement_onResult(queryResult => {
      const form = paymentFormRef.value.form

      if(queryResult.networkStatus === 7) { // 7 mean OK https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts
        //console.log("Element Fetch by id ", updateElementInfos.value)
        for (const [key] of Object.entries(updateElementInfos.value)) {
          if (key === 'invoice' && updateElementInfos.value[key] !== null) {
            paymentFormRef.value.selectedInvoice = updateElementInfos.value[key]
            form['invoiceId'] = updateElementInfos.value[key].id
            paymentFormRef.value.preselectedInvoice = toSearchListElement(updateElementInfos.value[key], 'invoice')
          } if (key === 'user' && updateElementInfos.value[key] !== null) {
            paymentFormRef.value.selectedUser = updateElementInfos.value[key]
            form['userId'] = updateElementInfos.value[key].id
            form['useInvoiceUser'] = false
            paymentFormRef.value.preselectedUser = toSearchListElement(updateElementInfos.value[key], 'user')
          } else if (key === 'currency') {
            form['currencyId'] = updateElementInfos.value[key].id
          } else { // The rest is automatically set
            form[key] = updateElementInfos.value[key]
          }
        }
      } else {
        console.log("Element Fetch by id failed.")
      }
    })

    ////////// UPDATE QUERY
    const { mutate: updatePayment, onDone: updatePaymentDone, onError: updatePaymentError } = useMutation(updateElementMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updatePaymentDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updatePaymentError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updatePaymentDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.payment.update.confirm'),
          text: ""+QR.data.updatePayment.payment.id,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-payment-update")
    })
    updatePaymentError(() => {
      console.log('Edit payment Error')
    })

    ////////// DELETE QUERY
    const { mutate: deleteMutation, onDone: deleteMutationDone, onError: deleteMutationError } = useMutation(deleteElementMutation)
    const removeStatus = reactive({
      success: false,
      error: false
    })
    deleteMutationDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.payment.delete.confirm'),
          text: ""+QR.data.deletePayment.payment.id,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-payment-update")
    })
    deleteMutationError(() => {
      removeStatus.error = true
      removeStatus.success = false
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const form = paymentFormRef.value.form

      const invoiceId = searchValueNulllIfZero(form.invoiceId)

      let userId = null
      if(form.useInvoiceUser === false) {
        userId = form.userId
      }

      const queryInput = { 
        input: { 
          where: {
            id: form.id,
          },
          data: {
            amount: form.amount,
            type: form.type,
            invoice: invoiceId,
            currency: form.currencyId,
            user: userId,
          }
        }
      }

      console.log(queryInput)

      updatePayment(queryInput)
    }

    ////////// MODAL DELETE BUTTON
    const deleteElement = () => {
      if (confirm(t('ui.element.delete.confirm'))) {
        const queryInput = { 
          input: { 
            where: {
              id: props.elementId,
            }
          }
        }

        //console.log(queryInput)

        deleteMutation(queryInput, {
          update: (cache, { data: { deletePayment: deleteMutation } }) => { // Needs to have the graphQL query name
            const data: any = cache.readQuery({ query: getPaymentsQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit} })

            if(data) { // If we are on a page where getPaymentsQuery has been used
              let paymentsClone = [...data.payments]
              paymentsClone = paymentsClone.filter((t: { id: number }) => {
                return t.id !== deleteMutation.payment.id;
              })

              cache.writeQuery({ query: getPaymentsQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit}, data: {
                payments: paymentsClone
              }})
            }
          }
        })
      }
    }

    // Testing purposes
    const fillForm = () => {
      console.log("vui")
      //const testDate = new Date()
      /*form.first_name = form.first_name+'testUpdate'
      form.second_name = form.second_name+'testUpdate'
      form.last_name = form.last_name+'testUpdate'
      form.birth_name = form.birth_name+'testUpdate'
      form.username = form.username+'testUpdate'
      form.email = form.email+'testUpdate'
      form.gender = 'other'*/
    }

    return {
      paymentFormRef,
      submit_modal,
      deleteElement,
      fillForm,
      updateStatus,

      helpers, userHelpers
    }
  }
});
