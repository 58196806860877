
import { defineComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useMutation, useResult } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
//import { productHelpers } from '@/modules/productHelpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'
import createElementMutation from '@/graphql/product/create.gql'
import getProductsQuery from '@/graphql/product/gets.gql'
import searchInvoicesQuery from '@/graphql/invoice/search.gql'
import getCurrenciesQuery from '@/graphql/currency/gets.gql'

import ProductForm from '@/components/forms/Product.form.vue'

export default defineComponent({
  name: 'ProductCreate',
  components: {
    ProductForm
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    
    const { t, locale } = useI18n({ useScope: 'global' })

    const form = reactive({
      id: '',
      name:'',
      price: 0,
      type: 'consultation',
      currencyId: 1
    })

    // Apollo Create Query 
    const { mutate: createElement, onDone: createElementDone, onError: createElementError } = useMutation(createElementMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })
    createElementDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createElementError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    createElementDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.product.create.confirm'),
          text: ""+QR.data.createProduct.product.name,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-product-create")
    })
    createElementError(() => {
      console.log('Create product Error')
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {

      const queryInput = { 
        input: { 
          data: {
            name: form.name,
            price: form.price,
            type: form.type,
            currency: form.currencyId,

            locale: locale.value
          }
        }
      }

      createElement(queryInput, {
        update: (cache, { data: { createProduct: createElement } }) => { // Needs to have the graphQL query name
          const data: any = cache.readQuery({ query: getProductsQuery, variables: { locale: locale.value } })

          if(data) { // If we are on a page where getProductsQuery has been used
            cache.writeQuery({ query: getProductsQuery, variables: { locale: locale.value }, data: {
              products: [createElement.product, ...data.products]
            }})
          }
        }
      })
    }

    // Testing purposes
    const fillForm = () => {
      form.name = "test_name"
      form.type = "card"
      form.price = 150
    }

    return {
      form,
      createElement,
      createStatus,

      submit_modal,
      fillForm,

      helpers
    }
  }
});
