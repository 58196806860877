<template>
  <JbPMain>
    <JbCToasts/>
    <JbUHeader>
      <div id="header-main-icon">
        <router-link to="/patient/home"><img src="img/favicons/android-chrome-192x192.png"/></router-link>
      </div>
      <!--<div class="sidebar-toggle-btn" id="right" @click="goBack"><font-awesome-icon icon="angle-left" /> Back</div>-->
      <div class="spacer">
      </div>
      <div class="sidebar-toggle-btn" id="left" @click="$JbStore.leftSidebar.toggle()"><font-awesome-icon icon="bars" /></div>
      
    </JbUHeader>

    <!--<JbCSidebar side="left" :toggle="$JbStore.leftSidebar.get()">
      <JbUMenuProfile :imageUrl="profile_picture_url" :name="firstName+' '+lastName" :role="role">
        <LocaleSwitch display="sidebar" style="margin-bottom:var(--default-gap);" />
        <router-link to="/logout"><JbUButton size="l" icon-left="sign-out-alt" fullwidth>{{ $t('ui.common.disconnect.self') }}</JbUButton></router-link>
      </JbUMenuProfile>
      <JbCMenu>
        <li><router-link to="/patient/appointments"><font-awesome-icon icon="calendar-check" />{{ $t('ui.appointment.patient.name') }}</router-link></li>
        <li><router-link to="/patient/invoices"><font-awesome-icon icon="file-invoice-dollar" />{{ $t('ui.invoice.patient.name') }}</router-link></li>
      </JbCMenu>
      <div style="padding:var(--space-4)">
        <JbUButton icon-left="plus" fullwidth color="teal">{{ $t('ui.appointment.patient.create') }}</JbUButton>
      </div>
    </JbCSidebar>-->

    <JbCMainContainer>
      <router-view />
    </JbCMainContainer>

    <!--<JbCSidebar side="right" :toggle="$JbStore.rightSidebar.get()">
      <div class="sidebar-section">
        <LocaleSwitch display="sidebar"/>
        <router-link to="/logout"><button style="height:60px; width:100%; justify-content:center"><font-awesome-icon icon="sign-out-alt" />{{ $t('ui.common.disconnect.self') }}</button></router-link>
      </div>
    </JbCSidebar>-->
  </JbPMain>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue'
import { Emitter } from 'mitt'
import { useRouter } from 'vue-router'
import { useQuery, useResult } from '@vue/apollo-composable'

export default defineComponent({
  name: 'Patient',
  setup(){
    // get emitter
    const emitter = inject('$emitter') as Emitter
    const contentManagerUrl = inject('$ContentManagerUrl')
    const router = useRouter()
    

    const firstName = ref(sessionStorage.getItem('first_name'))
    const lastName = ref(sessionStorage.getItem('last_name'))
    const role = ref(sessionStorage.getItem('role'))

    const profile_picture = sessionStorage.getItem('profile_picture')

    let profile_picture_url = ''
    if(profile_picture) {
      profile_picture_url = contentManagerUrl+profile_picture
    }

    const goBack = () => {
      router.go(-1)
    }

    return {
      emitter,
      goBack,

      firstName,
      lastName,
      role,
      profile_picture_url
    }
  }
})
</script>

<style scoped lang="scss">
.sidebar-section{
  padding:var(--space-5);

  .locale-switch {
    margin-bottom:var(--space-5);
  }
}
</style>