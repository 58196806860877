export default {

  productsToStrapi(productsArray: any[]): any[] {
    const convertedList: any[] = [] as any[]
    for (const product of productsArray) {
      convertedList.push({ product: product.product.id, quantity: product.quantity })
    }

    return convertedList
  },

  customProductsToStrapi(productsArray: any[]): any[] {
    const convertedList: any[] = [] as any[]
    for (const product of productsArray) {
      convertedList.push({ name: product.name, custom_text: product.custom_text, price: product.price, currency: product.currency.id, quantity: product.quantity})
    }

    return convertedList
  },

  computeTotal(productsArray: any[], options? : { custom_products?: boolean; discount_prc?: number; products_filter?: any[] }): number {
    let total = 0

    for (const product of productsArray) {
      if (options !== undefined && options.custom_products === true) {
        //let productTotal = product.price * product.quantity
        total += product.price * product.quantity
      } else {
        let productTotal = product.product.price * product.quantity
        if (options !== undefined && options.discount_prc !== undefined && options.products_filter !== undefined) {
          if (options.products_filter.includes(product.product.type)) {
            productTotal -= ((options.discount_prc / 100) * productTotal)
          }
        }
        total += productTotal
      }
    }

    return total
  },

  getNumber(invoice: { [key: string]: any }): string {
    return invoice.number_month + "-" + String(invoice.number_in_month).padStart(4, '0')
  }
  
}