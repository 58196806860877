<template>
  <JbUModal name="payment-create" cancellable>
    <template v-slot:headerLeft>
      <div v-if="$AppTesting" class="test-commands"><button @click.stop="fillForm">Fill Form</button></div>
      {{ $t('ui.payment.create.name') }}
    </template>
    <template v-slot:default>
      <PaymentForm ref="paymentFormRef" display="create" type="admin"/>
    </template>
    <template v-slot:footerLeft>
      <JbUAlert v-if="createStatus.error" type="error">{{ $t('ui.payment.create.error') }}</JbUAlert>
    </template>
    <template v-slot:footerRight>
      <button class="highlighted" @click="submit_modal">{{ $t('ui.payment.create.name') }}</button>
    </template>
  </JbUModal>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useMutation, useResult } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import { searchValueNulllIfZero } from '@/modules/search.ts'
import createElementMutation from '@/graphql/payment/create.gql'
import getPaymentsQuery from '@/graphql/payment/gets.gql'

import PaymentForm from '@/components/forms/Payment.form.vue'

export default defineComponent({
  name: 'PaymentCreate',
  components: {
    PaymentForm
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    
    const { t } = useI18n({ useScope: 'global' })

    const paymentFormRef = ref<null | any>(null)

    // Apollo Create Query 
    const { mutate: createElement, onDone: createElementDone, onError: createElementError } = useMutation(createElementMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })
    createElementDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createElementError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    createElementDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.payment.create.confirm'),
          text: ""+QR.data.createPayment.payment.amount,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-payment-create")
    })
    createElementError(() => {
      console.log('Create payment Error')
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const form = paymentFormRef.value.form

      const invoiceId = searchValueNulllIfZero(form.invoiceId)
      
      let userId = null
      if(form.useInvoiceUser === false) {
        userId = form.userId
      }

      const queryInput = { 
        input: { 
          data: {
            amount: form.amount,
            
            invoice: invoiceId,
            currency: form.currencyId,
            user: userId,

            type: form.type,
          }
        }
      }

      createElement(queryInput, {
        update: (cache, { data: { createPayment: createElement } }) => { // Needs to have the graphQL query name
          const data: any = cache.readQuery({ query: getPaymentsQuery, variables: {start: 0, limit:100} })

          if(data) { // If we are on a page where getPaymentsQuery has been used
            cache.writeQuery({ query: getPaymentsQuery, variables: {start: 0, limit:100}, data: {
              payments: [createElement.payment, ...data.payments]
            }})
          }

          $emitter.emit('invoices-refresh')
        }
      })
    }

    // Testing purposes
    const fillForm = () => {
      console.log('Test btn clicked')
    }

    return {
      paymentFormRef,
      helpers, userHelpers,
      createElement,
      createStatus,

      submit_modal,
      fillForm
    }
  }
});
</script>

<style scoped lang="scss">
</style>