<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Manager Home</p><br/>
    <p>From here the company secretaries could manage the data manually. After the features were implemented the plan was to have everything be automated to save as much of the secretaries' time as possible.</p><br/>
    <p>In the top right corner you can see a dropdown selector. It was a future-proof feature to organize data by location and have bookeeping separated. The database is structured accordingly.</p>
  </JbUAlert>
  <div class="header">
    <h1>{{ $t('ui.home.name') }}</h1>
  </div>
  <div class="row cards">
    <JbUCard class="w3" :title="$t('ui.user.name', 2)" icon="user" background="var(--color-blue-darker)" :number="userCount" :footer="$t('ui.common.last_30_days', [userRecentCount])">
      
    </JbUCard>
    <JbUCard class="w3" :title="$t('ui.invoice.name', 2)" icon="file-invoice-dollar" background="var(--color-orange-darker)" :number="invoiceCount" :footer="$t('ui.common.last_30_days', [invoiceRecentCount])">
      
    </JbUCard>
    <JbUCard class="w3" :title="$t('ui.payment.name', 2)" icon="dollar-sign" background="var(--color-pink-darker)" :number="paymentCount" :footer="$t('ui.common.last_30_days', [paymentRecentCount])">
      
    </JbUCard>
    <JbUCard class="w3" :title="$t('ui.appointment.name', 2)" icon="calendar-check" background="var(--color-lime-darker)" :number="appointmentCount" :footer="$t('ui.common.last_30_days', [appointmentRecentCount])">
      
    </JbUCard>
  </div>
  
  <!--<JbUPanel title="sumup" icon="dollar-sign">
    <button @click="sumupauth">TEST AUTH</button>
  </JbUPanel>-->
  
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getManagerHomeDataQuery from '@/graphql/views/get_ManagerHomeData.gql'
import SumUp_Authorize from '@/graphql/sumupCalls/authorize.gql'
import SumUp_getProfile from '@/graphql/sumupCalls/getProfile.gql'
import SumUp_getPayments from '@/graphql/sumupCalls/getPayments.gql'
import SumUp_getPayoutSettings from '@/graphql/sumupCalls/getPayoutSettings.gql'
import SumUp_createCheckout from '@/graphql/sumupCalls/createCheckout.gql'

export default defineComponent({
  name: 'User',
  setup() {

    const date = new Date()
    const lastMonth = new Date(date.getTime() - (30*86400000))

    ////////// GET AUTHENTICATED USER
    const { result: getManagerHomeData } = useQuery(getManagerHomeDataQuery, () => ({recentThreshold: helpers.dateobjToISO(lastMonth)}))
    const userCount = useResult(getManagerHomeData, null, data => data.userCount.aggregate.count)
    const userRecentCount = useResult(getManagerHomeData, null, data => data.userRecentCount.aggregate.count)
    const invoiceCount = useResult(getManagerHomeData, null, data => data.invoiceCount.aggregate.count)
    const invoiceRecentCount = useResult(getManagerHomeData, null, data => data.invoiceRecentCount.aggregate.count)
    const paymentCount = useResult(getManagerHomeData, null, data => data.paymentCount.aggregate.count)
    const paymentRecentCount = useResult(getManagerHomeData, null, data => data.paymentRecentCount.aggregate.count)
    const appointmentCount = useResult(getManagerHomeData, null, data => data.appointmentCount.aggregate.count)
    const appointmentRecentCount = useResult(getManagerHomeData, null, data => data.appointmentRecentCount.aggregate.count)

    //const { result: sumupAuthorize} = useQuery(SumUp_Authorize)

    //const { result: sumupProfile } = useQuery(SumUp_getProfile)

    //const { result: sumupPayments } = useQuery(SumUp_getPayments)

    //const { result: sumupPayoutSettings } = useQuery(SumUp_getPayoutSettings)
    
    //const { result: sumupcreateCheckout } = useQuery(SumUp_createCheckout)

    console.log(userCount)

    return {
      //managerHomeData,
      userCount, userRecentCount, invoiceCount, invoiceRecentCount, paymentCount, paymentRecentCount, appointmentCount, appointmentRecentCount,
      //sumupAuthorize,
      //sumupProfile,
      //sumupPayments,
      //sumupPayoutSettings
      //sumupcreateCheckout
    }
  }
});
</script>

<style scoped lang="scss">
</style>