
import { defineComponent, inject } from 'vue'
import store from '@/store'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LocaleSwitch',
  props: {
    display: String
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' })

    const changeLocale = (newLocale: string) => {
      localStorage.setItem('locale',newLocale)
      locale.value = newLocale
      store.commit('changeLocale', newLocale);
    }

    return {
      changeLocale,
      store
    }
  }
});
