<template>
  <JbPMain>
    <JbCMainContainer>
      <JbUAlert type="warning">
        <p>Welcome to the New Body Concept demo website.</p><br/>
        <p>As stated previously, this website was intended to be the basis of many great features for the company and its clients.</p><br/>
        <p>This demo is provided in the state the project was in when development stopped. Some features (mainly appointments) are half-implemented and will stay that way (unless I happen to find exactly the person who needs those features which is unlinkely to say the least).</p><br/>
        <p>I have inserted many orange boxes like this one through the demo to explain the different features it has. While creating data is kept enabled, the edition and deletion is disabled so it doesn't become too much of a mess.</p><br/>
        <p>Of course, all references to past clients's data have been removed and replaced with dummy data.</p><br/>
        <p>Enjoy!</p>
      </JbUAlert>
      <LocaleSwitch/>
      <div id="logo">
        <img v-if="companyInfos" :src="$ContentManagerUrl+companyInfos.logo.url"/>
      </div>
      <div id="buttons">
        <router-link to="/signin"><JbUCard icon="key" :number="$t('ui.common.connect.self')" background="rgb(196, 135, 43)"/></router-link>
        <router-link to="/signup"><JbUCard icon="plus" :number="$t('ui.home.new_user.create')" background="rgb(232, 160, 51)"/></router-link>
      </div>
    </JbCMainContainer>
  </JbPMain>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import getCompanyInfosQuery from '@/graphql/companyInformation/get.gql'
import LocaleSwitch from '../components/LocaleSwitch.vue'

export default defineComponent({
  name: 'Home',
  components: { LocaleSwitch },
  setup() {
    ////////// Field : CompanyInfos - Gets
    const { result: getCompanyInfosResult } = useQuery(getCompanyInfosQuery)
    const companyInfos = useResult(getCompanyInfosResult, null, data => data['companyInformation'])

    return {
      companyInfos
    }
  }
})
</script>

<style scoped lang="scss">
#logo {
  width:80%;
  margin:var(--space-9) auto;

  > img {
    width:80%;
    margin:0 auto;
  }
}
#buttons {
  display:flex;
  flex-flow: row wrap;
  justify-content:center;
  margin:var(--space-9) 0;
  column-gap:var(--space-6);
  row-gap:var(--space-6);

  > * {
    width:400px;
  }
}
</style>