import { createStore } from 'vuex'
// /!\ Local Storage : save everything as string

export default createStore({
  state: {
    locale: localStorage.getItem('locale'),
    cookiesAccepted: false,
    currentLocation: {},
  },
  getters: {
    cookiesAccepted(state) {
      return state.cookiesAccepted
    },
    locale(state) {
      if (state.locale === null) {
        return 'fr'
      } else {
        return state.locale
      }
    },
    currentLocation(state) {
      if (Object.keys(state.currentLocation).length !== 0) {
        return state.currentLocation
      } else if (localStorage.getItem('currentLocation')) {
        return JSON.parse(localStorage.getItem('currentLocation') as string)
      } else {
        return {name: '#'}
      }
    }
  },
  mutations: {
    acceptCookies(state, boolean) {
      state.cookiesAccepted = boolean
    },
    changeLocale(state, newLocale) {
      /*state.locale = newLocale.target.value
      localStorage.setItem('locale', newLocale.target.value)*/
      console.log(newLocale)
      state.locale = newLocale
      localStorage.setItem('locale', newLocale)
    },
    changeLocation(state, location) {
      state.currentLocation = location
      localStorage.setItem('currentLocation', JSON.stringify(location))
    }
  },
  actions: {
  },
  modules: {
  }
})