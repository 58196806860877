<template>

  <JbUAlert v-if="user && user.generation_method === 'import' && !isDate(user.username)" type="error">
    <p>{{ t('ui.user.imported_warning.sentence') }}</p>
    <router-link to="/patient/update-personal-infos" style="text-decoration:underline">{{ $t('ui.user.update.self.button') }}</router-link>
  </JbUAlert>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    user: Object
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })

    const isDate = (date: string) => {
      const a = Date.parse(date)
      if(isNaN(a))
        return false
      else
        return true
    }

    return {
      t,
      isDate
    }
  }
})
</script>

<style scoped lang="scss">
.jb-alert {
  margin-bottom: var(--space-5);
}
</style>
