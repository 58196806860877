<template>
  <template v-if="invoice">
    <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
      <p>Invoice</p><br/>
      <p>Every detail of an invoice.</p><br/>
      <p>A QR-code directing to this page is generated for each invoice so the client can scan his invoice and see more details about it online.</p>
    </JbUAlert>
    <div class="header">
      <h1>{{ invoice.description }}</h1> <div><button class="highlighted" @click="setupEditModal(invoice.id)">{{ $t('ui.invoice.update.name') }}</button></div>
    </div>
    <ElementDisplay v-if="invoice" :invoice="invoice" theme="full"/>
    <ElementUpdateModal :elementId="updateId"/>
  </template>
  <template v-else>
    {{ $t('ui.common.loading.name') }}
  </template>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useRoute } from 'vue-router'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getElementQuery from '@/graphql/invoice/get.gql'
import createMembershipMutation from '@/graphql/membership/create.gql'

import ElementUpdateModal from '@/components/modals/InvoiceUpdate.vue'
import ElementDisplay from '@/components/element_displays/Invoice.vue'

export default defineComponent({
  name: 'Invoice',
  components: {
    ElementUpdateModal,
    ElementDisplay
  },
  setup() {
    // Get global components
    const $emitter = inject('$emitter') as Emitter
    const route = useRoute()

    ////////// GET ELEMENT DATA WITH ITS ID
    const getElementParams = reactive({id: route.params.id})
    const { result: getElement, loading: editingElementLoading, onResult: editingElement_onResult } = useQuery(getElementQuery, getElementParams)
    const element = useResult(getElement, null, data => data.invoice)

    // Create a membership
    const { mutate: createMembership, onDone: createMembershipDone, onError: createMembershipError } = useMutation(createMembershipMutation)
    const createMembershipStatus = reactive({
      success: false,
      error: false
    })
    createMembershipDone(() => {
      createMembershipStatus.success = true
      createMembershipStatus.error = false
    })
    createMembershipError(() => {
      createMembershipStatus.error = true
      createMembershipStatus.success = false
    })

    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-invoice-update")
    }


    return {
      invoice: element,
      createMembership,

      updateId,
      setupEditModal,
    }
  }
})
</script>

<style scoped lang="scss">
</style>