<template>
  <template v-if="theme === undefined">
    <div class="generic-table separations">
      <div><div>{{ $t('ui.user.fields.profile_picture') }}</div><div><img v-if="user.profile_picture" style="width:100px;" :src="$ContentManagerUrl+(user.profile_picture ? user.profile_picture.url: '/default.jpg')"/></div></div>
      <div><div>{{ $t('ui.user.fields.language') }}</div><div>{{ $t('language.'+user.selected_locale+'.name') }}</div></div>
      <div><div>{{ $t('ui.user.fields.birthday') }}</div><div>{{ $d(new Date(user.birth_date), 'birthday') }}</div></div>
      <div><div>{{ $t('ui.user.fields.gender.name') }}</div><div>{{ $t('ui.user.fields.gender.'+user.gender) }}</div></div>
      <div><div>{{ $t('ui.user.fields.birth_name') }}</div><div>{{ user.birth_name }}</div></div>
      <div><div>{{ $t('ui.user.more.all_person_infos.is_therapist') }}</div><div>{{ user.is_therapist ? $t('ui.common.yes') : $t('ui.common.no') }}</div></div>
      <div><div>{{ $t('ui.user.fields.electronic_invoices') }}</div><div>{{ user.electronic_invoices ? $t('ui.common.yes') : $t('ui.common.no') }}</div></div>
      <div><div>{{ $t('ui.user.fields.medication') }}</div><div>{{ user.medication }}</div></div>
      <div><div>{{ $t('ui.user.fields.hear_about_us') }}</div><div>{{ user.hear_about_us }}</div></div>
      <div><div>{{ $t('ui.user.fields.recommendation') }}</div><div>{{ user.recommendation }}</div></div>
      <template v-if="user.contact_method.length > 0">
        <div v-for="contact_method of user.contact_method" :key="contact_method"><div>{{ $t('ui.contact_method.'+contact_method.type) }}</div><div>{{ contact_method.value }}</div></div>
      </template>
      <template v-if="user.address.length > 0">
        <div v-for="address of user.address" :key="address"><div>{{ $t('ui.address.name') }}</div><div>{{ address.street }}, {{ address.city }}</div></div>
      </template>
    </div>
  </template>
  <template v-if="theme === 'full'">
    <div class="row cards" style="margin-bottom:var(--default-gap);">
      <JbUPanel type="card" title="QR Code" icon="qrcode">
        <QRCodeVue3
          class="qrcode"
          :width="200"
          :height="200"
          :value="QRCodeLink"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4 }"
          :dotsOptions="{
            type: 'classy',
            color: '#000',
          }"
          image="img/favicons/android-chrome-192x192.png"
          :backgroundOptions="{ color: '#FFF' }"
          :cornersSquareOptions="{ type: 'extra-rounded', color: '#000' }"
          :cornersDotOptions="{ type: 'dot', color: '#000' }"
        />
      </JbUPanel>
      <JbUPanel type="card" :title="$t('ui.user.more.all_person_infos.title')" icon="user">
        <div class="generic-table separations">
          <div><div>{{ $t('ui.user.fields.profile_picture') }}</div><div><img v-if="user.profile_picture" style="width:100px;" :src="$ContentManagerUrl+(user.profile_picture ? user.profile_picture.url : '/default.jpg')"/></div></div>
          <div><div>{{ $t('ui.user.fields.language') }}</div><div>{{ $t('language.'+user.selected_locale+'.name') }}</div></div>
          <div><div>{{ $t('ui.user.fields.birthday') }}</div><div>{{ $d(new Date(user.birth_date), 'birthday') }}</div></div>
          <div><div>{{ $t('ui.user.fields.gender.name') }}</div><div>{{ $t('ui.user.fields.gender.'+user.gender) }}</div></div>
          <div><div>{{ $t('ui.user.fields.birth_name') }}</div><div>{{ user.birth_name }}</div></div>
          <div><div>{{ $t('ui.user.more.all_person_infos.is_therapist') }}</div><div>{{ user.is_therapist ? $t('ui.common.yes') : $t('ui.common.no') }}</div></div>
          <div><div>{{ $t('ui.user.fields.electronic_invoices') }}</div><div>{{ user.electronic_invoices ? $t('ui.common.yes') : $t('ui.common.no') }}</div></div>
          <div><div>{{ $t('ui.user.fields.medication') }}</div><div>{{ user.medication }}</div></div>
          <div><div>{{ $t('ui.user.fields.hear_about_us') }}</div><div>{{ user.hear_about_us }}</div></div>
          <div><div>{{ $t('ui.user.fields.recommendation') }}</div><div>{{ user.recommendation }}</div></div>
          <template v-if="user.contact_method.length > 0">
            <div v-for="contact_method of user.contact_method" :key="contact_method"><div>{{ $t('ui.contact_method.'+contact_method.type) }}</div><div>{{ contact_method.value }}</div></div>
          </template>
          <template v-if="user.address.length > 0">
            <div v-for="address of user.address" :key="address"><div>{{ $t('ui.address.name') }}</div><div>{{ address.street }}, {{ address.city }}</div></div>
          </template>
        </div>
      </JbUPanel>
      <JbUPanel type="card" v-if="user.membership" :title="$t('ui.membership.name')" icon="pen">
        <div class="generic-flexbox-column">
          <div><div>{{ $t('ui.membership.fields.type') }}</div><div>{{ user.membership.membership_type.name }}</div></div>
          <div><div>{{ $t('ui.membership.fields.balance') }}</div><div>{{ user.membership.balance }} {{ user.membership.currency.symbol }}</div></div>
        </div>
      </JbUPanel>
    </div>
    <div class="row cards">
      <JbUPanel type="card" v-if="user.relationships && user.relationships.length > 0" :title="$t('ui.relationship.name', 2)" icon="user">
        <div class="flex">
          <JbUCard v-for="relationship of user.relationships" :key="relationship" icon="user" :title="$t('ui.relationship.type.'+relationship.type)" :number="userHelpers.getFullName(relationship.user)"></JbUCard>
        </div>
      </JbUPanel>

      <JbUPanel type="card" v-if="user.invoices && user.invoices.length > 0" :title="$t('ui.invoice.name', 2)" icon="file-invoice-dollar">
        <JbUPanelList display="grid">
          <InvoiceListDisplay :invoices="user.invoices" :showAllInfos="false"/> 
        </JbUPanelList>
        <!--<button>Facture récapitulative</button>-->
      </JbUPanel>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { Emitter } from 'mitt'
import { helpers, userHelpers } from '@/modules/helpers'
import QRCodeVue3 from "qrcode-vue3"

import InvoiceListDisplay from '@/components/element_displays/Invoice_List.vue'

export default defineComponent({
  components: {
    InvoiceListDisplay,
    QRCodeVue3
  },
  props: {
    user: Object,
    theme: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    const QRCodeLink = "http://192.168.1.11:8081/administration/patients/"+props.user?.id

    return {
      QRCodeLink,
      helpers, userHelpers
    }

  }
})
</script>

<style lang="scss">
.qrcode {
  text-align:center;

  img {
    margin:var(--space-7) auto;
  }
}
</style>