<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Invoices</p><br/>
    <p>A list of all the invoices of the selected therapy center.</p><br/>
    <p>Here you can see, edit and verify all outgoing invoices and quickly check their payment status.</p><br/>
    <p>A PDF of the invoice can be generated and sent directly to the client if they want to. They can also check the invoice from their account.</p><br/>
    <p>The server detects if a membercard is invoiced, and add that amount to the client's membership card (see <router-link to="/administration/memberships" style="text-decoration: underline;">memberships</router-link> for full details).</p>
  </JbUAlert>
  <div class="header">
    <h1>{{ $t('ui.invoice.name', 2) }}</h1>
  </div>
  <JbUPanel :title="$t('ui.invoice.name', 2)" icon="file-invoice-dollar" id="invoices-list" :padding="0">
    <template v-slot:right>
      <input type="text" :placeholder="$t('ui.invoice.search.name')" v-model="searchListPaging.searchTerm" @keyup="searchInvoices"/>
    </template>
    <div v-if="getInvoicesError">Error: {{ getInvoicesError.message }}</div>
    <div v-if="invoices">
      <JbUPanelList display="grid" :class="getInvoicesLoading ? 'fetch-loading' : ''" :pagination="true" :pagingVar="invoiceListPaging" @updateStart="invoiceListPaging.start = $event">
      <div class="inline-infos header">
        <div></div>
        <div>{{ $t('ui.invoice.fields.number') }}</div>
        <div>{{ $t('ui.invoice.fields.description') }}</div>
        <div>{{ $t('ui.user.name') }}</div>
        <!--<div>{{ $t('ui.invoice.fields.date') }}</div>-->
        <div>{{ $t('ui.invoice.details.total') }} </div>
        <div class="actions">{{ $t('ui.list.actions.name') }}</div>
      </div>
      <template v-if="listToDisplay.value">
        <JbUPanelListRow v-for="invoice of listToDisplay.value" :key="invoice.id" editable :item-id="invoice.id" @edit-element="setupEditModal(invoice.id)">
          <template #inline-infos>
            <div>{{ invoice.total - paymentHelpers.getTotalPayments(invoice.payments) === 0 ? '✔️' : '❌' }}</div>
            <div>{{ invoiceHelpers.getNumber(invoice) }}</div>
            <div>{{ invoice.description }}</div>
            <div>{{ invoice.user ? userHelpers.getFullName(invoice.user) : 'No user' }}</div>
            <!--<div>{{ invoice.date }}</div>-->
            <div>{{ invoice.total }} {{ invoice.currency ? invoice.currency.symbol : "??" }}</div>
          </template>

          <template #all-infos>
            <ElementDisplay :invoice="invoice"/>
            <router-link :to="'/administration/invoices/'+invoice.id"><JbUAlert style="margin-top:var(--space-6);text-align:right;">{{ $t('ui.common.see_all') }} <font-awesome-icon icon="arrow-right"/></JbUAlert></router-link>
          </template>
        </JbUPanelListRow>
      </template>
      </JbUPanelList>
    </div>
  </JbUPanel>
  <ElementUpdateModal :elementId="updateId" :listPaging="invoiceListPaging"/>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers, invoiceHelpers, paymentHelpers } from '@/modules/helpers'
import getInvoicesQuery from '@/graphql/invoice/gets.gql'

import searchInvoicesQuery from '@/graphql/invoice/search.gql'
import ElementUpdateModal from '@/components/modals/InvoiceUpdate.vue'
import ElementDisplay from '@/components/element_displays/Invoice.vue'

import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Invoices',
  components: {
    ElementUpdateModal,
    ElementDisplay
  },
  setup() {
    // get Global properties
    const emitter = inject('$emitter') as Emitter

    // Invoices list
    const invoiceListPaging = reactive({
      start: 0,
      limit: 100,
      max: 0
    })
    const { result: getInvoicesResult, loading: getInvoicesLoading, error: getInvoicesError, refetch: refetchInvoices } = useQuery(getInvoicesQuery, () => ({start: invoiceListPaging.start, limit: invoiceListPaging.limit}))

    const invoices = useResult(getInvoicesResult, null, data => data.invoices)
    const invoicesCount = useResult(getInvoicesResult, null, data => data.invoicesConnection.aggregate.count)
    invoiceListPaging.max = invoicesCount as any

    emitter.on('invoices-refresh', () => {
      refetchInvoices()
    })

    // Search invoices
    const searchListPaging = reactive({
      max: 0,
      searchTerm: ref('')
    })
    const searchInvoicesOptions = reactive({enabled : false})
    const { result: _searchInvoices } = useQuery(searchInvoicesQuery, searchListPaging, searchInvoicesOptions)
    const searchedInvoices = useResult(_searchInvoices, null, data => data.invoices)

    const searchInvoices = (event: KeyboardEvent) => {
      searchInvoicesOptions.enabled = true
      console.log(searchListPaging.searchTerm)
    }

    // Invoice ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id 
      emitter.emit("open-modal-invoice-update")
    }

    const listToDisplay = computed(() => {
      if(searchListPaging.searchTerm.length > 1)
        return searchedInvoices
      else
        return invoices
    })

    return {
      updateId,
      setupEditModal,

      invoices,
      invoicesCount,
      getInvoicesLoading,
      getInvoicesError,
      
      searchInvoices,
      searchedInvoices,
      searchListPaging,

      invoiceListPaging,
      listToDisplay,

      helpers, userHelpers, invoiceHelpers, paymentHelpers
    }
  }
})
</script>