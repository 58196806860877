
import { defineComponent, inject, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import getPaymentsQuery from '@/graphql/payment/gets.gql'
import searchPaymentsQuery from '@/graphql/payment/search.gql'

import PaymentUpdateModal from '@/components/modals/PaymentUpdate.vue'

export default defineComponent({
  name: 'Payments',
  components: {
    PaymentUpdateModal
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    // Payments list
    const paymentsPaging = reactive({
      start: 0,
      limit: 100,
      max: 0,
      noPayment: false
    })

    const { result:getPaymentsQR, loading: getPaymentsLoading, error: getPaymentsError } = useQuery(getPaymentsQuery, () => ({start: paymentsPaging.start, limit: paymentsPaging.limit}))

    const payments = useResult(getPaymentsQR, null, data => data.payments)
    const paymentsCount = useResult(getPaymentsQR, null, data => data.paymentsConnection.aggregate.count)
    paymentsPaging.max = paymentsCount as any

    // Search payments
    const searchListPaging = reactive({
      max: 0,
      searchTerm: ref('')
    })
    const searchPaymentsOptions = reactive({enabled : false})
    const { result: _searchPayments } = useQuery(searchPaymentsQuery, searchListPaging, searchPaymentsOptions)
    const searchedPayments = useResult(_searchPayments, null, data => data.payments)

    const searchPayments = (event: KeyboardEvent) => {
      searchPaymentsOptions.enabled = true
      console.log(searchListPaging.searchTerm)
    }


    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-payment-update")
    }

    

    return {
      updateId,
      setupEditModal,

      payments,
      getPaymentsLoading,
      getPaymentsError,

      paymentsPaging,

      searchPayments,
      searchedPayments,
      searchListPaging,

      helpers, invoiceHelpers
    }

  }
})
