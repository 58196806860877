<template>
  <JbUModal name="user-update" cancellable>
    <template v-slot:headerLeft>
      <div v-if="$AppTesting" class="test-commands"><button @click.stop="fillForm">Fill Form</button></div>
      {{ $t('ui.user.update.name') }} {{ elementId }}
    </template>
    <template v-slot:headerRight>
      <button class="error" @click="deleteElement">{{ $t('ui.user.delete.name') }}</button>
    </template>
    <template v-slot:default>
      <UserForm ref="userFormRef" display="update" type="admin"/>
    </template>
    <template v-slot:footerLeft>
      <JbUAlert v-if="updateStatus.error" type="error" :timeout="5">{{ $t('ui.user.update.error') }}</JbUAlert>
    </template>
    <template v-slot:footerRight>
      <!--{{ userId }}-->
      <button class="highlighted" @click="submit_modal">{{ $t('ui.user.update.name') }}</button>
    </template>
  </JbUModal>
</template>

<script lang="ts">
import { defineComponent, inject, watch, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import { toSearchListElement } from '@/modules/search.ts'
import getElementQuery from '@/graphql/user/get.gql'
import updateElementMutation from '@/graphql/user/update.gql'
import deleteElementMutation from '@/graphql/user/delete.gql'

import UserForm from '@/components/forms/User.form.vue'

import getUsersQuery from '@/graphql/user/gets.gql'

export default defineComponent({
  name: 'UserUpdate',
  components: {
    UserForm
  },
  props: ['elementId', 'listPaging'],
  setup(props) {
     // get emitter
    const $emitter = inject('$emitter') as Emitter

    const { t, locale } = useI18n({ useScope: 'global' })

    const userFormRef = ref<null | any>(null)

    ////////// MODAL EDIT ID
    const editingElement = reactive({id: props.elementId})
    const editingElementOptions = reactive({enabled : false})
    const { result: getElement, onResult: editingElement_onResult } = useQuery(getElementQuery, editingElement, editingElementOptions)
    const updateElementInfos = useResult(getElement, null, data => data.user)
    
    watch(() => props.elementId, (newValue) => {
      //console.log(newValue)
      userFormRef.value.elementId = newValue
      editingElement.id = newValue
      editingElementOptions.enabled = true
    })

    editingElement_onResult(queryResult => {
      const form = userFormRef.value.form
      if(queryResult.networkStatus === 7) { // 7 mean OK https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts
        //console.log("Element Fetch by id ", updateElementInfos.value.id)
        for (const [key] of Object.entries(updateElementInfos.value)) {
          if(key === 'birth_date') {
            form[key] = new Date(updateElementInfos.value[key])
            form.birth_day.model = String(form[key].getDate()).padStart(2,'0')
            form.birth_month.model = String(form[key].getMonth()+1).padStart(2,'0')
            form.birth_year.model = form[key].getFullYear()
          } else if(key === 'address') {
            form.street.model = updateElementInfos.value[key][0].street
            form.street_extra.model = updateElementInfos.value[key][0].street_extra
            form.postal_code.model = updateElementInfos.value[key][0].postal_code
            form.city.model = updateElementInfos.value[key][0].city
            form.region.model = updateElementInfos.value[key][0].region
            if(updateElementInfos.value[key][0].country !== null)
              form.country.model = updateElementInfos.value[key][0].country.id
          } else if (key === 'relationships') {
            form[key] = helpers.unfreezeProducts(updateElementInfos.value[key])
            for(const relationship in form[key]) {
              console.log(userFormRef.value.preselectedUsers)
              //selectedUser.value = updateElementInfos.value[key]
              form[key][relationship].user = updateElementInfos.value[key][relationship].user.id
              userFormRef.value.preselectedUsers[relationship] = toSearchListElement(updateElementInfos.value[key][relationship].user, 'user')
            }
            console.log(form[key])
          } else { // The rest is automatically set
            if(form[key] !== undefined) {
              form[key].model = updateElementInfos.value[key]
            }
          }
        }
      } else {
        console.log("Element Fetch by id failed.")
      }
    })

    ////////// UPDATE QUERY
    const { mutate: updateMutation, onDone: updateMutationDone, onError: updateMutationError } = useMutation(updateElementMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updateMutationDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updateMutationError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updateMutationDone((QR) => {
      //console.log(QR)
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.user.update.confirm'),
          text: QR.data.updateUser.user.last_name+" "+QR.data.updateUser.user.first_name,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-user-update")
    })
    updateMutationError(() => {
      console.log('Edit user Error')
    })

    ////////// DELETE QUERY
    const { mutate: deleteMutation, onDone: deleteMutationDone, onError: deleteMutationError } = useMutation(deleteElementMutation)
    const removeStatus = reactive({
      success: false,
      error: false
    })
    deleteMutationDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.user.delete.confirm'),
          text: QR.data.deleteUser.user.last_name+" "+QR.data.deleteUser.user.first_name,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-user-update")
    })
    deleteMutationError(() => {
      removeStatus.error = true
      removeStatus.success = false
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const form = userFormRef.value.form

      const relationship_list = userHelpers.relationshipsToStrapi(form.relationships)

      //console.log(relationship_list)

      const queryInput = { 
        input: { 
          where: {
            id: props.elementId,
          },
          data: {
            first_name: form.first_name.model,
            second_name: form.second_name.model,
            last_name: form.last_name.model,
            birth_name: form.birth_name.model,
            job: form.job.model,
            phone_private: form.phone_private.model,
            cellphone: form.cellphone.model,
            email: form.email.model,
            problems: form.problems.model,
            medication: form.medication.model,
            hear_about_us: form.hear_about_us.model,
            recommendation: form.recommendation.model,
            selected_locale: locale.value,
            address: [{
              street: form.street.model,
              street_extra: form.street_extra.model,
              postal_code: form.postal_code.model,
              city: form.city.model,
              region:form.region.model,
              country: form.country.model
            }],

            // Without validation
            gender: form.gender.model,
            birth_date: form.birth_year.model+'-'+form.birth_month.model+'-'+form.birth_day.model,
            electronic_invoices: form.electronic_invoices.model,

            relationships: relationship_list
          }
        }
      }

      updateMutation(queryInput)
    }

    ////////// MODAL DELETE BUTTON
    const deleteElement = () => {
      if (confirm(t('ui.element.delete.confirm'))) {
        const queryInput = { 
          input: { 
            where: {
              id: props.elementId,
            }
          }
        }

        deleteMutation(queryInput, {
          update: (cache, { data: { deleteUser: deleteMutation } }) => { // Needs to have the graphQL query name
            const data: any = cache.readQuery({ query: getUsersQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit} })

            if(data) { // If we are on a page where getUsersQuery has been used
              let usersClone = [...data.users]
              usersClone = usersClone.filter((t: { id: number }) => {
                return t.id !== deleteMutation.user.id
              })

              cache.writeQuery({ query: getUsersQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit}, data: {
                users: usersClone
              }})
            }
          }
        })
      }
    }

    // Testing purposes
    const fillForm = () => {
      userFormRef.value.fillForm()
    }

    return {
      userFormRef,
      submit_modal,
      deleteElement,
      fillForm,
      updateStatus
    }
  }
});
</script>