<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Appointments</p><br/>
    <p>This is pretty much where things stopped.</p><br/>
    <p>This was planned for the next update but the next update never happened.</p><br/>
    <p>The plan was to have a calendar display the list of appointments and manually check/edit them. This view would also be useful to secretaries to plan the day.</p>
  </JbUAlert>
  <div class="header">
    <h1>{{ $t('ui.appointment.name', 2) }}</h1>
  </div>
  <JbUPanel :title="$t('ui.appointment.name', 2)" icon="file-invoice-dollar" id="appointments-list" :padding="0">
    <template v-slot:right>
      <input type="text" :placeholder="$t('ui.appointment.search.name')" v-model="searchListPaging.searchTerm" @keyup="searchAppointments"/>
    </template>
    <div v-if="getAppointmentsError">Error: {{ getAppointmentsError.message }}</div>
    <div v-if="appointments">
      <JbUPanelList display="grid" :class="getAppointmentsLoading ? 'fetch-loading' : ''" :pagination="true" :pagingVar="appointmentListPaging" @updateStart="appointmentListPaging.start = $event">
      <div class="inline-infos header">
        <div>{{ $t('ui.appointment.fields.date') }}</div>
        <div>{{ $t('ui.appointment.fields.start') }}</div>
        <div>{{ $t('ui.appointment.fields.end') }}</div>
        <div>{{ $t('ui.appointment.fields.patient') }}</div>
        <div class="actions">{{ $t('ui.list.actions.name') }}</div>
      </div>
      <template v-if="listToDisplay.value">
        <JbUPanelListRow v-for="appointment of listToDisplay.value" :key="appointment.id" editable :item-id="appointment.id" @edit-element="setupEditModal(appointment.id)">
          <template #inline-infos>
            <div>{{ appointment.date }}</div>
            <div>{{ appointment.start_time }}</div>
            <div>{{ appointment.end_time }}</div>
            <div>{{ appointment.patient ? userHelpers.getFullName(appointment.patient) : 'No patient' }}</div>
          </template>
          <template #all-infos>
            <router-link :to="'/administration/appointment/'+appointment.id"><JbUAlert style="margin-top:var(--space-6);text-align:right;">{{ $t('ui.common.see_all') }} <font-awesome-icon icon="arrow-right"/></JbUAlert></router-link>
          </template>
        </JbUPanelListRow>
      </template>
      </JbUPanelList>
    </div>
  </JbUPanel>
  <ElementUpdateModal :elementId="updateId" :listPaging="appointmentListPaging"/>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers, appointmentHelpers, paymentHelpers } from '@/modules/helpers'
import getAppointmentsQuery from '@/graphql/appointment/gets.gql'

import searchAppointmentsQuery from '@/graphql/appointment/search.gql'
import ElementUpdateModal from '@/components/modals/AppointmentUpdate.vue'
//import ElementDisplay from '@/components/element_displays/Appointment.vue'

import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Appointments',
  components: {
    ElementUpdateModal,
    //ElementDisplay
  },
  setup() {
    // get Global properties
    const emitter = inject('$emitter') as Emitter

    // Appointments list
    const appointmentListPaging = reactive({
      start: 0,
      limit: 100,
      max: 0
    })
    const { result: getAppointmentsResult, loading: getAppointmentsLoading, error: getAppointmentsError, refetch: refetchAppointments } = useQuery(getAppointmentsQuery, () => ({start: appointmentListPaging.start, limit: appointmentListPaging.limit}))

    const appointments = useResult(getAppointmentsResult, null, data => data.appointments)
    const appointmentsCount = useResult(getAppointmentsResult, null, data => data.appointmentsConnection.aggregate.count)
    appointmentListPaging.max = appointmentsCount as any

    emitter.on('appointments-refresh', () => {
      refetchAppointments()
    })

    // Search appointments
    const searchListPaging = reactive({
      max: 0,
      searchTerm: ref('')
    })
    const searchAppointmentsOptions = reactive({enabled : false})
    const { result: _searchAppointments } = useQuery(searchAppointmentsQuery, searchListPaging, searchAppointmentsOptions)
    const searchedAppointments = useResult(_searchAppointments, null, data => data.appointments)

    const searchAppointments = (event: KeyboardEvent) => {
      searchAppointmentsOptions.enabled = true
      console.log(searchListPaging.searchTerm)
    }

    // Appointment ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id 
      emitter.emit("open-modal-appointment-update")
    }

    const listToDisplay = computed(() => {
      if(searchListPaging.searchTerm.length > 1)
        return searchedAppointments
      else
        return appointments
    })

    return {
      updateId,
      setupEditModal,

      appointments,
      appointmentsCount,
      getAppointmentsLoading,
      getAppointmentsError,
      
      searchAppointments,
      searchedAppointments,
      searchListPaging,

      appointmentListPaging,
      listToDisplay,

      helpers, userHelpers, appointmentHelpers, paymentHelpers
    }
  }
})
</script>