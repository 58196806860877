
import { defineComponent, inject, computed, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { userHelpers } from '@/modules/helpers'
import getUsersQuery from '@/graphql/user/gets.gql'
import createUserMutation from '@/graphql/user/create.gql'
import deleteUserMutation from '@/graphql/user/delete.gql'
import createMembershipMutation from '@/graphql/membership/create.gql'
import searchUsersQuery from '@/graphql/user/search.gql'

import ElementUpdateModal from '@/components/modals/UserUpdate.vue'
import ElementDisplay from '@/components/element_displays/User.vue'

export default defineComponent({
  name: 'Users',
  components: {
    ElementUpdateModal,
    ElementDisplay
  },
  data() {
    return {
      usersToImport: [],
    }
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    // get Users list
    const usersPaging = reactive({
      start: 0,
      limit: 100,
      max: 100
    })

    const { result: getUsersResult, loading: getUsersLoading, error: getUsersError, refetch: getUsersRefetch } = useQuery(getUsersQuery, () => ({ start: usersPaging.start, limit: usersPaging.limit }))
    const users = useResult(getUsersResult, null, data => data.users)
    const usersCount = useResult(getUsersResult, null, data => data.usersConnection.aggregate.count)
    usersPaging.max = usersCount as any

    // Create a user (Those are only useful for the Bexio import function)
    const { mutate: createUser, onDone: createUserDone, onError: createUserError } = useMutation(createUserMutation)
    const createUserStatus = reactive({
      success: false,
      error: false
    })
    createUserDone(() => {
      createUserStatus.success = true
      createUserStatus.error = false
    })
    createUserError(() => {
      createUserStatus.error = true
      createUserStatus.success = false
    })

    // Create a membership
    const { mutate: createMembership, onDone: createMembershipDone, onError: createMembershipError } = useMutation(createMembershipMutation)
    const createMembershipStatus = reactive({
      success: false,
      error: false
    })
    createMembershipDone(() => {
      createMembershipStatus.success = true
      createMembershipStatus.error = false
    })
    createMembershipError(() => {
      createMembershipStatus.error = true
      createMembershipStatus.success = false
    })


    // Delete user
    const { mutate: deleteUser } = useMutation(deleteUserMutation)

    // Search users
    const searchListPaging = reactive({
      max: 0,
      searchTerm: ref('')
    })
    const searchUsersOptions = reactive({enabled : false})
    const { result: _searchUsers } = useQuery(searchUsersQuery, searchListPaging, searchUsersOptions)
    const searchedUsers = useResult(_searchUsers, null, data => data.users)

    const searchUsers = (event: KeyboardEvent) => {
      searchUsersOptions.enabled = true
      console.log(searchListPaging.searchTerm)
    }

    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-user-update")
    }

    const listToDisplay = computed(() => {
      if(searchListPaging.searchTerm.length > 2)
        return searchedUsers
      else
        return users
    })
    const zer = (event: any) => {
      console.log(event)
      usersPaging.start = event
    }


    return {
      createUser,
      createUserStatus,
      createMembership,

      updateId,
      setupEditModal,

      deleteUser,

      users,
      usersCount,
      getUsersLoading,
      getUsersError,
      getUsersRefetch,
      usersPaging,

      searchUsers,
      searchedUsers,
      searchListPaging,

      listToDisplay,

      userHelpers, zer
    }

  },
  methods: {
    async importUser(user: any) {
      const name1 = user['Name 1'] !== "" ? user['Name 1'] : ''
      const name2 = user['Name 2'] !== "" ? user['Name 2'] : ''

      const username = name1+user['Datensatz ID']

      let email = ''
      const contact_methods = []
      if(user['E-Mail'] !== "") {
        contact_methods.push({ type: 'email', value: user['E-Mail']})
        email = user['E-Mail']
      } else {
        email = username+'@nbc.com'
      }
      if(user['E-Mail 2'] !== "")
        contact_methods.push({ type: 'email', value: user['E-Mail 2']})
      if(user['Telefon'] !== "")
        contact_methods.push({ type: 'phone_number', value: user['Telefon'].toString()})
      if(user['Telefon 2'] !== "")
        contact_methods.push({ type: 'phone_number', value: user['Telefon 2'].toString()})
      if(user['Mobile'] !== "")
        contact_methods.push({ type: 'mobile_phone_number', value: user['Mobile'].toString()})
      if(user['Fax'] !== "")
        contact_methods.push({ type: 'fax', value: user['Fax'].toString()})
      if(user['Website'] !== "")
        contact_methods.push({ type: 'website', value: user['Website']})

      let country = 0
      let selected_locale = ''
      if(user['Land'] !== ""){
        if(user['Land'] === "Schweiz") {
          country = 1 // Suisse dans la base de données
          selected_locale = 'de'
        } else if (user['Land'] === 'Österreich') {
          country = 4 // Autriche dans la base de données
          selected_locale = 'de'
        } else if (user['Land'] === 'Deutschland') {
          country = 3 // Allemagne dans la base de données
          selected_locale = 'de'
        } else if (user['Land'] === 'Frankreich') {
          country = 2 // France dans la base de données
          selected_locale = 'fr'
        } else if (user['Land'] === 'USA') {
          country = 5 // USA dans la base de données
          selected_locale = 'en'
        }
      }



      const street = user['Adresse'] !== "'" ? user['Adresse'].substring(1) : ''
      const postalCode = user['PLZ'] !== "" ? String(user['PLZ']) : '0'



      let birth_date: null | string = null
      if(user['Geburtstag'] !== "") {
        birth_date = user['Geburtstag']//.substring(1) Because Bexio export had an extra unneeded char
        if(birth_date) {
          const dates = birth_date.split('.')
          birth_date = dates[2]+"-"+dates[1]+"-"+dates[0]
        }
      }

      let gender: null|string = null
      if(user['Anrede'] !== "" && user['Anrede'] == 'Herr') {
        gender = 'male'
      } else if (user['Anrede'] !== "" && user['Anrede'] == 'Frau') {
        gender = 'female'
      }

      
      let membershipType: null | string = null

      if(user['Kategorie'].includes("Member 2")) {
        membershipType = '1'
      } else if(user['Kategorie'].includes("Member 3")) {
        membershipType = '2'
      } else if(user['Kategorie'].includes("Member 4")) {
        membershipType = '3'
      }

      const userQueryInput = { 
        input: { 
          data: {
            username: username,
            email: email,
            password: email,
            created_by_user: sessionStorage.getItem('id'),
            generation_method: 'import',
            contact_method: contact_methods,
            last_name: name1,
            first_name: name2,
            birth_date: birth_date,
            gender: gender,
            address: [{street: street, postal_code: postalCode, city: user['Ort'], country: country }],
            selected_locale: selected_locale,
            confirmed: true,
          }
        }
      }
      
      //console.log(userQueryInput)
      const createUserResult = await this.createUser(userQueryInput).catch(
        (err) => {
          console.log(err);
        }
      )

      if(membershipType && createUserResult) {
        //console.log(membershipType)
        const membershipQueryInput = { 
          input: { 
            data: {
              balance: 0,
              membership_type: membershipType,
              user: createUserResult.data.createUser.user.id,
              currency: 1 // CHF by default
            }
          }
        }
        this.createMembership(membershipQueryInput)
      }
      
    },
    async importUsers() {
      if(this.usersToImport.length > 0) {
        for (let i = 0; i < this.usersToImport.length /*55*/; ++i) {
          const user = this.usersToImport[i]
          //console.log(await this.importUser(user))
          await this.importUser(user)
        }
      }
    },

    deleteImportedUsers() {
      for (let i = 0; i < this.users.length; ++i) {
        const queryInput = { 
          input: { 
            where: {
              id: this.users[i].id
            }
          }
        }
        this.deleteUser(queryInput)
      }
    },

  }
});
