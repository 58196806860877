<template>
  <JbPMain>
    <JbCMainContainer>
      <JbUAlert type="warning">
        <p>To connect as an admin, use login:testAdmin password:testAdmin </p><br/>
        <p>To connect as a patient, use login:testPatient password:testPatient </p><br/>
        <p>The whole platform is translated in three languages because patients were expected to come from any country (eventually).</p><br/>
        <p>Trivia : the "remember me" checkbox does nothing. :p</p>
      </JbUAlert>
      <LocaleSwitch/>
      <div style="width:400px; margin:auto;">
        <JbUAlert v-if="accountConfirmed">{{ t('ui.login.confirm') }}</JbUAlert>
        <JbUPanel :title="$t('ui.login.panelTitle')" icon="key" id="login" >
          <JbCLogin @submit="authenticate" :status="status"/>
        </JbUPanel>
      </div>
    </JbCMainContainer>
  </JbPMain>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue'
import router from '@/router'
import { useI18n } from 'vue-i18n'
import LocaleSwitch from '../components/LocaleSwitch.vue'

export default defineComponent({
  name: 'Signin',
  components: { LocaleSwitch },
  setup() {
    const contentManagerUrl = inject('$ContentManagerUrl')
    const { t, locale, availableLocales } = useI18n({ useScope: 'global' })

    // Display an Alert block 
    let accountConfirmed = false
    if(router.currentRoute.value.query.accountConfirmed){
      accountConfirmed = true
    }

    let redirect = ""
    if(router.currentRoute.value.redirectedFrom && router.currentRoute.value.redirectedFrom.meta.authorize) {
      // If redirected an authorized page
      console.log(router.currentRoute.value.redirectedFrom.fullPath)
      redirect = router.currentRoute.value.redirectedFrom.fullPath
    }
    

    const status = ref('ok')

    const authenticate = async(formValues: {login: string; password: string}) => {
      if (formValues.login && formValues.password) {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ identifier: formValues.login, password: formValues.password })
        };

        try {
          const response = await fetch(contentManagerUrl+"/auth/local/", requestOptions)
          const data = await response.json()
          
          if(data.jwt) {
            console.log('Login successful : '+data.user.role.name)
            console.log(data)
            sessionStorage.setItem('jwt', data.jwt)
            sessionStorage.setItem('id', data.user.id)
            sessionStorage.setItem('first_name', data.user.first_name)
            sessionStorage.setItem('last_name', data.user.last_name)
            sessionStorage.setItem('role', data.user.role.name)

            if(data.user.profile_picture)
              sessionStorage.setItem('profile_picture', data.user.profile_picture.url)

            console.log(sessionStorage)

            if(redirect === ""){
              router.push({ name: data.user.role.name+'Home' })
            } else {
              router.push(redirect)
            }
          } else {
            console.log('Logins are wrong')
            status.value = 'error'
          }


        } catch(err) {
          console.log("Error with authenticating request.")
          console.log(err)
          status.value = 'error'
        }

      } else {
        console.log("Invalid login submitted")
      }
    }
    return {
      t, locale, availableLocales,
      authenticate,
      status,
      accountConfirmed
    }
  }
});
</script>

<style scoped lang="scss">
.locale-switch {
  //margin-bottom:var(--space-8);
}
</style>