
import { defineComponent,defineAsyncComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers, paymentHelpers } from '@/modules/helpers'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import sendByEmailInvoiceMutation from '@/graphql/invoice/sendByEmail.gql'

import QRCodeVue3 from "qrcode-vue3"
import ModalSumupPayment from '@/components/modals/SumupPayment.vue'

export default defineComponent({
  name: 'Invoice',
  props: {
    invoice: Object,
    theme: {
      type: String,
      default: undefined
    },
  },
  components: {
    QRCodeVue3,
    ModalSumupPayment
  },
  setup(props) {
    // get emitter
    const emitter = inject('$emitter') as Emitter
    const QRCodeLink = window.location.href
    
    // Payments
    const totalPayments = ref(0)
    if(props.invoice && props.invoice.payments && props.invoice.payments.length > 0) {
      totalPayments.value = paymentHelpers.getTotalPayments(props.invoice.payments)
    }

    const leftToPay = ref(0)
    if(props.invoice) {
      leftToPay.value = props.invoice.total - totalPayments.value
    }

    const modalInitFlag = ref(false)
    const initPayment = () => {
      modalInitFlag.value = true
      emitter.emit("open-modal-sumup-payment")
    }
    

    return {
      QRCodeLink,
      helpers,

      totalPayments,
      leftToPay,

      initPayment,
      modalInitFlag
    }

  }
})
