
import { defineComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import { helpers, paymentHelpers } from '@/modules/helpers'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import sendByEmailInvoiceMutation from '@/graphql/invoice/sendByEmail.gql'
import QRCodeVue3 from "qrcode-vue3"

export default defineComponent({
  name: 'Invoice',
  props: {
    invoice: Object,
    theme: {
      type: String,
      default: undefined
    },
  },
  components: {
    QRCodeVue3
  },
  setup(props) {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    const QRCodeLink = "http://192.168.1.11:8081/administration/invoices/"+props.invoice?.id
    
    const { locale } = useI18n({ useScope: 'global' })

    // generate Invoice PDF
    const { mutate: _generateInvoice } = useMutation(generateInvoiceMutation)

    const generateStatus = reactive({
      error: false,
      success: false,
      message: ""
    })
    const generateInvoice = async (id: number) => {
      const queryInput = {
        id: id,
        locale: locale.value
      }
      try {
        await _generateInvoice(queryInput)

        generateStatus.success = true
        generateStatus.error = false
      } catch(e) {
        generateStatus.success = false
        generateStatus.error = true
        generateStatus.message = e.message
      }
    }

    // send Invoice PDF by Email
    const { mutate: _sendByEmailInvoice } = useMutation(sendByEmailInvoiceMutation)

    const sendByEmailInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _sendByEmailInvoice(queryInput)
    }


    // Payments
    const totalPayments = ref(0)
    if(props.invoice && props.invoice.payments && props.invoice.payments.length > 0) {
      totalPayments.value = paymentHelpers.getTotalPayments(props.invoice.payments)
    }

    const leftToPay = ref(0)
    if(props.invoice) {
      leftToPay.value = props.invoice.total - totalPayments.value
    }

    

    return {
      QRCodeLink,
      generateInvoice,
      generateStatus,

      sendByEmailInvoice,
      helpers,

      totalPayments,
      leftToPay
    }

  }
})
