<template>
  <JbUModal name="sumup-payment" cancellable>
    <template v-slot:headerLeft>
      {{ $t('ui.payment.more.make_a_payment') }}
    </template>
    <template v-slot:default>
      <template v-if="checkout">


        <div v-if="checkout.status == 'PAID' || (verification && verification.status == 'ok')" id="success-display">
          <span>{{ $t('ui.payment.more.payment_success') }}</span> <br>
          <font-awesome-icon icon="check-circle" /> <br>
          <span>{{ $t('ui.payment.more.can_close') }}</span>
        </div>
        <SumupPaymentSDK v-else :checkout="checkout" @checkout-success="onCheckoutSuccess"/>

        
      </template>
      <template v-else>
        {{ $t('ui.common.loading.name') }}
      </template>
    </template>
  </JbUModal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import Q_SumUp_GetOrCreateCheckout from '@/graphql/sumupCalls/patient/getOrCreateCheckout.gql'
import Q_SumUp_verifyCheckout from '@/graphql/sumupCalls/patient/verifyCheckout.gql'
import SumupPaymentSDK from "@/components/SumupPaymentSDK.vue"

export default defineComponent({
  name: 'ModalSumupPayment',
  components: {
    SumupPaymentSDK
  },
  props: {
    invoice: Object,
    initFlag: Boolean
  },
  setup(props) {
    const paymentDoneFlag = ref(false)

    const { result: sumupGetOrCreateCheckout } = useQuery(Q_SumUp_GetOrCreateCheckout, () => ({invoiceId: props.invoice!.id}), () => ({enabled: props.initFlag}))
    const checkout = useResult(sumupGetOrCreateCheckout, null, data => data.getOrCreateCheckout)

    const onCheckoutSuccess = () => {
      console.log('will verify checkout :'+ checkout.value.id)
      paymentDoneFlag.value = true
    }

    const { result: sumupVerifyCheckout } = useQuery(Q_SumUp_verifyCheckout, () => ({invoiceId: props.invoice!.id, checkoutId: checkout.value === null ? '0' : checkout.value.id }), () => ({enabled: paymentDoneFlag.value}))
    const verification = useResult(sumupVerifyCheckout, null, data => data.verifyCheckout)
    

        
    return {
      checkout,
      verification,
      onCheckoutSuccess,
    }
  }
});
</script>

<style scoped lang="scss">
#success-display {
  text-align:center;

  span {
    display:inline-block;
    font-size:var(--text-xl);
    padding:var(--space-8);
  }

  svg {
    font-size:10rem;
    color:var(--color-green);
  }
}
</style>