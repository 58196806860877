// disable linter camelCase BS
/*eslint-disable */
export const fr = {
  language: {
    en: {
      name: "Anglais"
    },
    fr: {
      name: "Français"
    },
    de: {
      name: "Allemand"
    }
  },
  country: {
    germany: "Allemagne",
    de: "Allemagne",
    france: "France",
    fr: "France",
    switzerland: "Suisse",
    ch: "Suisse",
    austria: "Autriche",
    at: "Autriche",
    spain: "Espagne",
    sp: "Espagne",
    italy: "Italie",
    it: "Italie",
    great_britain: "Grande Bretagne",
    gb: "Grande Bretagne",
    united_states: "États Unis",
    us: "États Unis",
  },
  ui: {
    common: {
      yes: "Oui",
      no: "Non",
      date: "Date",
      see_all: "Voir toutes les informations",
      last_30_days: "+{0} depuis les 30 derniers jours",
      loading: {
        name: "Chargement..."
      },
      error: {
        name: "Erreur"
      },
      refresh: {
        name: "Recharger"
      },
      connect: {
        self: "Se connecter"
      },
      disconnect: {
        self: "Se déconnecter"
      },
      fields: {
        write_here: "Écrire ici..."
      }
    },
    login: {
      panelTitle: "new body concept",
      confirm: "Votre compte est désormais activé. Vous pouvez vous connecter et commencer à utiliser notre application !"
    },
    role: {
      Owner: "Propriétaire",
      Manager: "Manager",
      Patient: "Patient"
    },
    home: {
      name: "Page d'accueil",
      new_user: {
        create: "Créer un nouveau compte",
        create_mine: "Créer mon compte",
        create_form: "Veuillez remplir ce formulaire pour créer un nouveau compte. Veuillez noter que les champs munis d'un astérisque sont obligatoires."
      }
    },
    page: {
      settings: {
        name: "Préférences",
        products: "Modifiez les produits facturables."
      }
    },
    list: {
      actions: {
        name: "Actions"
      }
    },
    element: {
      create: {
        name: "Nouvel élément",
      },
      update: {
        name: "Éditer un élément",
      },
      delete: {
        name: "Supprimer un élément",
        confirm: "Supprimer cet élément ?"
      }
    },
    address: {
      name: "Adresse",
      fields: {
        id: "Numéro",
        street: "Rue",
        street_extra: "Extra",
        city: "Ville",
        postal_code: "Code postal",
        region: "Région",
        country: "Pays"
      }
    },
    contact_method: {
      phone_number: "Numéro de téléphone",
      mobile_phone_number: "Numéro de portable",
      email: "Email",
      skype: "Skype",
      fax: "Fax",
      website: "Site internet"
    },
    appointment: {
      name: "Rendez-vous",
      self: {
        name: "Mes rendez-vous",
        to_come: "{0} rendez-vous à venir",
        see_all: "Voir mes rendez-vous"
      },
      create: {
        name: "Nouveau rendez-vous",
        confirm: "Le nouveau rendez-vous a bien été crée.",
        error: "Le nouvel rendez-vous n'a pas pu être crée."
      },
      update: {
        name: "Éditer le rendez-vous",
        confirm: "Le rendez-vous a bien été modifié.",
        error: "Le rendez-vous n'a pas pu être modifié."
      },
      delete: {
        name: "Supprimer le rendez-vous",
        confirm: "Le rendez-vous a bien été supprimé.",
        error: "Le rendez-vous n'a pas pu être supprimé."
      },
      search: {
        name: "Rechercher des rendez-vous"
      },
      fields: {
        date: "Date",
        patient: "Patient",
        start: "Heure début",
        end: "Heure fin"
      },
      patient: {
        name: "Mes rendez-vous",
        create: "Prendre rendez-vous"
      }
    },
    invoice: {
      name: "Facture | Factures",
      self: {
        name: "Mes factures",
        to_come: "{0} factures",
        see_all: "Voir mes factures"
      },
      create: {
        name: "Nouvelle facture",
        confirm: "La nouvelle facture a bien été créée.",
        error: "La nouvelle facture n'a pas pu être créée."
      },
      update: {
        name: "Éditer la facture",
        confirm: "La facture a bien été modifiée.",
        error: "La facture n'a pas pu être modifiée."
      },
      delete: {
        name: "Supprimer la facture",
        confirm: "La facture a bien été supprimée.",
        error: "La facture n'a pas pu être supprimée."
      },
      search: {
        name: "Rechercher des factures"
      },
      fields: {
        number: "Numéro",
        number_month: "Mois",
        number_in_month: "Numéro",
        description: "Description",
        description_auto: "Rendez-vous du ",
        date: "Date de facturation",
        due_date: "Date de paiement",
        products: "Produits facturés",
        use_membership_account: "Utiliser le solde du compte de membre"
      },
      label_groups: {
        infos: "Informations",
      },
      details: {
        products: "Produits facturés",
        date: "Date de facturation",
        due_date: "Date de paiement",
        payments: "Paiements effectués",
        total: "Total",
        total_paid: "Total des sommes reglées",
        rest_due: "Reste à payer",
        is_paid: "Cette facture est payée.",
        pdf: {
          file: "Fichier PDF",
          see: "Voir le fichier PDF",
          generate: {
            name: "Générer fichier PDF",
            error: "Impossible de générer le PDF",
            success: "PDF généré"
          },
          send_by_email: "Envoyer le PDF par Email",
        }
      },
      patient: {
        name: "Mes factures"
      }
    },
    membership: {
      name: "Adhésion | Adhésions",
      create: {
        name: "Nouvelle adhésion",
        confirm: "La nouvelle adhésion a bien été créée.",
        error: "La nouvelle adhésion n'a pas pu être créée."
      },
      update: {
        name: "Éditer l'adhésion",
        confirm: "L'adhésion a bien été modifiée.",
        error: "L'adhésion n'a pas pu être modifiée."
      },
      delete: {
        name: "Supprimer l'adhésion",
        confirm: "L'adhésion a bien été supprimée.",
        error: "L'adhésion n'a pas pu être supprimée."
      },
      product: {
        name: "Facturation de produit"
      },
      fields: {
        id: "Numéro",
        balance: "Solde",
        type: "Type",
        discount_prc: "Pourcentage de remise"
      },
      generate: {
        name: "Générer la facture",
        all: "Générer toutes les factures"
      },
      options: {
        name: "Options de facturation"
      },
      more: {
        past_invoices: "Les {0} dernières factures",
        no_invoices: "Aucune facture",
        past_appointments: "Rendez-vous pris durant les {0} derniers mois",
        no_appointments: "Aucun rendez-vous",
        use_balance: "Utiliser le solde du compte adhérant pour payer",
        new_balance: "Nouveau solde",
        subscribe: "Devenir membre"
      }
    },
    payment: {
      name: "Paiement | Paiements",
      self: {
        name: "Mes paiements",
        to_come: "{0} paiements",
        see_all: "Voir mes paiements"
      },
      create: {
        name: "Nouveau paiement",
        confirm: "Le nouveau paiement a bien été crée.",
        error: "Le nouveau paiement n'a pas pu être crée."
      },
      update: {
        name: "Éditer le paiement",
        confirm: "Le paiement a bien été modifié.",
        error: "Le paiement n'a pas pu être modifié."
      },
      delete: {
        name: "Supprimer le paiement",
        confirm: "Le paiement a bien été supprimé.",
        error: "Le paiement n'a pas pu être supprimé."
      },
      search: {
        name: "Rechercher des paiements"
      },
      fields: {
        id: "Numéro",
        amount: "Montant",
        invoice: "Numéro de facture",
        currency: "Devise",
        use_invoice_user: "Utiliser l'utilisateur rattaché à la facture"
      },
      more: {
        invoice_details: "Détails de la facture",
        make_a_payment: "Effectuer un paiement en ligne via SumUp",
        payment_success: "Le paiement a été effectué avec succès !",
        can_close: "Vous pouvez maintenant fermer cette fenêtre.",
        use_form: "Last {0} invoices",
      }
    },
    payment_type: {
      name: "Type de paiement",
      account_balance: "Solde de compte membre",
      card: "Carte bancaire",
      cash: "Liquide",
      bank_transfer: "Virement"
    },
    product: {
      name: "Produit | Produits",
      create: {
        name: "Nouveau produit",
        confirm: "Le nouveau produit a bien été crée.",
        error: "Le nouveau produit n'a pas pu être crée."
      },
      update: {
        name: "Éditer le produit",
        confirm: "Le produit a bien été modifié.",
        error: "Le produit n'a pas pu être modifié."
      },
      delete: {
        name: "Supprimer le produit",
        confirm: "Le produit a bien été supprimé.",
        error: "Le produit n'a pas pu être supprimé."
      },
      fields: {
        name: "Nom",
        category: "Catégorie",
        price: "Prix",
        quantity: "Quantité",
        currency: "Devise"
      },
      more: {
        new_custom: "Nouveau produit manuel"
      }
    },
    product_type: {
      name: "Type de produit",
      card: "Carte de fidélité",
      consultation: "Consultation",
    },
    relationship: {
      name: "Relation | Relations",
      create: {
        name: "Nouvelle relation"
      },
      type: {
        father: "Père",
        grandfather: "Grand-père",
        mother: "Mère",
        grandmother: "Grand-mère",
        brother: "Frère",
        sister: "Soeur",
        son: "Fils",
        daughter: "Fille",
        grandson: "Petit-fils",
        granddaughter: "Petite-fille",
        cousin: "Cousin",
        cousine: "Cousine",
        family: "Famille",
        friend: "Ami"
      }
    },
    user: {
      name: "Utilisateur | Utilisateurs",
      create: {
        name: "Nouvel utilisateur",
        confirm: "Le nouvel utilisateur a bien été crée.",
        error: "Le nouvel utilisateur n'a pas pu être crée.",
        self: {
          confirm: "Votre nouveau compte a bien été crée. Vous allez désormais recevoir un email de confirmation à l'adresse que vous avez utilisé pour vous inscrire.",
          error: "Votre compte n'a pas pu être crée. L'adresse email est déjà utilisée.",
          form_invalid: "Les données du formulaires sont manquantes ou incomplètes. Veuillez réessayer en suivant les instructions."
        }
      },
      update: {
        name: "Éditer l'utilisateur",
        confirm: "L'utilisateur a bien été édité.",
        error: "L'utilisateur n'a pas pu être édité.",
        self: {
          button: "Éditer mes informations",
          confirm: "Vos informations ont été éditées",
          error: "Vos informations n'ont pas pu être éditées"
        }
      },
      delete: {
        name: "Supprimer l'utilisateur",
        confirm: "L'utilisateur a bien été supprimé.",
        error: "L'utilisateur n'a pas pu être supprimé."
      },
      search: {
        name: "Rechercher des utilisateurs"
      },
      fields: {
        last_name: "Nom de famille",
        second_name: "Deuxième prénom(s)",
        first_name: "Prénom",
        birth_name: "Nom de naissance",
        job: "Métier",
        phone_private: "Téléphone privé",
        cellphone: "Téléphone portable",
        email: "Adresse Email",
        gender: {
          name: "Genre",
          male: "Masculin",
          female: "Feminin",
          other: "Autre"
        },
        username: "Nom d'utilisateur",
        password: "Mot de passe",
        birthday: "Date de naissance",
        electronic_invoices: "Je souhaite recevoir des factures papier en complément des factures électroniques.",//electronic_invoices: "Je souhaite recevoir des factures papier.",
        profile_picture: 'Photo de profil',
        password_confirm: "Confirmation",
        password_confirm_ph: "Tapez à nouveau votre mot de passe",
        language: "Langue sélectionnée",
        problems: "Problèmes",
        problems_ph: "Décrivez les symptomes que vous souhaitez traiter.",
        medication: "Prenez-vous régulièrement des médicaments ?",
        hear_about_us: "Comment avez-vous entendu parler de nous ?",
        recommendation: "J'ai la recommandation de",
        marketing: "J'accepte de recevoir des communications commerciales de la part de « new body concept ».",
        data_optin: "En soumettant ce formulaire, j’accepte que mes informations personnelles soient utilisées exclusivement dans le cadre de l'application « new body concept »."
      },
      more: {
        all_person_infos: {
          title: "Informations personnelles",
          is_therapist: "Thérapeute ?"
        },
        name: "Nom",
        full_name: "Nom complet"
      },
      label_groups: {
        logins: "Identifiants",
        personal_infos: "Informations personnelles",
        address: "Adresse",
        medical_questions: "Informations médicales",
      },
      list: "Liste des utilisateurs",
      imported_warning: {
        sentence: "Votre compte n'est pas encore sécurisé. Veuillez éditer votre compte pour y définir un nouveau mot de passe personnel.",
      }
    }
  },
}