<template>
  <JbPMain>
    <JbCMainContainer padding="0">
      <JbUAlert type="warning">
        <p>The signup process was designed to mimic a real world form that every patient had to fill before the first appointment.</p><br/>
        <p>In hindsight form a business perspective this process should have been made easier on first sign-up to facilitate appointment booking.</p><br/>
        <p>After filling the form, an email with a account activation is sent to the user.</p>
      </JbUAlert>
      <LocaleSwitch/>
      <div style="max-width:800px; margin:auto;">
        <JbUPanel id="forms-w-labels" :padding="40">
          <div id="logo">
            <img v-if="companyInfos" :src="$ContentManagerUrl+companyInfos.logo.url"/>
          </div>
          <div class="form-title">{{ $t('ui.home.new_user.create_form') }}</div>
          <div v-if="$AppTesting" class="test-commands"><button @click.stop="fillForm">Fill Form</button></div>
          <UserForm ref="userFormRef" display="create" type="user"/>
          <button class="highlighted" @click="submit_form" style="margin-top:var(--space-7);display:block;width:100%;text-align:center;padding:var(--space-7)">{{ $t('ui.home.new_user.create_mine') }}</button>
          <JbUAlert v-if="showInvalidFormAlert" type="error">{{ $t('ui.user.create.self.form_invalid') }}</JbUAlert>
          <JbUAlert v-if="createStatus.success" type="success">{{ $t('ui.user.create.self.confirm') }}</JbUAlert>
          <JbUAlert v-else-if="createStatus.error" type="error">{{ $t('ui.user.create.self.error') }}</JbUAlert>
        </JbUPanel>
      </div>
    </JbCMainContainer>
  </JbPMain>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { useI18n } from 'vue-i18n'
import LocaleSwitch from '../components/LocaleSwitch.vue'
import getCompanyInfosQuery from '@/graphql/companyInformation/get.gql'

import createElementMutation from '@/graphql/user/create.gql'

import UserForm from '@/components/forms/User.form.vue'

export default defineComponent({
  name: 'Signup',
  components: { 
    LocaleSwitch,
    UserForm
  },
  setup() {
    const showInvalidFormAlert = ref(false)

    const { locale } = useI18n({ useScope: 'global' })
    
    const userFormRef = ref<null | any>(null)
    
    ////////// CREATE QUERY
    const { mutate: createElement, onDone: createElementDone, onError: createElementError } = useMutation(createElementMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })

    createElementDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createElementError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    ////////// Field : CompanyInfos - Gets
    const { result: getCompanyInfosResult } = useQuery(getCompanyInfosQuery)
    const companyInfos = useResult(getCompanyInfosResult, null, data => data['companyInformation'])

    ////////// FORM SUBMIT
    const submit_form = () => {

      const form = userFormRef.value.form

      if(userFormRef.value.validate() && form.password.model === form.password_confirm.model) {

        showInvalidFormAlert.value = false

        const queryInput = { 
          input: { 
            data: {
              // With validation
              username: new Date,
              first_name: form.first_name.model,
              second_name: form.second_name.model,
              last_name: form.last_name.model,
              birth_name: form.birth_name.model,
              job: form.job.model,
              phone_private: form.phone_private.code + form.phone_private.model,
              cellphone: form.cellphone.code + form.cellphone.model,
              email: form.email.model,
              password: form.password.model,
              problems: form.problems.model,
              medication: form.medication.model,
              hear_about_us: form.hear_about_us.model,
              recommendation: form.recommendation.model,
              selected_locale: locale.value,
              address: [{
                street: form.street.model,
                street_extra: form.street_extra.model,
                postal_code: form.postal_code.model,
                city: form.city.model,
                region:form.region.model,
                country: form.country.model
              }],

              // Without validation
              gender: form.gender.model,
              birth_date: form.birth_year.model+'-'+form.birth_month.model+'-'+form.birth_day.model,
              generation_method: "signup",
              electronic_invoices: form.electronic_invoices.model,
              marketing: form.marketing.model,
              confirmed: false,
              is_therapist: false
            }
          }
        }
        
        createElement(queryInput).catch((e) => { return/*console.log(e)*/ })

      } else {
        showInvalidFormAlert.value = true
      }
    }

    // Testing purposes
    const fillForm = () => {
      userFormRef.value.fillForm()
    }

    return {
      userFormRef,
      
      companyInfos,
      createElement,
      createStatus,

      submit_form,
      fillForm,
      showInvalidFormAlert
    }
  }
})
</script>

<style scoped lang="scss">
#logo {
  width:80%;
  margin:var(--space-6) auto;

  > img {
    width:50%;
    margin:0 auto;
  }
}
.form-title {
  margin:0 var(--space-8) var(--space-8) var(--space-8);
  text-align:center;
}
.jb-alert {
  margin-top:var(--space-5);
}
.locale-switch {
  margin-bottom:var(--space-5);
  padding:var(--space-7);
}
#forms-w-labels {
  margin-bottom:80px;
}
</style>