
import { defineComponent, ref, reactive } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { useI18n } from 'vue-i18n'
import LocaleSwitch from '../components/LocaleSwitch.vue'
import getCompanyInfosQuery from '@/graphql/companyInformation/get.gql'

import createElementMutation from '@/graphql/user/create.gql'

import UserForm from '@/components/forms/User.form.vue'

export default defineComponent({
  name: 'Signup',
  components: { 
    LocaleSwitch,
    UserForm
  },
  setup() {
    const showInvalidFormAlert = ref(false)

    const { locale } = useI18n({ useScope: 'global' })
    
    const userFormRef = ref<null | any>(null)
    
    ////////// CREATE QUERY
    const { mutate: createElement, onDone: createElementDone, onError: createElementError } = useMutation(createElementMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })

    createElementDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createElementError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    ////////// Field : CompanyInfos - Gets
    const { result: getCompanyInfosResult } = useQuery(getCompanyInfosQuery)
    const companyInfos = useResult(getCompanyInfosResult, null, data => data['companyInformation'])

    ////////// FORM SUBMIT
    const submit_form = () => {

      const form = userFormRef.value.form

      if(userFormRef.value.validate() && form.password.model === form.password_confirm.model) {

        showInvalidFormAlert.value = false

        const queryInput = { 
          input: { 
            data: {
              // With validation
              username: new Date,
              first_name: form.first_name.model,
              second_name: form.second_name.model,
              last_name: form.last_name.model,
              birth_name: form.birth_name.model,
              job: form.job.model,
              phone_private: form.phone_private.code + form.phone_private.model,
              cellphone: form.cellphone.code + form.cellphone.model,
              email: form.email.model,
              password: form.password.model,
              problems: form.problems.model,
              medication: form.medication.model,
              hear_about_us: form.hear_about_us.model,
              recommendation: form.recommendation.model,
              selected_locale: locale.value,
              address: [{
                street: form.street.model,
                street_extra: form.street_extra.model,
                postal_code: form.postal_code.model,
                city: form.city.model,
                region:form.region.model,
                country: form.country.model
              }],

              // Without validation
              gender: form.gender.model,
              birth_date: form.birth_year.model+'-'+form.birth_month.model+'-'+form.birth_day.model,
              generation_method: "signup",
              electronic_invoices: form.electronic_invoices.model,
              marketing: form.marketing.model,
              confirmed: false,
              is_therapist: false
            }
          }
        }
        
        createElement(queryInput).catch((e) => { return/*console.log(e)*/ })

      } else {
        showInvalidFormAlert.value = true
      }
    }

    // Testing purposes
    const fillForm = () => {
      userFormRef.value.fillForm()
    }

    return {
      userFormRef,
      
      companyInfos,
      createElement,
      createStatus,

      submit_form,
      fillForm,
      showInvalidFormAlert
    }
  }
})
