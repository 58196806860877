
import { defineComponent, inject, ref, reactive, watch, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import { searchHelper, toSearchList, toSearchListElement } from '@/modules/search.ts'
import getElementQuery from '@/graphql/product/get.gql'
import updateElementMutation from '@/graphql/product/update.gql'
import deleteElementMutation from '@/graphql/product/delete.gql'

import ProductForm from '@/components/forms/Product.form.vue'

import getProductsQuery from '@/graphql/product/gets.gql'

export default defineComponent({
  name: 'ProductUpdate',
  props: ['elementId', 'listPaging'],
  components: {
    ProductForm
  },
  setup(props) {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    const { t, locale } = useI18n({ useScope: 'global' })

    const productFormRef = ref<null | any>(null)


    ////////// MODAL EDIT ID
    const editingElement = reactive({id: props.elementId})
    const editingElementOptions = reactive({enabled : false})
    const { result: getElement, loading: editingElementLoading, onResult: editingElement_onResult } = useQuery(getElementQuery, editingElement, editingElementOptions)
    const updateElementInfos = useResult(getElement, null, data => data.product)
    const preselectedInvoice = ref({ id:0, name:'' })

    watch(() => props.elementId, (newValue) => {
      editingElement.id = newValue
      editingElementOptions.enabled = true
    })

    editingElement_onResult(queryResult => {
      const form = productFormRef.value.form
      
      if(queryResult.networkStatus === 7) { // 7 mean OK https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts
        for (const [key] of Object.entries(updateElementInfos.value)) {
          if (key === 'currency') {
            form['currencyId'] = updateElementInfos.value[key].id
          } else if (key === 'product_category') {
            if(updateElementInfos.value[key] !== null)
              form['productCategoryId'] = updateElementInfos.value[key].id
            else
              form['productCategoryId'] = ''
          } else { // The rest is automatically set
            form[key] = updateElementInfos.value[key]
          }
        }
      } else {
        console.log("Element Fetch by id failed.")
      }
    })

    ////////// UPDATE QUERY
    const { mutate: updateProduct, onDone: updateProductDone, onError: updateProductError } = useMutation(updateElementMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updateProductDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updateProductError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updateProductDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.product.update.confirm'),
          text: ""+QR.data.updateProduct.product.name,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-product-update")
    })
    updateProductError(() => {
      console.log('Edit product Error')
    })

    ////////// DELETE QUERY
    const { mutate: deleteMutation, onDone: deleteMutationDone, onError: deleteMutationError } = useMutation(deleteElementMutation)
    const removeStatus = reactive({
      success: false,
      error: false
    })
    deleteMutationDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.product.delete.confirm'),
          text: ""+QR.data.deleteProduct.product.name,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-product-update")
    })
    deleteMutationError(() => {
      removeStatus.error = true
      removeStatus.success = false
    })

    

    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const form = productFormRef.value.form

      let productCategoryId: null | number = null
      if(form.productCategoryId !== '') {
        productCategoryId = form.productCategoryId
      }

      const queryInput = { 
        input: { 
          where: {
            id: form.id,
          },
          data: {
            name: form.name,
            price: form.price,
            type: form.type,
            currency: form.currencyId,
            product_category: productCategoryId,
          }
        }
      }

      console.log(queryInput)

      updateProduct(queryInput)
    }

    ////////// MODAL DELETE BUTTON
    const deleteElement = () => {
      if (confirm(t('ui.element.delete.confirm'))) {
        const queryInput = { 
          input: { 
            where: {
              id: props.elementId,
            }
          }
        }

        //console.log(queryInput)

        deleteMutation(queryInput, {
          update: (cache, { data: { deleteProduct: deleteMutation } }) => { // Needs to have the graphQL query name
            const data: any = cache.readQuery({ query: getProductsQuery,  variables: { locale: locale.value } })

            if(data) { // If we are on a page where getProductsQuery has been used
              let productsClone = [...data.products]
              productsClone = productsClone.filter((t: { id: number }) => {
                return t.id !== deleteMutation.product.id;
              })

              cache.writeQuery({ query: getProductsQuery, variables: { locale: locale.value } , data: {
                products: productsClone
              }})
            }
          }
        })
      }
    }

    // Testing purposes
    const fillForm = () => {
      console.log("vui")
      //const testDate = new Date()
      /*form.first_name = form.first_name+'testUpdate'
      form.second_name = form.second_name+'testUpdate'
      form.last_name = form.last_name+'testUpdate'
      form.birth_name = form.birth_name+'testUpdate'
      form.username = form.username+'testUpdate'
      form.email = form.email+'testUpdate'
      form.gender = 'other'*/
    }

    return {
      productFormRef,
      submit_modal,
      deleteElement,
      fillForm,
      updateStatus,
    }
  }
});
