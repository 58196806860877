<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Patient side Invoice</p><br/>
    <p>There you have the selected invoice details.</p><br/>
    <p>The SumUp Api here is not connected to anything anymore but still works in sandbox mode. It was going to be used so patient could order their products online and pay right away, so that they would just need to collect or sign in once on site.</p>
  </JbUAlert>
  <template v-if="invoice">
    <div class="header">
      <h1>{{ invoice.description }}</h1>
    </div>
    <ElementDisplay v-if="invoice" :invoice="invoice" theme="full"/>
  </template>
  <template v-else>
    {{ $t('ui.common.loading.name') }}
  </template>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, watch } from 'vue'
import { Emitter } from 'mitt'
import { useRoute } from 'vue-router'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getElementQuery from '@/graphql/invoice/patient/get.gql'

import ElementDisplay from '@/components/element_displays/patient/Invoice.vue'

export default defineComponent({
  name: 'Invoice',
  components: {
    ElementDisplay
  },
  setup() {
    // Get global components
    const $emitter = inject('$emitter') as Emitter
    const route = useRoute()

    ////////// GET ELEMENT DATA WITH ITS ID
    const getElementParams = reactive({id: route.params.id, user: sessionStorage.getItem('id')})
    const { result: getElement, loading: getElementLoading, onResult: getElement_onResult } = useQuery(getElementQuery, getElementParams)
    const elements = useResult(getElement, null, data => data.invoices)
    const element = ref<{} | null>(null)

    watch(elements, (newValue) => {
      if (!elements.value) {
        return;
      }
      element.value = newValue[0]
    }, { immediate: true })
    
    return {
      invoice: element,
    }
  }
})
</script>

<style scoped lang="scss">
</style>