<template>
    <div class="locale-switch" :class="display">
      <div :class="store.getters.locale == 'de'  ? 'current' : ''" @click="changeLocale('de')"><img src="../assets/icons/flags/de.svg"/></div>
      <div :class="store.getters.locale == 'en'  ? 'current' : ''" @click="changeLocale('en')"><img src="../assets/icons/flags/en.svg"/></div>
      <div :class="store.getters.locale == 'fr'  ? 'current' : ''" @click="changeLocale('fr')"><img src="../assets/icons/flags/fr.svg"/></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import store from '@/store'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'LocaleSwitch',
  props: {
    display: String
  },
  setup() {
    const { locale } = useI18n({ useScope: 'global' })

    const changeLocale = (newLocale: string) => {
      localStorage.setItem('locale',newLocale)
      locale.value = newLocale
      store.commit('changeLocale', newLocale);
    }

    return {
      changeLocale,
      store
    }
  }
});
</script>

<style scoped lang="scss">
.locale-switch {
  display:flex;
  justify-content:flex-end;
  align-items: center;
  //column-gap:5px;
  
  div {

    padding:6px;
    color:#4e4e4e;
    cursor:pointer;

    img {
      transition:width 0.1s linear;
      border-radius:50%;
      width:40px;
    }

    &.current {
      color:#e6e6e6;
      border-bottom:2px solid #5a5a5a; // inset pour empêcher l'image de réduire en taille

      img {
        //width:50px;
        
        //box-shadow: 0px 0px 5px 2px #193966;
      }
    }
  }

  &.sidebar {
    background: var(--color-button-bg-default);
    border-radius: 4px;
    justify-content:center;
    padding:10px;
  }
}
</style>