
import { defineComponent, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import Q_SumUp_GetOrCreateCheckout from '@/graphql/sumupCalls/patient/getOrCreateCheckout.gql'
import Q_SumUp_verifyCheckout from '@/graphql/sumupCalls/patient/verifyCheckout.gql'
import SumupPaymentSDK from "@/components/SumupPaymentSDK.vue"

export default defineComponent({
  name: 'ModalSumupPayment',
  components: {
    SumupPaymentSDK
  },
  props: {
    invoice: Object,
    initFlag: Boolean
  },
  setup(props) {
    const paymentDoneFlag = ref(false)

    const { result: sumupGetOrCreateCheckout } = useQuery(Q_SumUp_GetOrCreateCheckout, () => ({invoiceId: props.invoice!.id}), () => ({enabled: props.initFlag}))
    const checkout = useResult(sumupGetOrCreateCheckout, null, data => data.getOrCreateCheckout)

    const onCheckoutSuccess = () => {
      console.log('will verify checkout :'+ checkout.value.id)
      paymentDoneFlag.value = true
    }

    const { result: sumupVerifyCheckout } = useQuery(Q_SumUp_verifyCheckout, () => ({invoiceId: props.invoice!.id, checkoutId: checkout.value === null ? '0' : checkout.value.id }), () => ({enabled: paymentDoneFlag.value}))
    const verification = useResult(sumupVerifyCheckout, null, data => data.verifyCheckout)
    

        
    return {
      checkout,
      verification,
      onCheckoutSuccess,
    }
  }
});
