<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Patient Home</p><br/>
    <p>The home of a patient's account.</p><br/>
    <p>You could edit your account, book and check appointments, check every invoice you got billed, pay any past or future service, and of course check your account balance in case you were a "Member".</p><br/>
    <p>Most of that was sadly never implemented but the groundwork was done.</p>
  </JbUAlert>
  <AlertPasswordImportedUser :user="me"/>
  <div v-if="me" class="row cards">
    <JbUCard class="link" min-height="size-m" :title="me.email" icon="user" :number="userHelpers.getFullName(me)" background="var(--color-blue-darker)" :footer="$t('ui.user.update.self.button')" @click="$router.push('/patient/update-personal-infos')">
      <h2> </h2>
    </JbUCard>
  </div>
  <div v-if="me" style="margin-top:var(--space-5);" class="row cards">
    <JbUCard class="w3 link" min-height="size-m" :title="$t('ui.appointment.self.name')" icon="calendar-check" background="var(--color-lime-darker)" :number="$t('ui.appointment.self.to_come', [me.appointments.length])" :footer="$t('ui.appointment.self.see_all')">
    </JbUCard>
    <JbUCard class="w3 link" min-height="size-m" :title="$t('ui.invoice.name', 2)" icon="file-invoice-dollar" background="var(--color-orange-darker)" :number="$t('ui.invoice.self.to_come', [me.invoices.length])" :footer="$t('ui.invoice.self.see_all')" @click="$router.push('/patient/invoices')">
    </JbUCard>
    <JbUCard class="w3 link" min-height="size-m" :title="$t('ui.payment.name', 2)" icon="dollar-sign" background="var(--color-pink-darker)" :number="$t('ui.payment.self.to_come', [me.payments.length])" :footer="$t('ui.payment.self.see_all')">
    </JbUCard>
    <JbUCard class="w3" :title="$t('ui.membership.name')" background="var(--color-teal-darker)">
      <div v-if="me && me.membership">
        <h2>{{ me.membership.balance }} {{ me.membership.currency.symbol }}</h2>
      </div>
      <div v-else>
        <h2>No Membership !</h2>
        <div style="margin-top:var(--default-gap)"><JbUButton color="highlighted" size="l">{{ $t('ui.membership.more.subscribe' )}}</JbUButton></div>
      </div>
    </JbUCard>
  </div>
  <div style="margin-top:var(--space-5);" class="row cards">
    
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { userHelpers, invoiceHelpers } from '@/modules/helpers'
import getMeQuery from '@/graphql/user/getMe.gql'
import AlertPasswordImportedUser from '@/components/AlertPasswordImportedUser.vue'

export default defineComponent({
  name: 'PatientHome',
  components: {
    AlertPasswordImportedUser,
  },
  setup() {

    ////////// GET AUTHENTICATED USER
    const { result: getMeId} = useQuery(getMeQuery)
    const me = useResult(getMeId, null, data => data.meFull)


    //console.log(me)

    return {
      me,

      userHelpers, invoiceHelpers
    }
  }
});
</script>

<style scoped lang="scss">
</style>