
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    user: Object
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })

    const isDate = (date: string) => {
      const a = Date.parse(date)
      if(isNaN(a))
        return false
      else
        return true
    }

    return {
      t,
      isDate
    }
  }
})
