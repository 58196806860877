
import { defineComponent, inject } from 'vue'
import { Emitter } from 'mitt'
import { helpers, userHelpers } from '@/modules/helpers'
import QRCodeVue3 from "qrcode-vue3"

import InvoiceListDisplay from '@/components/element_displays/Invoice_List.vue'

export default defineComponent({
  components: {
    InvoiceListDisplay,
    QRCodeVue3
  },
  props: {
    user: Object,
    theme: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    const QRCodeLink = "http://192.168.1.11:8081/administration/patients/"+props.user?.id

    return {
      QRCodeLink,
      helpers, userHelpers
    }

  }
})
