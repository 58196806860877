<template>
  <LocaleSwitch/>
  <JbUPanel :title="$t('ui.user.name')">
    <UserForm ref="userFormRef" display="update" type="user"/>
    <button class="highlighted" @click="submit_form" style="margin-top:var(--space-7);display:block;width:100%;text-align:center;padding:var(--space-7)">{{ $t('ui.user.update.self.button') }}</button>
  </JbUPanel>
</template>

<script lang="ts">
import { defineComponent, inject, ref, reactive, watchEffect} from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getUserQuery from '@/graphql/user/get.gql'
import getMeQuery from '@/graphql/user/getMe.gql'
import updateElementMutation from '@/graphql/user/update.gql'
import LocaleSwitch from '@/components/LocaleSwitch.vue'

import UserForm from '@/components/forms/User.form.vue'

import getCountriesQuery from '@/graphql/country/gets.gql'

export default defineComponent({
  name: 'UserUpdate',
  components: {
    UserForm,
    LocaleSwitch
  },
  setup() {
     // get emitter
    const $emitter = inject('$emitter') as Emitter

    const { t, locale } = useI18n({ useScope: 'global' })

    const userFormRef = ref<null | any>(null)
    
    ////////// GET AUTHENTICATED USER
    const { result: getMe, onResult: editingElement_onResult} = useQuery(getMeQuery)
    const me = useResult(getMe, null, data => data.meFull)

    const populateForm = (me: any) => {
      const form = userFormRef.value.form

      for (const [key] of Object.entries(me)) {
        if(key === 'birth_date') {
          form[key] = new Date(me[key])
          form.birth_day.model = String(form[key].getDate()).padStart(2,'0')
          form.birth_month.model = String(form[key].getMonth()+1).padStart(2,'0')
          form.birth_year.model = form[key].getFullYear()
        } else if(key === 'address') {
          form.street.model = me[key][0].street
          form.street_extra.model = me[key][0].street_extra
          form.postal_code.model = me[key][0].postal_code
          form.city.model = me[key][0].city
          form.region.model = me[key][0].region
          if(me[key][0].country !== null)
            form.country.model = me[key][0].country.id
        } else { // The rest is automatically set
          if(form[key] !== undefined) {
            form[key].model = me[key]
          }
        }
      }
    }

    watchEffect(() => {
      if (!me.value) {
        return;
      }
      populateForm(me.value)
    }, {
      flush: 'post'
    })
    

    ////////// UPDATE QUERY
    const { mutate: updateMutation, onDone: updateMutationDone, onError: updateMutationError } = useMutation(updateElementMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updateMutationDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updateMutationError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updateMutationDone((QR) => {
      console.log(QR)
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.user.update.self.confirm'),
          text: QR.data.updateUser.user.last_name+" "+QR.data.updateUser.user.first_name,
          icon: "check-circle",
        }
      })
    })
    updateMutationError(() => {
      console.log('Edit user Error')
    })

    ////////// MODAL SUBMIT
    const submit_form = () => {
      const form = userFormRef.value.form

      const queryInput: { [key: string]: any } = { 
        input: { 
          where: {
            id: me.value.id,
          },
          data: {
            first_name: form.first_name.model,
            second_name: form.second_name.model,
            last_name: form.last_name.model,
            birth_name: form.birth_name.model,
            job: form.job.model,
            phone_private: form.phone_private.model,
            cellphone: form.cellphone.model,
            username: new Date,
            email: form.email.model,
            problems: form.problems.model,
            medication: form.medication.model,
            hear_about_us: form.hear_about_us.model,
            recommendation: form.recommendation.model,
            selected_locale: locale.value,
            address: [{
              street: form.street.model,
              street_extra: form.street_extra.model,
              postal_code: form.postal_code.model,
              city: form.city.model,
              region:form.region.model,
              country: form.country.model
            }],

            // Without validation
            gender: form.gender.model,
            birth_date: form.birth_year.model+'-'+form.birth_month.model+'-'+form.birth_day.model,
            electronic_invoices: form.electronic_invoices.model,
          }
        }
      }

      if(form.password.model !== '' && (form.password.model === form.password_confirm.model)) {
        queryInput.input.data.password = form.password.model
      }

      updateMutation(queryInput)
    }

    // Testing purposes
    const fillForm = () => {
      userFormRef.value.fillForm()
    }

    return {
      userFormRef,
      
      submit_form,
      fillForm,
      updateStatus
    }
  }
});
</script>