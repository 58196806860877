
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MembershipFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Membership"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"balance"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currency"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrencyFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserFieldsShallow"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"invoices"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"InvoiceFieldsShallow"},"directives":[]}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"membership_type"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":364}};
    doc.loc.source = {"body":"#import \"../user/fields.shallow.fragment.gql\"\n#import \"../invoice/fields.shallow.fragment.gql\"\n#import \"../currency/fields.fragment.gql\"\n\nfragment MembershipFields on Membership {\n  id\n  balance\n  currency {\n    ...CurrencyFields\n  }\n  user {\n    ...UserFieldsShallow\n    invoices {\n      ...InvoiceFieldsShallow\n    }\n  }\n  membership_type {\n    id\n    name\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../user/fields.shallow.fragment.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../invoice/fields.shallow.fragment.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../currency/fields.fragment.gql").definitions));


      module.exports = doc;
    
