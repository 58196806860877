
import { defineComponent, ref, reactive } from 'vue'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { userHelpers, invoiceHelpers } from '@/modules/helpers'
import getMeQuery from '@/graphql/user/getMe.gql'
import AlertPasswordImportedUser from '@/components/AlertPasswordImportedUser.vue'

export default defineComponent({
  name: 'PatientHome',
  components: {
    AlertPasswordImportedUser,
  },
  setup() {

    ////////// GET AUTHENTICATED USER
    const { result: getMeId} = useQuery(getMeQuery)
    const me = useResult(getMeId, null, data => data.meFull)


    //console.log(me)

    return {
      me,

      userHelpers, invoiceHelpers
    }
  }
});
