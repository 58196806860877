<template>
  <JbUModal name="product-create" cancellable>
    <template v-slot:headerLeft>
      <div v-if="$AppTesting" class="test-commands"><button @click.stop="fillForm">Fill Form</button></div>
      {{ $t('ui.product.create.name') }}
    </template>
    <template v-slot:default>
      <ProductForm ref="productFormRef" display="create" type="admin"/>
      <!--<form class="jb-form" @submit.prevent="">
        <div class="form-row">
          <label for="name">{{ $t('ui.product.fields.name') }}</label>
          <div><input type="text" id="name" name="name" v-model.number="form.name" :placeholder="$t('ui.product.fields.name')"></div>
        </div>
        <div class="form-row">
          <label for="type">{{ $t('ui.product_type.name') }}</label>
          <div>
            <select v-model="form.type">
              <option value="consultation">{{ $t('ui.product_type.consultation') }}</option>
              <option value="card">{{ $t('ui.product_type.card') }}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <label for="price">{{ $t('ui.product.fields.price') }}</label>
          <div><input type="text" id="price" name="price" v-model.number="form.price" :placeholder="$t('ui.product.fields.price')"></div>
        </div>
        <div class="form-row">
          <label for="currencyID">{{ $t('ui.product.fields.currency') }}</label>
          <div>
            <select v-if="currencies" v-model="form.currencyId">
              <option v-for="currency of currencies" :key="currency" :value="currency.id">
                {{ currency.name }} ({{ currency.symbol }})
              </option>
            </select>
          </div>
        </div>
      </form>-->
    </template>
    <template v-slot:footerLeft>
      <JbUAlert v-if="createStatus.error" type="error">{{ $t('ui.product.create.error') }}</JbUAlert>
    </template>
    <template v-slot:footerRight>
      <button class="highlighted" @click="submit_modal">{{ $t('ui.product.create.name') }}</button>
    </template>
  </JbUModal>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useMutation, useResult } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
//import { productHelpers } from '@/modules/productHelpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'
import createElementMutation from '@/graphql/product/create.gql'
import getProductsQuery from '@/graphql/product/gets.gql'
import searchInvoicesQuery from '@/graphql/invoice/search.gql'
import getCurrenciesQuery from '@/graphql/currency/gets.gql'

import ProductForm from '@/components/forms/Product.form.vue'

export default defineComponent({
  name: 'ProductCreate',
  components: {
    ProductForm
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    
    const { t, locale } = useI18n({ useScope: 'global' })

    const form = reactive({
      id: '',
      name:'',
      price: 0,
      type: 'consultation',
      currencyId: 1
    })

    // Apollo Create Query 
    const { mutate: createElement, onDone: createElementDone, onError: createElementError } = useMutation(createElementMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })
    createElementDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createElementError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    createElementDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.product.create.confirm'),
          text: ""+QR.data.createProduct.product.name,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-product-create")
    })
    createElementError(() => {
      console.log('Create product Error')
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {

      const queryInput = { 
        input: { 
          data: {
            name: form.name,
            price: form.price,
            type: form.type,
            currency: form.currencyId,

            locale: locale.value
          }
        }
      }

      createElement(queryInput, {
        update: (cache, { data: { createProduct: createElement } }) => { // Needs to have the graphQL query name
          const data: any = cache.readQuery({ query: getProductsQuery, variables: { locale: locale.value } })

          if(data) { // If we are on a page where getProductsQuery has been used
            cache.writeQuery({ query: getProductsQuery, variables: { locale: locale.value }, data: {
              products: [createElement.product, ...data.products]
            }})
          }
        }
      })
    }

    // Testing purposes
    const fillForm = () => {
      form.name = "test_name"
      form.type = "card"
      form.price = 150
    }

    return {
      form,
      createElement,
      createStatus,

      submit_modal,
      fillForm,

      helpers
    }
  }
});
</script>

<style scoped lang="scss">
</style>