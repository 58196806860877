<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Patient side Invoices</p><br/>
    <p>Here you could check your own invoices and their details.</p><br/>
    <p>The "Unpaid invoice" below is left that way to showcase the inclusion of the payment API, SumUp. Click on "more details" to check it out.</p>
  </JbUAlert>
  <div class="header">
    <h1>{{ $t('ui.invoice.name', 2) }}</h1>
  </div>
  <div class="row">
    <JbUPanel type="card" :title="$t('ui.invoice.name', 2)">
        <div v-if="me && me.invoices">
          <JbUPanelList display="grid">
          <div class="inline-infos header">
            <div>{{ $t('ui.invoice.fields.number') }}</div>
            <div>{{ $t('ui.invoice.fields.description') }}</div>
            <div>{{ $t('ui.invoice.fields.date') }}</div>
            <div>{{ $t('ui.invoice.details.total') }} </div>
            <div class="actions">{{ $t('ui.list.actions.name') }}</div>
          </div>
          <template v-for="invoice of me.invoices" :key="invoice">
            <JbUPanelListRow :item-id="invoice.id" @edit-element="setupEditModal(invoice.id)">
              <template #inline-infos>
                <div>{{ invoiceHelpers.getNumber(invoice) }}</div>
                <div>{{ invoice.description }}</div>
                <div>{{ invoice.date }}</div>
                <div>{{ invoice.total }} {{ invoice.currency ? invoice.currency.symbol : "??" }}</div>
              </template>
              <template #all-infos>
                <router-link :to="'/patient/invoices/'+invoice.id"><JbUAlert style="margin-top:var(--space-6);text-align:right;">{{ $t('ui.common.see_all') }} <font-awesome-icon icon="arrow-right"/></JbUAlert></router-link>
              </template>
            </JbUPanelListRow>
          </template>
          </JbUPanelList>
        </div>
      </JbUPanel>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { invoiceHelpers } from '@/modules/helpers'
import getMeQuery from '@/graphql/user/getMe.gql'

import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Invoices',
  setup() {
    ////////// GET AUTHENTICATED USER
    const { result: getMeId} = useQuery(getMeQuery)
    const me = useResult(getMeId, null, data => data.meFull)

    //console.log(me)

    return {
      me,

      invoiceHelpers
    }
  }
})
</script>