
import { defineComponent, inject, reactive, ref, computed } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { invoiceHelpers } from '@/modules/helpers'
import getMeQuery from '@/graphql/user/getMe.gql'

import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Invoices',
  setup() {
    ////////// GET AUTHENTICATED USER
    const { result: getMeId} = useQuery(getMeQuery)
    const me = useResult(getMeId, null, data => data.meFull)

    //console.log(me)

    return {
      me,

      invoiceHelpers
    }
  }
})
