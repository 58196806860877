
import { defineComponent, inject, ref } from 'vue'
import { Emitter } from 'mitt'
import { useRouter } from 'vue-router'
import { useQuery, useResult } from '@vue/apollo-composable'

export default defineComponent({
  name: 'Patient',
  setup(){
    // get emitter
    const emitter = inject('$emitter') as Emitter
    const contentManagerUrl = inject('$ContentManagerUrl')
    const router = useRouter()
    

    const firstName = ref(sessionStorage.getItem('first_name'))
    const lastName = ref(sessionStorage.getItem('last_name'))
    const role = ref(sessionStorage.getItem('role'))

    const profile_picture = sessionStorage.getItem('profile_picture')

    let profile_picture_url = ''
    if(profile_picture) {
      profile_picture_url = contentManagerUrl+profile_picture
    }

    const goBack = () => {
      router.go(-1)
    }

    return {
      emitter,
      goBack,

      firstName,
      lastName,
      role,
      profile_picture_url
    }
  }
})
