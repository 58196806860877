<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Payments</p><br/>
    <p>A list of all the payments of the selected therapy center.</p><br/>
    <p>Each payment is attached to an invoice.</p><br/>
    <p>You can edit the name on the invoice to have some else pay for you (typically when a parent pays for his child), and you can have several payments on one invoice (eg. if one part is paid via membership account, and the other via bank card). The system will compute the total and tell the user is the invoice is fully paid.</p><br/>
    <p>The currency can be modified, again in an effort to accomodate to every customer.</p>
  </JbUAlert>
  <div class="header">
    <h1>{{ $t('ui.payment.name', 2) }}</h1>
  </div>
  <JbUPanel :title="$t('ui.payment.name', 2)" icon="file-invoice-dollar" id="membership" :padding="0">
    <template v-slot:right>
      <input type="text" :placeholder="$t('ui.payment.search.name')" v-model="searchListPaging.searchTerm" @keyup="searchPayments"/>
    </template>
    <div v-if="getPaymentsError">{{ $t('ui.common.error.name') }}</div>
    <div v-if="payments">
      <JbUPanelList display="grid" :class="getPaymentsLoading ? 'fetch-loading' : ''" :pagination="true" :pagingVar="paymentsPaging" @updateStart="paymentsPaging.start = $event">
        <div class="inline-infos header">
          <div>{{ $t('ui.payment.fields.id') }}</div>
          <div>{{ $t('ui.payment.fields.amount') }}</div>
          <div>{{ $t('ui.payment.fields.invoice') }}</div>
          <div class="actions">{{ $t('ui.list.actions.name') }}</div>
        </div>
        
      <template v-for="payment of payments" :key="payment">
        <JbUPanelListRow editable :item-id="payment.id" @edit-element="setupEditModal(payment.id)">
          <template #inline-infos>
            <div>{{ payment.id }}</div>
            <div>{{ payment.amount }} {{ payment.currency ? payment.currency.symbol : '' }}</div>
            <div>{{ payment.invoice ? invoiceHelpers.getNumber(payment.invoice) : 'Orphan Payment' }}</div>
          </template>

          <template #all-infos>
            <h2>{{ $t('ui.payment.more.invoice_details') }}</h2>
            <div>
              <template v-if="payment.invoice">
                {{ payment.invoice.description }} {{ payment.invoice.total }} {{ payment.invoice.currency.symbol }} 
                <a v-if="payment.invoice.file" :href="$ContentManagerUrl+payment.invoice.file.url" target="_blank"><font-awesome-icon icon="eye" /></a>
              </template>
              <template v-else>
                {{ $t('ui.payment.more.no_invoices') }}
              </template>
            </div>
          </template>
        </JbUPanelListRow>
      </template>
      </JbUPanelList>
    </div>
  </JbUPanel>
  <PaymentUpdateModal :elementId="updateId" :listPaging="paymentsPaging"/>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import getPaymentsQuery from '@/graphql/payment/gets.gql'
import searchPaymentsQuery from '@/graphql/payment/search.gql'

import PaymentUpdateModal from '@/components/modals/PaymentUpdate.vue'

export default defineComponent({
  name: 'Payments',
  components: {
    PaymentUpdateModal
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    // Payments list
    const paymentsPaging = reactive({
      start: 0,
      limit: 100,
      max: 0,
      noPayment: false
    })

    const { result:getPaymentsQR, loading: getPaymentsLoading, error: getPaymentsError } = useQuery(getPaymentsQuery, () => ({start: paymentsPaging.start, limit: paymentsPaging.limit}))

    const payments = useResult(getPaymentsQR, null, data => data.payments)
    const paymentsCount = useResult(getPaymentsQR, null, data => data.paymentsConnection.aggregate.count)
    paymentsPaging.max = paymentsCount as any

    // Search payments
    const searchListPaging = reactive({
      max: 0,
      searchTerm: ref('')
    })
    const searchPaymentsOptions = reactive({enabled : false})
    const { result: _searchPayments } = useQuery(searchPaymentsQuery, searchListPaging, searchPaymentsOptions)
    const searchedPayments = useResult(_searchPayments, null, data => data.payments)

    const searchPayments = (event: KeyboardEvent) => {
      searchPaymentsOptions.enabled = true
      console.log(searchListPaging.searchTerm)
    }


    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-payment-update")
    }

    

    return {
      updateId,
      setupEditModal,

      payments,
      getPaymentsLoading,
      getPaymentsError,

      paymentsPaging,

      searchPayments,
      searchedPayments,
      searchListPaging,

      helpers, invoiceHelpers
    }

  }
})
</script>