
import { defineComponent, inject, ref, reactive } from 'vue'
import { Emitter } from 'mitt'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import getMembershipsQuery from '@/graphql/membership/gets.gql'

import MembershipUpdateModal from '@/components/modals/MembershipUpdate.vue'

export default defineComponent({
  name: 'Memberships',
  components: {
    MembershipUpdateModal
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    // Memberships list
    const membershipsPaging = reactive({
      start: 0,
      limit: 100,
      max: 0,
      noMembership: false
    })

    const { result:getMembershipsQR, loading: getMembershipsLoading, error: getMembershipsError, refetch: refetchMemberships} = useQuery(getMembershipsQuery,  () => ({start: membershipsPaging.start, limit: membershipsPaging.limit}))

    const memberships = useResult(getMembershipsQR, null, data => data.memberships)
    const membershipsCount = useResult(getMembershipsQR, null, data => data.membershipsConnection.aggregate.count)
    membershipsPaging.max = membershipsCount as any

    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-membership-update")
    }

    $emitter.on('memberships-refresh', () => {
      refetchMemberships()
    })

    return {
      memberships,
      membershipsPaging,
      getMembershipsLoading,
      getMembershipsError,

      updateId,
      setupEditModal,

      helpers, userHelpers
    }

  },
  methods: {
    /*async createMembershipAllInvoices() {
      for (let i = 0; i < this.memberships.length; ++i) {
        const user = this.memberships[i]
        const date = this.invoicesForm.date
        const due_date = this.invoicesForm.due_date
        const dateString = helpers.dateobjToISO(date)
        const dueDateString = helpers.dateobjToISO(due_date)

        console.log(await this.createMembershipInvoice({input: { data: { description: this.invoicesForm.name+user.username, total: user.membership.price, users_permissions_user: user.id, date: dateString, due_date: dueDateString }}}))
        //this.invoicesForm.number++
      }
    }*/
  }
})
