
import { defineComponent } from 'vue';
import { helpers, invoiceHelpers, paymentHelpers } from '@/modules/helpers'
import ElementDisplay from '@/components/element_displays/Invoice.vue'

export default defineComponent({
  name: 'Invoices',
  components: {
    ElementDisplay
  },
  props: {
    invoices: Object,
    showAllInfos: Boolean
  },
  setup() {    
    return {
      helpers, invoiceHelpers, paymentHelpers
    }
  }
})
