<template>
  <template v-if="theme === undefined">
    <div class="row cards">
      <JbUPanel type="card" :title="$t('ui.invoice.details.pdf.file')">
        <div style="display:flex;flex-flow:column wrap; row-gap:5px;">
          <div>
            <button @click="generateInvoice(invoice.id)"><font-awesome-icon icon="cogs" />{{ $t('ui.invoice.details.pdf.generate.name') }}</button>
          </div>
          <div>
            <a v-if="invoice.file" :href="$ContentManagerUrl+invoice.file.url" target="_blank"><button><font-awesome-icon icon="file-pdf" />{{ $t('ui.invoice.details.pdf.see') }}</button></a>
            <button v-if="invoice.file" @click="sendByEmailInvoice(invoice.id)"><font-awesome-icon icon="envelope" />{{ $t('ui.invoice.details.pdf.send_by_email') }}</button>
          </div>
        </div>
        <JbUAlert v-if="generateStatus.error" type="error">{{ $t('ui.invoice.details.pdf.generate.error') }} : {{ generateStatus.message }}</JbUAlert>
        <JbUAlert v-if="generateStatus.success" type="success">{{ $t('ui.invoice.details.pdf.generate.success') }}</JbUAlert>
      </JbUPanel>

      <JbUPanel type="card" :title="$t('ui.invoice.details.products')">
        <h2>{{ invoice.total }}  {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>

        <div class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.product.fields.name') }}</div>
            <div>{{ $t('ui.product.fields.quantity') }}</div>
            <div>{{ $t('ui.product.fields.price') }}</div>
          </div>
          <div v-for="listEntry of invoice.product_list" :key="listEntry">
            <div>{{ listEntry.product.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.product.price }} {{ listEntry.product.currency.symbol }}</div>
          </div>
          <div v-for="listEntry of invoice.custom_product_list" :key="listEntry">
            <div>{{ listEntry.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.price }} {{ listEntry.currency.symbol }}</div>
          </div>
        </div>
      </JbUPanel>
      <JbUPanel v-if="invoice.payments" type="card" :title="$t('ui.invoice.details.payments')" id="payments">
        <h2>{{ totalPayments }} {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>
        <div class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.payment.fields.amount') }}</div>
            <div>{{ $t('ui.common.date') }}</div>
          </div>
          <div v-for="payment of invoice.payments" :key="payment">
            <div>{{ payment.amount }} {{ payment.currency.symbol }}</div>
            <div>{{ $d(new Date(payment.created_at), 'short') }}</div>
          </div>
        </div>
        <h2>{{ $t('ui.invoice.details.rest_due') }} : {{ leftToPay }} {{ invoice.currency ? invoice.currency.symbol : "??" }} {{ leftToPay === 0 ? '✔️' : '❌' }}</h2>
      </JbUPanel>
    </div>
  </template>
  <template v-if="theme === 'full'">
    <div class="row cards">
      <JbUPanel type="card" title="QR Code" icon="qrcode">
        <QRCodeVue3
          class="qrcode"
          :width="200"
          :height="200"
          :value="QRCodeLink"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4 }"
          :dotsOptions="{
            type: 'classy',
            color: '#000',
          }"
          image="img/favicons/android-chrome-192x192.png"
          :backgroundOptions="{ color: '#FFF' }"
          :cornersSquareOptions="{ type: 'extra-rounded', color: '#000' }"
          :cornersDotOptions="{ type: 'dot', color: '#000' }"
        />
      </JbUPanel>

      <JbUPanel type="card" :title="$t('ui.invoice.details.products')">
        <div class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.product.fields.name') }}</div>
            <div>{{ $t('ui.product.fields.quantity') }}</div>
            <div>{{ $t('ui.product.fields.price') }}</div>
          </div>
          <div v-for="listEntry of invoice.product_list" :key="listEntry">
            <div>{{ listEntry.product.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.product.price }} {{ listEntry.product.currency.symbol }}</div>
          </div>
          <div v-for="listEntry of invoice.custom_product_list" :key="listEntry">
            <div>{{ listEntry.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.price }} {{ listEntry.currency.symbol }}</div>
          </div>
        </div>
        
        <h2 style="text-align:right;margin-top:var(--space-5)">{{ $t('ui.invoice.details.total') }} : {{ invoice.total }}  {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>
      </JbUPanel>
      <JbUPanel v-if="invoice.payments" type="card" :title="$t('ui.payment.name', 2)" id="payments">
        <h2>{{ totalPayments }}  {{ invoice.currency ? invoice.currency.symbol : "??" }} / {{ invoice.total }}  {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>
        <div v-if="invoice.payments && invoice.payments.length > 0" class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.payment.fields.amount') }}</div>
            <div>{{ $t('ui.common.date') }}</div>
          </div>
          <div v-for="payment of invoice.payments" :key="payment">
            <div>{{ payment.amount }} {{ payment.currency.symbol }}</div>
            <div>{{ $d(new Date(payment.created_at), 'short') }}</div>
          </div>
        </div>
        
        <JbUCard v-if="leftToPay > 0" style="cursor:pointer; margin-top:var(--space-5);" background="linear-gradient(to bottom,#00ac73 0,#009eac 100%)" @click="initPayment">
          <div style="text-align:center">{{ $t('ui.payment.more.make_a_payment' )}}</div>
        </JbUCard>
        <JbUAlert v-else type="success" style="margin-top:var(--space-5);" background="linear-gradient(to bottom,#00ac73 0,#009eac 100%)">
          <span><font-awesome-icon icon="check-circle" /> {{ $t('ui.invoice.details.is_paid') }}</span>
        </JbUAlert>
        
      </JbUPanel>
    </div>
  </template>
  <ModalSumupPayment :initFlag="modalInitFlag" :invoice="invoice"/>
</template>

<script lang="ts">
import { defineComponent,defineAsyncComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers, paymentHelpers } from '@/modules/helpers'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import sendByEmailInvoiceMutation from '@/graphql/invoice/sendByEmail.gql'

import QRCodeVue3 from "qrcode-vue3"
import ModalSumupPayment from '@/components/modals/SumupPayment.vue'

export default defineComponent({
  name: 'Invoice',
  props: {
    invoice: Object,
    theme: {
      type: String,
      default: undefined
    },
  },
  components: {
    QRCodeVue3,
    ModalSumupPayment
  },
  setup(props) {
    // get emitter
    const emitter = inject('$emitter') as Emitter
    const QRCodeLink = window.location.href
    
    // Payments
    const totalPayments = ref(0)
    if(props.invoice && props.invoice.payments && props.invoice.payments.length > 0) {
      totalPayments.value = paymentHelpers.getTotalPayments(props.invoice.payments)
    }

    const leftToPay = ref(0)
    if(props.invoice) {
      leftToPay.value = props.invoice.total - totalPayments.value
    }

    const modalInitFlag = ref(false)
    const initPayment = () => {
      modalInitFlag.value = true
      emitter.emit("open-modal-sumup-payment")
    }
    

    return {
      QRCodeLink,
      helpers,

      totalPayments,
      leftToPay,

      initPayment,
      modalInitFlag
    }

  }
})
</script>

<style scoped lang="scss">
#payments {
  .generic-table > div > div {
    padding:var(--space-1);
  }
}
</style>