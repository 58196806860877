export default {

  getTotalPayments(payments: any[]): number {
    let total = 0
    for (const payment of payments) {
      total += payment.amount
    }
    return total
  }
  
}