
import { defineComponent, computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'

import searchInvoicesQuery from '@/graphql/invoice/search.gql'
import searchUsersQuery from '@/graphql/user/search.gql'
import getCurrenciesQuery from '@/graphql/currency/gets.gql'

export default defineComponent({
  name: 'PaymentForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const { t, locale } = useI18n({ useScope: 'global' })

    const formRef = ref<null | HTMLFormElement>(null)

    const selectedInvoice = ref<{[key: string]: any}>({})
    const preselectedInvoice = ref({ id:0, name:'' })
    const selectedUser = ref<{[key: string]: any}>({})
    const preselectedUser = ref({ id:0, name:'' })
    
    const form: { [Key: string]: any } = reactive({
      id: '',
      amount: 0,
      type: 'cash',
      invoiceId: "0",
      currencyId: 1,

      useInvoiceUser: true
    })

    // Fields
    ////////// Field : Invoice - Search
    const { searchFunction: searchInvoices, ElementsList: searchedInvoices } = searchHelper('invoices', searchInvoicesQuery)

    const searchedInvoicesList = computed(() => {
      return toSearchList(searchedInvoices, 'invoice')
    })

    const updateSelectedInvoice = (item: {[key: string]: any}) => {
      selectedInvoice.value = helpers.getByIdInArray(item.id, searchedInvoices.value as any[])
      form.invoiceId = (selectedInvoice.value as any).id
    }

    ////////// Field : User - Search
    const { searchFunction: searchUsers, ElementsList: searchedUsers } = searchHelper('users', searchUsersQuery)

    const searchedUsersList = computed(() => {
      return toSearchList(searchedUsers, 'user')
    })

    const updateSelectedUser = (item: {[key: string]: any}) => {
      selectedUser.value = helpers.getByIdInArray(item.id, searchedUsers.value as any[])
      form.userId = (selectedUser.value as any).id
    }

    ////////// Field : Currency - Gets
    const { result: getCurrenciesResult } = useQuery(getCurrenciesQuery)
    const currencies = useResult(getCurrenciesResult, null, data => data.currencies)

    const setAmountToTotal = () => {
      if(Object.keys(selectedInvoice.value).length !== 0) {
        form.amount = selectedInvoice.value.total
      }
    }


    const validate = () => {
      if(formRef.value != null)
        return formRef.value.validateForm(form)
    }

    // Testing purposes
    const fillForm = () => {
      console.log("Signup 'Fill Form' button clicked.")
      // With validation
      form.first_name.model= 'a_test_first_name'
      form.second_name.model = 'test_second_name'
      form.last_name.model = 'a_test_last_name'
    }

    return {
      form,
      formRef,

      currencies,

      searchInvoices,
      searchedInvoicesList,
      updateSelectedInvoice,
      selectedInvoice,
      preselectedInvoice,

      searchUsers,
      searchedUsersList,
      updateSelectedUser,
      selectedUser,
      preselectedUser,

      setAmountToTotal,

      fillForm,
      validate,
      helpers, userHelpers
    }
  }
});
