
import { defineComponent, inject, watch, reactive } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getElementQuery from '@/graphql/membership/get.gql'
import updateElementMutation from '@/graphql/membership/update.gql'
import deleteElementMutation from '@/graphql/membership/delete.gql'

import getMembershipsQuery from '@/graphql/membership/gets.gql'

export default defineComponent({
  name: 'MembershipUpdate',
  props: ['elementId', 'listPaging'],
  setup(props) {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    const { t } = useI18n({ useScope: 'global' })

    
    const form: { [Key: string]: any } = reactive({
      id:'',
      balance: 0,
      membershipTypeId: 0
    })


    ////////// MODAL EDIT ID
    const editingElement = reactive({id: props.elementId})
    const editingElementOptions = reactive({enabled : false})
    const { result: getElement, loading: editingElementLoading, onResult: editingElement_onResult } = useQuery(getElementQuery, editingElement, editingElementOptions)
    const updateElementInfos = useResult(getElement, null, data => data.membership)
    
    watch(() => props.elementId, (newValue) => {
      //console.log(newValue)
      editingElement.id = newValue
      editingElementOptions.enabled = true
    })

    editingElement_onResult(queryResult => {
      if(queryResult.networkStatus === 7) { // 7 mean OK https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts
        //console.log("Element Fetch by id ", updateElementInfos.value.id)
        for (const [key] of Object.entries(updateElementInfos.value)) {
          if(key === 'birth_date') {
            form[key] = new Date(updateElementInfos.value[key])
          } else { // The rest is automatically set
            form[key] = updateElementInfos.value[key]
          }
        }
      } else {
        console.log("Element Fetch by id failed.")
      }
    })

    ////////// UPDATE QUERY
    const { mutate: updateMutation, onDone: updateMutationDone, onError: updateMutationError } = useMutation(updateElementMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updateMutationDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updateMutationError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updateMutationDone((QR) => {
      console.log(QR)
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.membership.update.confirm'),
          text: ""+QR.data.updateMembership.membership.balance,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-membership-update")
    })
    updateMutationError(() => {
      console.log('Edit membership Error')
    })

    ////////// DELETE QUERY
    const { mutate: deleteMutation, onDone: deleteMutationDone, onError: deleteMutationError } = useMutation(deleteElementMutation)
    const removeStatus = reactive({
      success: false,
      error: false
    })
    deleteMutationDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.membership.delete.confirm'),
          text: ""+QR.data.deleteMembership.membership.balance,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-membership-update")
    })
    deleteMutationError(() => {
      removeStatus.error = true
      removeStatus.success = false
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const queryInput = { 
        input: { 
          where: {
            id: form.id,
          },
          data: {
            balance: form.balance
          }
        }
      }

      updateMutation(queryInput)
    }

    ////////// MODAL DELETE BUTTON
    const deleteElement = () => {
      if (confirm(t('ui.element.delete.confirm'))) {
        const queryInput = { 
          input: { 
            where: {
              id: form.id,
            }
          }
        }

        deleteMutation(queryInput, {
          update: (cache, { data: { deleteMembership: deleteMutation } }) => { // Needs to have the graphQL query name
            const membershipsCache: any = cache.readQuery({ query: getMembershipsQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit} })

            if(membershipsCache) { // If we are on a page where getMembershipsQuery has been used
              let membershipsClone = [...membershipsCache.memberships]
              membershipsClone = membershipsClone.filter((t: { id: number }) => {
                return t.id !== deleteMutation.membership.id;
              })

              cache.writeQuery({ query: getMembershipsQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit}, data: {
                memberships: membershipsClone
              }})
            }
          }
        })
      }
    }

    // Testing purposes
    const fillForm = () => {
      form.balance = 548
    }

    return {
      form,
      submit_modal,
      deleteElement,
      fillForm,
      updateStatus
    }
  }
});
