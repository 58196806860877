
import { defineComponent, inject, computed, reactive, ref } from 'vue'
import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Settings',
  data() {
    return {
      usersToImport: [],
    }
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    

    return {
    }

  }
})
