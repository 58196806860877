
import { defineComponent, inject, watch, watchEffect, computed, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'
import _ from "lodash";

import searchUsersQuery from '@/graphql/user/search.gql'

import getCountriesQuery from '@/graphql/country/gets.gql'

export default defineComponent({
  name: 'UserForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const currentYear = new Date().getFullYear()

    const { t, locale } = useI18n({ useScope: 'global' })

    const formRef = ref<null | HTMLFormElement>(null)
    
    const preselectedUsers = reactive([])

    const form: { [Key: string]: any } = reactive({
      email: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      password: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      password_confirm: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      first_name: {
        model: '',
        validation: {
          max: 25,
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      second_name: {
        model: '',
        validation: {
          
        }
      },
      last_name: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      birth_name: {
        model: '',
        validation: {
          
        }
      },
      gender: {
        model: 'male'
      },
      birth_day: {
        model: '01'
      },
      birth_month: {
        model: '01'
      },
      birth_year: {
        model: currentYear-1
      },
      cellphone: {
        model: '',
        code:'+41',
        validation: {
          
        }
      },
      phone_private: {
        model: '',
        code:'+41',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      street: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      street_extra: {
        model: '',
        validation: {
          
        }
      },
      postal_code: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      city: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      region: {
        model: '',
        validation: {
          
        }
      },
      job: {
        model: '',
        validation: {
          
        }
      },
      problems: {
        model: '',
        validation: {
          
        }
      },
      medication: {
        model: '',
        validation: {
          
        }
      },
      hear_about_us: {
        model: '',
        validation: {
          
        }
      },
      recommendation: {
        model: '',
        validation: {
          
        }
      },
      country: {
        model: 1
      },
      electronic_invoices: {
        model: false
      },
      marketing: {
        model: false
      },
      data_optin: {
        model: false,
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      relationships: [] as any[]
    })
    

    ////////// Field : Country - Gets
    const { result: getCountriesResult, onResult: getCountriesOnResult} = useQuery(getCountriesQuery, {locale: locale})
    const countries = useResult(getCountriesResult, null, data => data.countries)
    getCountriesOnResult((QR) => {
      form.country.model = QR.data.countries[0].id
    })

    const addRelationshipToFormList = (type: string) => {
      form.relationships.push({type: "family", user: 0, deducedRelationship: "", deducedRelationshipName: ""})
      //console.log(form.relationships)
    }
    const removeRelationshipFromFormList = (index: number) => {
      form.relationships.splice(index, 1)
    }

    const deduceRelationship = (relationship: any) => {      
      if(relationship.type === 'family') {
        relationship.deducedRelationship = 'family'
      } else if(relationship.type === 'friend') {
        relationship.deducedRelationship = 'friend'
      } else if(relationship.type === 'father' || relationship.type === 'mother') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'daughter' : 'son'
      } else if(relationship.type === 'grandfather' || relationship.type === 'grandmother') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'granddaughter' : 'grandson'
      } else if(relationship.type === 'brother' || relationship.type === 'sister') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'sister' : 'brother'
      } else if(relationship.type === 'son' || relationship.type === 'daughter') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'mother' : 'father'
      } else if(relationship.type === 'grandson' || relationship.type === 'granddaughter') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'grandmother' : 'grandfather'
      } else if(relationship.type === 'cousin' || relationship.type === 'cousine') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'cousine' : 'cousin'
      }
      relationship.deducedRelationshipName = t('ui.relationship.type.'+relationship.deducedRelationship)
    }


    watch(() => _.cloneDeep(form.relationships), () => {
      for(const relationship in form.relationships) {
        deduceRelationship(form.relationships[relationship])
      }
    })

    watchEffect(() => {
      for(const relationship in form.relationships) {
        deduceRelationship(form.relationships[relationship])
      }
    })

    ////////// Field : User - Search
    const { searchFunction: searchUsers, ElementsList: searchedUsers } = searchHelper('users', searchUsersQuery)

    const searchedUsersList = computed(() => {
      return toSearchList(searchedUsers, 'user')
    })

    const updateSelectedUser = (item: {[key: string]: any}, key: string) => {
      const selectedUser = helpers.getByIdInArray(item.id, searchedUsers.value as any[])
      form.relationships[key].user = selectedUser.id
    }
    

    const validate = () => {
      if(formRef.value != null)
        return formRef.value.validateForm(form)
    }

    // Testing purposes
    const fillForm = () => {
      console.log("Signup 'Fill Form' button clicked.")
      // With validation
      form.first_name.model= 'a_test_first_name'
      form.second_name.model = 'test_second_name'
      form.last_name.model = 'a_test_last_name'
      form.birth_name.model = 'test_birth_name'
      form.job.model = 'test_job'
      form.phone_private.model = '666666666'
      form.cellphone.model = '666666667'
      form.street.model = 'test_street'
      form.street_extra.model = 'test_street_extra'
      form.postal_code.model = 'test_postal_code'
      form.city.model = 'test_city'
      form.region.model = 'test_region'

      form.email.model ='testemail@email.fake'
      form.password.model = 'test_password'
      form.password_confirm.model = 'test_password'
      form.problems.model = 'test_problems'
      form.medication.model = 'test_medication'
      form.hear_about_us.model = 'test_hear_about_us'
      form.recommendation.model = 'test_recommendation'

      form.birth_day.model = '05'
      form.birth_month.model = '07'
      form.birth_year.model = 1991

      // Without validation
      form.gender.model = 'other'
      form.electronic_invoices.model = true
      form.data_optin.model = true
      form.marketing.model = true
    }

    return {
      form,
      formRef,

      currentYear,
      countries,
      addRelationshipToFormList, removeRelationshipFromFormList,
      deduceRelationship,
      
      searchUsers,
      searchedUsersList,
      updateSelectedUser,
      preselectedUsers,

      fillForm,
      validate
    }
  }
});
