<template>
  <JbCForm :validation="form" ref="formRef">
    <div class="form-group-label">{{ $t('ui.invoice.label_groups.infos') }}</div>
    <div class="form-row">
      <label><span class="mid-dot c-blue">•</span>{{ $t('ui.user.name') }}</label>
      <div><JbCSearch v-model="form.userId" name="user" :options="searchedUsersList" :placeholder="$t('ui.user.search.name')+'...'" :preselected="preselectedUser" @keyUp="searchUsers" @listItemClick="updateSelectedUser"/></div>
    </div>
    <div v-if="selectedUser && selectedUser.membership" class="form-row">
      <label>{{ $t('ui.membership.name') }}</label>
      <JbUCard theme="input" id="invoice-membership">
        <div class="generic-flexbox-column">
          <div>
            <div>{{ $t('ui.membership.fields.type') }}</div><div>{{ selectedUser.membership.membership_type.name }}</div>
          </div>
          <div>
            <div>{{ $t('ui.membership.fields.discount_prc') }}</div><div>{{ selectedUser.membership.membership_type.discount_prc }} %</div>
          </div>
        </div>
      </JbUCard>
    </div>
    <div class="form-row">
      <label :for="'description-'+$.uid">{{ $t('ui.invoice.fields.description') }}</label>
      <div><input type="text" name="description" :id="'description-'+$.uid" v-model="form.description" :placeholder="$t('ui.invoice.fields.description')"></div>
    </div>
    <div v-if="display === 'update'" class="form-row">
      <label>{{ $t('ui.invoice.fields.number') }}</label>
      <div style="display:flex">
        <input type="text" name="number_month" v-model="form.number_month" :placeholder="$t('ui.invoice.fields.number_month')">
        <input type="number" name="number_in_month" v-model.number="form.number_in_month" :placeholder="$t('ui.invoice.fields.number_in_month')">
      </div>
    </div>

    <div class="form-row">
      <label>{{ $t('ui.invoice.fields.date') }}</label>
      <v-date-picker v-model="form.date">
        <template v-slot="{ inputValue, togglePopover }">
          <input type="text" name="date" :value="inputValue" @click="togglePopover()" readonly />
        </template>
      </v-date-picker>
    </div>
    <div class="form-row">
      <label>{{ $t('ui.invoice.fields.due_date') }}</label>
      <v-date-picker v-model="form.due_date">
        <template v-slot="{ inputValue, togglePopover }">
          <input type="text" name="due_date" :value="inputValue" @click="togglePopover()" readonly />
        </template>
      </v-date-picker>
    </div>
    <div class="form-row">
      <label>{{ $t('ui.invoice.fields.products') }}</label>
      <JbUCard theme="input">
        <div class="product_list">
          <div v-for="(listEntry, index) of form.product_list" :key="listEntry" >
            <template v-if="listEntry.product">
              <div>{{ listEntry.product.name }}</div>
              <div class="spacer"></div>

              <div>{{ listEntry.product.price }}</div>
              <div>{{ listEntry.product.currency.symbol }}</div>
              <div><input style="display:inline-block; width:60px" type="number" v-model.number="listEntry.quantity" /></div>
              <div><button class="error icon-only" @click.stop="removeProductFromFormList(index)"><font-awesome-icon icon="times"/></button></div>
            </template>
            <template v-else>
              <div>Erreur: produit non défini dans la base de données.</div>
            </template>

          </div>
          <div v-for="(listEntry, index) of form.custom_product_list" :key="listEntry" >
            <div><input style="display:inline-block;" type="text" v-model="listEntry.name" /></div>
            <div class="spacer"></div>
            <div><input style="display:inline-block;" type="text" v-model="listEntry.custom_text" /></div>
            <div><input style="display:inline-block; width:80px" type="number" v-model.number="listEntry.price" /></div>
            <div>
              <select v-if="currencies" v-model="listEntry.currency.id">
                <option v-for="currency of currencies" :key="currency" :value="currency.id">{{ currency.symbol }}</option>
              </select>
            </div>
            <div><input style="display:inline-block; width:60px" type="number" v-model.number="listEntry.quantity" /></div>
            <div><button class="error icon-only" @click.stop="removeCustomProductFromFormList(index)"><font-awesome-icon icon="times"/></button></div>
          </div>
        </div>
        <div style="margin-top:15px;">
          <JbUDropdown :title="$t('ui.product.create.name')" icon="plus" display="button">
            <div @click="addCustomProductToFormList(index)">
              <font-awesome-icon icon="plus"/> {{ $t('ui.product.more.new_custom') }}
            </div>
            <div v-for="product of productsOrphan" :key="product" @click="addProductToFormList(product)">
              {{ product.name }} ({{ product.price }} CHF)
            </div>
            <div v-for="category of productCategories" :key="category" class="has-children">
              <div>{{ category.name }} <font-awesome-icon icon="caret-right" /></div>
              <div class="children">
                <div v-for="product of category.products" :key="product" @click="addProductToFormList(product)">
                  {{ product.name }} ({{ product.price }} CHF)
                </div>
              </div>
            </div>
          </JbUDropdown>
        </div>
      </JbUCard>
    </div>
    <div class="form-row">
      <label :for="'currency-'+$.uid">{{ $t('ui.payment.fields.currency') }}</label>
      <div>
        <select v-if="currencies" v-model="form.currencyId" :id="'currency-'+$.uid">
          <option v-for="currency of currencies" :key="currency" :value="currency.id">
            {{ currency.name }} ({{ currency.symbol }})
          </option>
        </select>
      </div>
    </div>

    <div class="form-group-label">{{ $t('ui.invoice.details.total') }}</div>

    <h2 style="text-align:right"><template v-if="selectedUser && selectedUser.membership"><span style="text-decoration:line-through;">{{ total }} {{ currencySymbol }}</span> <font-awesome-icon icon="arrow-right" /></template> {{ total_afterDiscount }} {{ currencySymbol }}</h2>
  </JbCForm>
</template>

<script lang="ts">
import { defineComponent, inject, watch, computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'

import searchUsersQuery from '@/graphql/user/search.gql'
import getProductsQuery from '@/graphql/product/gets_byCategory.gql'
import getCurrenciesQuery from '@/graphql/currency/gets.gql'

export default defineComponent({
  name: 'InvoiceForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const formRef = ref<null | any>(null)

    const { t, locale } = useI18n({ useScope: 'global' })

    const selectedUser = ref<{[key: string]: any}>({})
    const preselectedUser = ref({ id:0, name:'' })

    const form: { [Key: string]: any } = reactive({
      id:'',
      description: t('ui.invoice.fields.description_auto'),
      date: new Date(),
      due_date: new Date(),
      product_list: [] as any[],
      custom_product_list: [] as any[],
      userId: "0",
      currencyId: 1
    })
    form.due_date = new Date(form.due_date.getTime() + (30*86400000))
    

    // Fields
    ////////// Field : User - Search
    const { searchFunction: searchUsers, ElementsList: searchedUsers } = searchHelper('users', searchUsersQuery)

    const searchedUsersList = computed(() => {
      return toSearchList(searchedUsers, 'user')
    })

    const updateSelectedUser = (item: {[key: string]: any}) => {
      selectedUser.value = helpers.getByIdInArray(item.id, searchedUsers.value as any[])
      form.userId = (selectedUser.value as any).id
    }

    ////////// Field : Products - Gets
    const { result: getProductsQR } = useQuery(getProductsQuery, {locale: locale})
    const productCategories = useResult(getProductsQR, null, data => data.productCategories)
    const productsOrphan = useResult(getProductsQR, null, data => data.products)

    ////////// Field : Currency - Gets
    const { result: getCurrenciesResult } = useQuery(getCurrenciesQuery)
    const currencies = useResult(getCurrenciesResult, null, data => data.currencies)

    const currencySymbol = computed(() => {
      let selectedCurrency = "Unknown Currency"
      let currency
      
      if(currencies) {
        currency = helpers.getByIdInArray(form.currencyId, currencies.value as any[])
        if(currency !== 0)
          selectedCurrency = currency.symbol
      }

      return selectedCurrency
    })

    const validate = () => {
      return formRef.value.validateForm(form)
    }

    const addProductToFormList = (product: any) => {
      form.product_list.push({product, quantity: 1})
      //console.log(form.product_list)
    }

    const addCustomProductToFormList = () => {
      form.custom_product_list.push({name: "", custom_text: "", price: 0, currency: { id: 1, symbol: "CHF" }, quantity: 1, type: "custom"})
      //console.log(form.custom_product_list)
    }
    

    const removeProductFromFormList = (index: number) => {
      form.product_list.splice(index, 1)
    }
    const removeCustomProductFromFormList = (index: number) => {
      form.custom_product_list.splice(index, 1)
    }

    
    const total = computed(() => {
      return invoiceHelpers.computeTotal(form.product_list) + invoiceHelpers.computeTotal(form.custom_product_list, {custom_products:true})
    })
    const total_afterDiscount = computed(() => {
      if(selectedUser.value.id && selectedUser.value.membership) {
        return invoiceHelpers.computeTotal(form.product_list, {discount_prc: selectedUser.value.membership.membership_type.discount_prc, products_filter: ['consultation']}) + invoiceHelpers.computeTotal(form.custom_product_list, {custom_products:true})
      } else return invoiceHelpers.computeTotal(form.product_list) + invoiceHelpers.computeTotal(form.custom_product_list, {custom_products:true})
    })

    // Testing purposes
    const fillForm = () => {
      form.description = 'test_description',
      (form.product_list as any).push(
        { product : { "id": "19", "name": "Erster Termin mit Robert", "price": 250, "type": "consultation", "currency": { "id": "1", "name": "Swiss Franc", "symbol": "CHF", "__typename": "Currency" }, "__typename": "Product" }, quantity: 3 },
        { product : { "id": "19", "name": "Erster Termin mit Robert", "price": 250, "type": "consultation", "currency": { "id": "1", "name": "Swiss Franc", "symbol": "CHF", "__typename": "Currency" }, "__typename": "Product" }, quantity: 1 },
        { product : { "id": "19", "name": "Erster Termin mit Robert", "price": 250, "type": "consultation", "currency": { "id": "1", "name": "Swiss Franc", "symbol": "CHF", "__typename": "Currency" }, "__typename": "Product" }, quantity: 2 }
      )
    }

    return {
      form,
      formRef,

      searchUsers,
      searchedUsersList,
      updateSelectedUser,
      selectedUser,
      preselectedUser,

      addProductToFormList,
      addCustomProductToFormList,
      removeProductFromFormList,
      removeCustomProductFromFormList,

      currencies,
      currencySymbol,

      productCategories,
      productsOrphan,

      total,
      total_afterDiscount,

      fillForm,
      validate
    }
  }
});
</script>

<style scoped lang="scss">
.product_list {
  > div {
  border-bottom: 1px solid var(--space-3);
  display:flex;
  align-items: center;

    > div {
      padding:var(--space-3);
    }
  }
}
</style>