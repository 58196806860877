
import { defineComponent, inject, reactive, ref, computed } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers, appointmentHelpers, paymentHelpers } from '@/modules/helpers'
import getAppointmentsQuery from '@/graphql/appointment/gets.gql'

import searchAppointmentsQuery from '@/graphql/appointment/search.gql'
import ElementUpdateModal from '@/components/modals/AppointmentUpdate.vue'
//import ElementDisplay from '@/components/element_displays/Appointment.vue'

import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Appointments',
  components: {
    ElementUpdateModal,
    //ElementDisplay
  },
  setup() {
    // get Global properties
    const emitter = inject('$emitter') as Emitter

    // Appointments list
    const appointmentListPaging = reactive({
      start: 0,
      limit: 100,
      max: 0
    })
    const { result: getAppointmentsResult, loading: getAppointmentsLoading, error: getAppointmentsError, refetch: refetchAppointments } = useQuery(getAppointmentsQuery, () => ({start: appointmentListPaging.start, limit: appointmentListPaging.limit}))

    const appointments = useResult(getAppointmentsResult, null, data => data.appointments)
    const appointmentsCount = useResult(getAppointmentsResult, null, data => data.appointmentsConnection.aggregate.count)
    appointmentListPaging.max = appointmentsCount as any

    emitter.on('appointments-refresh', () => {
      refetchAppointments()
    })

    // Search appointments
    const searchListPaging = reactive({
      max: 0,
      searchTerm: ref('')
    })
    const searchAppointmentsOptions = reactive({enabled : false})
    const { result: _searchAppointments } = useQuery(searchAppointmentsQuery, searchListPaging, searchAppointmentsOptions)
    const searchedAppointments = useResult(_searchAppointments, null, data => data.appointments)

    const searchAppointments = (event: KeyboardEvent) => {
      searchAppointmentsOptions.enabled = true
      console.log(searchListPaging.searchTerm)
    }

    // Appointment ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id 
      emitter.emit("open-modal-appointment-update")
    }

    const listToDisplay = computed(() => {
      if(searchListPaging.searchTerm.length > 1)
        return searchedAppointments
      else
        return appointments
    })

    return {
      updateId,
      setupEditModal,

      appointments,
      appointmentsCount,
      getAppointmentsLoading,
      getAppointmentsError,
      
      searchAppointments,
      searchedAppointments,
      searchListPaging,

      appointmentListPaging,
      listToDisplay,

      helpers, userHelpers, appointmentHelpers, paymentHelpers
    }
  }
})
