<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Settings</p><br/>
    <p>Where all the settings would reside.</p><br/>
    <p>Currently only used to customize the billable products list.</p>
  </JbUAlert>
  <div class="header">
    <h1>{{ $t('ui.page.settings.name') }}</h1>
  </div>
  <JbUPanel :title="$t('ui.page.settings.name')" icon="cog">
      <router-link to="products">
        <JbUCard :title="$t('ui.product.name', 2)" icon="book-medical" background="var(--color-brown-darker)" :footer="$t('ui.page.settings.products')">
        </JbUCard>
      </router-link>
  </JbUPanel>
</template>

<script lang="ts">
import { defineComponent, inject, computed, reactive, ref } from 'vue'
import { Emitter } from 'mitt'

export default defineComponent({
  name: 'Settings',
  data() {
    return {
      usersToImport: [],
    }
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    

    return {
    }

  }
})
</script>

<style scoped lang="scss">


</style>