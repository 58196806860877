<template>
  <router-view />
  <!--<CookiesNotice/>-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import CookiesNotice from '@/components/CookiesNotice.vue';

export default defineComponent({
  name: 'App',
  components: {
    //CookiesNotice
  }
});
</script>

<style lang="scss">
</style>
