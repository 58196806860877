<template>
  <div class="inline-infos header">
    <div></div>
    <div>{{ $t('ui.invoice.fields.number') }}</div>
    <div>{{ $t('ui.invoice.fields.description') }}</div>
    <div>{{ $t('ui.invoice.fields.date') }}</div>
    <div>{{ $t('ui.invoice.details.total') }} </div>
    <div class="actions">{{ $t('ui.list.actions.name') }}</div>
  </div>
  <template v-for="invoice of invoices" :key="invoice.id">
    <JbUPanelListRow :item-id="invoice.id" @edit-element="setupEditModal(invoice.id)">
      <template #inline-infos>
        <div>{{ invoice.total - paymentHelpers.getTotalPayments(invoice.payments) === 0 ? '✔️' : '❌' }}</div>
        <div>{{ invoiceHelpers.getNumber(invoice) }}</div>
        <div>{{ invoice.description }}</div>
        <div>{{ invoice.date }}</div>
        <div>{{ invoice.total }} {{ invoice.currency ? invoice.currency.symbol : "??" }}</div>
        <div class="actions"><router-link :to="'/administration/invoices/'+invoice.id"><button><font-awesome-icon icon="eye" /></button></router-link></div>
      </template>

      <template v-if="showAllInfos === true" #all-infos>
        <ElementDisplay :invoice="invoice"/>
      </template>
    </JbUPanelListRow>
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { helpers, invoiceHelpers, paymentHelpers } from '@/modules/helpers'
import ElementDisplay from '@/components/element_displays/Invoice.vue'

export default defineComponent({
  name: 'Invoices',
  components: {
    ElementDisplay
  },
  props: {
    invoices: Object,
    showAllInfos: Boolean
  },
  setup() {    
    return {
      helpers, invoiceHelpers, paymentHelpers
    }
  }
})
</script>