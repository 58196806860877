<template>
  <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
    <p>Memberships</p><br/>
    <p>Where most of my initial efforts went.</p><br/>
    <p>So new body concept had a membership system offline before the app. The way is worked is clients would pay 1000/1500/2000 CHF in advance to fill their membership account, and that would give them the rank of "Member" 2/3/4 and a 5/10/15% discount respectively on every therapy product (massage, hypnose, etc.).</p><br/>
    <p>They did everything manually before, and as you might guess, if was tedious, time consuming, and very prone to error.</p><br/>
    <p>So the new system had to compute the total and check beforehand if the user has a membership account and adjust the price accordingly. It also lets the manager select if he wants to use the user's membership balance or regular payments.</p><br/>
    <p>Every creation / edition / deletion of an invoice changes the account balance of its user.</p><br/>
    <p>Here our testPatient (John Doe) has 1000CHF on his account, because he has ordered and paid for a Membercard 2, as reflected in the "Invoices" and "Payments" tabs. By selecting this payment method now he will get 5% discount on the therapy products (but not the medicine)</p><br/>
    <p>One planed feature was to have an reminder email be sent out to the client when the account balance would reach under 300CHF to encourage them to refill their account.</p>
  </JbUAlert>

  <div class="header">
  <h1>{{ $t('ui.membership.name', 2) }}</h1>
  </div>

  <JbUPanel :title="$t('ui.membership.name', 2)" icon="file-invoice-dollar" id="membership" :padding="0">
    <div v-if="getMembershipsError">{{ $t('ui.common.error.name') }}</div>
    <div v-if="memberships">
      <JbUPanelList display="grid" :class="getMembershipsLoading ? 'fetch-loading' : ''" :pagination="true" :pagingVar="membershipsPaging" @updateStart="membershipsPaging.start = $event">
        <div class="inline-infos header">
          <div>{{ $t('ui.membership.fields.balance') }}</div>
          <div>{{ $t('ui.user.name') }}</div>
          <div>{{ $t('ui.user.fields.username') }}</div>
          <div>{{ $t('ui.membership.fields.type') }}</div>
          <div class="actions">{{ $t('ui.list.actions.name') }}</div>
        </div>
        
      <template v-for="membership of memberships" :key="membership.id">
        <JbUPanelListRow editable :item-id="membership.id" @edit-element="setupEditModal(membership.id)">
          <template #inline-infos>
            <div>{{ membership.balance }} {{ membership.currency.symbol }}</div>
            <div>{{ membership.user ? userHelpers.getFullName(membership.user) : 'Orphan Membership' }}</div>
            <div>{{ membership.user ? membership.user.username : 'Orphan Membership' }}</div>
            <div>{{ membership.membership_type.name }}</div>
          </template>

          <template #all-infos>
            <div class="flexbox cards">
              <JbUPanel type="card" :title="$t('ui.membership.more.past_invoices', [10])">
                <template v-if="membership.user.invoices && membership.user.invoices.length > 0">
                  <div class="generic-table">
                    <div class="headers">
                      <div>{{ $t('ui.invoice.fields.description') }}</div>
                      <div>{{ $t('ui.invoice.details.total') }}</div>
                      <div>{{ $t('ui.invoice.details.pdf.file') }}</div>
                    </div>
                    <div v-for="invoice of membership.user.invoices" :key="invoice">
                      <div>{{ invoice.description }}</div>
                      <div>{{ invoice.total }} {{ invoice.currency.symbol }}</div>
                      <div><a v-if="invoice.file" :href="$ContentManagerUrl+invoice.file.url" target="_blank"><font-awesome-icon icon="eye" /></a></div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    {{ $t('ui.membership.more.no_invoices') }}
                  </div>
                </template>
              </JbUPanel>
              <JbUPanel type="card" :title="$t('ui.membership.more.past_appointments', [10])">
                <template v-if="membership.user.appointments && membership.user.appointments.length > 0">
                  <div v-for="invoice of membership.user.invoices" :key="invoice">
                    {{ invoice.description }} {{ invoice.total }} 
                    <a v-if="invoice.file" :href="$ContentManagerUrl+invoice.file.url" target="_blank"><font-awesome-icon icon="eye" /></a>
                  </div>
                </template>
                <template v-else>
                  <div>
                    {{ $t('ui.membership.more.no_appointments') }}
                  </div>
                </template>
              </JbUPanel>
            </div>
          </template>
        </JbUPanelListRow>
      </template>
      </JbUPanelList>
    </div>
  </JbUPanel>
  <MembershipUpdateModal :elementId="updateId" :listPaging="membershipsPaging"/>
</template>

<script lang="ts">
import { defineComponent, inject, ref, reactive } from 'vue'
import { Emitter } from 'mitt'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import getMembershipsQuery from '@/graphql/membership/gets.gql'

import MembershipUpdateModal from '@/components/modals/MembershipUpdate.vue'

export default defineComponent({
  name: 'Memberships',
  components: {
    MembershipUpdateModal
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    // Memberships list
    const membershipsPaging = reactive({
      start: 0,
      limit: 100,
      max: 0,
      noMembership: false
    })

    const { result:getMembershipsQR, loading: getMembershipsLoading, error: getMembershipsError, refetch: refetchMemberships} = useQuery(getMembershipsQuery,  () => ({start: membershipsPaging.start, limit: membershipsPaging.limit}))

    const memberships = useResult(getMembershipsQR, null, data => data.memberships)
    const membershipsCount = useResult(getMembershipsQR, null, data => data.membershipsConnection.aggregate.count)
    membershipsPaging.max = membershipsCount as any

    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-membership-update")
    }

    $emitter.on('memberships-refresh', () => {
      refetchMemberships()
    })

    return {
      memberships,
      membershipsPaging,
      getMembershipsLoading,
      getMembershipsError,

      updateId,
      setupEditModal,

      helpers, userHelpers
    }

  },
  methods: {
    /*async createMembershipAllInvoices() {
      for (let i = 0; i < this.memberships.length; ++i) {
        const user = this.memberships[i]
        const date = this.invoicesForm.date
        const due_date = this.invoicesForm.due_date
        const dateString = helpers.dateobjToISO(date)
        const dueDateString = helpers.dateobjToISO(due_date)

        console.log(await this.createMembershipInvoice({input: { data: { description: this.invoicesForm.name+user.username, total: user.membership.price, users_permissions_user: user.id, date: dateString, due_date: dueDateString }}}))
        //this.invoicesForm.number++
      }
    }*/
  }
})
</script>