<template>
  <JbUModal name="invoice-create" cancellable>
    <template v-slot:headerLeft>
      <div v-if="$AppTesting" class="test-commands"><button @click.stop="fillForm">Fill Form</button></div>
      {{ $t('ui.invoice.create.name') }}
    </template>
    <template v-slot:default>
      <InvoiceForm ref="invoiceFormRef" display="create" type="admin"/>
    </template>
    <template v-slot:footerLeft>
      <JbUAlert v-if="createStatus.error" type="error">{{ $t('ui.invoice.create.error') }}</JbUAlert>
    </template>
    <template v-slot:footerRight>
      <button class="highlighted" @click="submit_modal">{{ $t('ui.invoice.create.name') }}</button>
    </template>
  </JbUModal>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { searchValueNulllIfZero } from '@/modules/search.ts'
import createElementMutation from '@/graphql/invoice/create.gql'
import getInvoicesQuery from '@/graphql/invoice/gets.gql'

import InvoiceForm from '@/components/forms/Invoice.form.vue'

export default defineComponent({
  name: 'InvoiceCreate',
  components: {
    InvoiceForm
  },
  setup() {
    // get emitter
    const emitter = inject('$emitter') as Emitter
    
    const { t, locale } = useI18n({ useScope: 'global' })

    const invoiceFormRef = ref<null | any>(null)

    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 30)

    const form = reactive({
      description: '',
      date: today,
      due_date: tomorrow,
      product_list: [] as any[],
      custom_product_list: [] as any[],
      userId: 0,
      currencyId: 1,
      useMembershipBalance: false
    })

    // Apollo Create Query 
    const { mutate: createElement, onDone: createElementDone, onError: createElementError } = useMutation(createElementMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })
    createElementDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createElementError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    createElementDone((QR) => {
      emitter.emit("create-toast", {
        props: {
          title: t('ui.invoice.create.confirm'),
          text: QR.data.createInvoice.invoice.description,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-create")
    })
    createElementError(() => {
      console.log('Create invoice Error')
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const form = invoiceFormRef.value.form

      const userId = searchValueNulllIfZero(form.userId)

      // Transform product array into a strapi compatible object
      const product_list = invoiceHelpers.productsToStrapi(form.product_list)
      const custom_product_list: any[] = invoiceHelpers.customProductsToStrapi(form.custom_product_list)


      const queryInput = { 
        input: { 
          data: {
            description: form.description,
            product_list: product_list,
            custom_product_list: JSON.parse(JSON.stringify(custom_product_list)),
            date: helpers.dateobjToISO(form.date),
            due_date: helpers.dateobjToISO(form.due_date),
            total: invoiceFormRef.value.total_afterDiscount,
            currency: form.currencyId,
            location: 1
          }
        }
      }

      if(form.userId !== 0) {
        (queryInput.input.data as any).user = form.userId
      }
      if(invoiceFormRef.value.selectedUser.membership) {
        (queryInput.input.data as any).membership_type = invoiceFormRef.value.selectedUser.membership.membership_type.id
      }

      createElement(queryInput, {
        update: (cache, { data: { createInvoice: createElement } }) => { // Needs to have the graphQL query name
          const invoicesCache: any = cache.readQuery({ query: getInvoicesQuery, variables: {start: 0, limit:100} })
          //console.log(data.invoices)

          if(invoicesCache) { // If we are on a page where getInvoicesQuery has been used
            cache.writeQuery({ query: getInvoicesQuery, variables: {start: 0, limit:100}, data: {
              invoices: [createElement.invoice, ...invoicesCache.invoices]
            }})
          }

          emitter.emit('memberships-refresh')
        }
      })
    }

    // Testing purposes
    const fillForm = () => {
      invoiceFormRef.value.fillForm()
    }

    return {
      invoiceFormRef,
      createElement,
      createStatus,

      submit_modal,
      fillForm,

      invoiceHelpers
    }
  }
});
</script>