<template>
  <template v-if="theme === undefined">
    <div class="row cards">
      <JbUPanel type="card" :title="$t('ui.invoice.details.pdf.file')">
        <div style="display:flex;flex-flow:column wrap; row-gap:5px;">
          <div>
            <button @click="generateInvoice(invoice.id)"><font-awesome-icon icon="cogs" />{{ $t('ui.invoice.details.pdf.generate.name') }}</button>
          </div>
          <div>
            <a v-if="invoice.file" :href="$ContentManagerUrl+invoice.file.url" target="_blank"><button><font-awesome-icon icon="file-pdf" />{{ $t('ui.invoice.details.pdf.see') }}</button></a>
            <button v-if="invoice.file" @click="sendByEmailInvoice(invoice.id)"><font-awesome-icon icon="envelope" />{{ $t('ui.invoice.details.pdf.send_by_email') }}</button>
          </div>
        </div>
        <JbUAlert v-if="generateStatus.error" type="error">{{ $t('ui.invoice.details.pdf.generate.error') }} : {{ generateStatus.message }}</JbUAlert>
        <JbUAlert v-if="generateStatus.success" type="success">{{ $t('ui.invoice.details.pdf.generate.success') }}</JbUAlert>
      </JbUPanel>

      <JbUPanel type="card" :title="$t('ui.invoice.details.products')">
        <h2>{{ invoice.total }}  {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>

        <div class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.product.fields.name') }}</div>
            <div>{{ $t('ui.product.fields.quantity') }}</div>
            <div>{{ $t('ui.product.fields.price') }}</div>
          </div>
          <div v-for="listEntry of invoice.product_list" :key="listEntry">
            <div>{{ listEntry.product.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.product.price }} {{ listEntry.product.currency.symbol }}</div>
          </div>
          <div v-for="listEntry of invoice.custom_product_list" :key="listEntry">
            <div>{{ listEntry.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.price }} {{ listEntry.currency.symbol }}</div>
          </div>
        </div>
      </JbUPanel>
      <JbUPanel v-if="invoice.payments" type="card" :title="$t('ui.invoice.details.payments')" id="payments">
        <h2>{{ totalPayments }} {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>
        <div class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.payment.fields.amount') }}</div>
            <div>{{ $t('ui.common.date') }}</div>
          </div>
          <div v-for="payment of invoice.payments" :key="payment">
            <div>{{ payment.amount }} {{ payment.currency.symbol }}</div>
            <div>{{ $d(new Date(payment.created_at), 'short') }}</div>
          </div>
        </div>
        <h2>{{ $t('ui.invoice.details.rest_due') }} : {{ leftToPay }} {{ invoice.currency ? invoice.currency.symbol : "??" }} {{ leftToPay === 0 ? '✔️' : '❌' }}</h2>
      </JbUPanel>
    </div>
  </template>
  <template v-if="theme === 'full'">
    <div class="row cards">
      <JbUPanel type="card" title="QR Code" icon="qrcode">
        <QRCodeVue3
          class="qrcode"
          :width="200"
          :height="200"
          :value="QRCodeLink"
          :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
          :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4 }"
          :dotsOptions="{
            type: 'classy',
            color: '#000',
          }"
          image="img/favicons/android-chrome-192x192.png"
          :backgroundOptions="{ color: '#FFF' }"
          :cornersSquareOptions="{ type: 'extra-rounded', color: '#000' }"
          :cornersDotOptions="{ type: 'dot', color: '#000' }"
        />
      </JbUPanel>

      <JbUPanel type="card" :title="$t('ui.invoice.details.pdf.file')">
        <div style="display:flex;flex-flow:column wrap; row-gap:5px;">
          <div>
            <button @click="generateInvoice(invoice.id)"><font-awesome-icon icon="cogs" />{{ $t('ui.invoice.details.pdf.generate.name') }}</button>
          </div>
          <div>
            <a v-if="invoice.file" :href="$ContentManagerUrl+invoice.file.url" target="_blank"><button><font-awesome-icon icon="file-pdf" />{{ $t('ui.invoice.details.pdf.see') }}</button></a>
            <button v-if="invoice.file" @click="sendByEmailInvoice(invoice.id)"><font-awesome-icon icon="envelope" />{{ $t('ui.invoice.details.pdf.send_by_email') }}</button>
          </div>
        </div>
        <JbUAlert v-if="generateStatus.error" type="error">{{ $t('ui.invoice.details.pdf.generate.error') }} : {{ generateStatus.message }}</JbUAlert>
        <JbUAlert v-if="generateStatus.success" type="success">{{ $t('ui.invoice.details.pdf.generate.success') }}</JbUAlert>
      </JbUPanel>

      <JbUPanel type="card" :title="$t('ui.invoice.details.products')">
        <h2>{{ invoice.total }}  {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>

        <div class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.product.fields.name') }}</div>
            <div>{{ $t('ui.product.fields.quantity') }}</div>
            <div>{{ $t('ui.product.fields.price') }}</div>
          </div>
          <div v-for="listEntry of invoice.product_list" :key="listEntry">
            <div>{{ listEntry.product.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.product.price }} {{ listEntry.product.currency.symbol }}</div>
          </div>
          <div v-for="listEntry of invoice.custom_product_list" :key="listEntry">
            <div>{{ listEntry.name }}</div>
            <div>{{ listEntry.quantity }}</div>
            <div>{{ listEntry.price }} {{ listEntry.currency.symbol }}</div>
          </div>
        </div>
      </JbUPanel>
      <JbUPanel v-if="invoice.payments" type="card" :title="$t('ui.invoice.details.payments')" id="payments">
        <h2>{{ totalPayments }} {{ invoice.currency ? invoice.currency.symbol : "??" }}</h2>
        <div class="generic-table">
          <div class="headers">
            <div>{{ $t('ui.payment.fields.amount') }}</div>
            <div>{{ $t('ui.common.date') }}</div>
          </div>
          <div v-for="payment of invoice.payments" :key="payment">
            <div>{{ payment.amount }} {{ payment.currency.symbol }}</div>
            <div>{{ $d(new Date(payment.created_at), 'short') }}</div>
          </div>
        </div>
        <h2>{{ $t('ui.invoice.details.rest_due') }} : {{ leftToPay }} {{ invoice.currency ? invoice.currency.symbol : "??" }} {{ leftToPay === 0 ? '✔️' : '❌' }}</h2>
      </JbUPanel>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import { helpers, paymentHelpers } from '@/modules/helpers'
import generateInvoiceMutation from '@/graphql/invoice/generate.gql'
import sendByEmailInvoiceMutation from '@/graphql/invoice/sendByEmail.gql'
import QRCodeVue3 from "qrcode-vue3"

export default defineComponent({
  name: 'Invoice',
  props: {
    invoice: Object,
    theme: {
      type: String,
      default: undefined
    },
  },
  components: {
    QRCodeVue3
  },
  setup(props) {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    const QRCodeLink = "http://192.168.1.11:8081/administration/invoices/"+props.invoice?.id
    
    const { locale } = useI18n({ useScope: 'global' })

    // generate Invoice PDF
    const { mutate: _generateInvoice } = useMutation(generateInvoiceMutation)

    const generateStatus = reactive({
      error: false,
      success: false,
      message: ""
    })
    const generateInvoice = async (id: number) => {
      const queryInput = {
        id: id,
        locale: locale.value
      }
      try {
        await _generateInvoice(queryInput)

        generateStatus.success = true
        generateStatus.error = false
      } catch(e) {
        generateStatus.success = false
        generateStatus.error = true
        generateStatus.message = e.message
      }
    }

    // send Invoice PDF by Email
    const { mutate: _sendByEmailInvoice } = useMutation(sendByEmailInvoiceMutation)

    const sendByEmailInvoice = (id: number) => {
      const queryInput = {
        id: id
      }
      _sendByEmailInvoice(queryInput)
    }


    // Payments
    const totalPayments = ref(0)
    if(props.invoice && props.invoice.payments && props.invoice.payments.length > 0) {
      totalPayments.value = paymentHelpers.getTotalPayments(props.invoice.payments)
    }

    const leftToPay = ref(0)
    if(props.invoice) {
      leftToPay.value = props.invoice.total - totalPayments.value
    }

    

    return {
      QRCodeLink,
      generateInvoice,
      generateStatus,

      sendByEmailInvoice,
      helpers,

      totalPayments,
      leftToPay
    }

  }
})
</script>

<style scoped lang="scss">
#payments {
  .generic-table > div > div {
    padding:var(--space-1);
  }
}
</style>