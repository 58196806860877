import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from './views/Home.vue'
import Signup from './views/Signup.vue'
import Signin from './views/Signin.vue'
import Manager from './views/Manager.vue'
  import MHome from './views/Manager/Home.vue'
  import MMemberships from './views/Manager/Memberships.vue'
  import MUsers from './views/Manager/Users.vue'
  import MUser from './views/Manager/User.vue'
  import MInvoices from './views/Manager/Invoices.vue'
  import MInvoice from './views/Manager/Invoice.vue'
  import MPayments from './views/Manager/Payments.vue'
  import MAppointments from './views/Manager/Appointments.vue'
  import MAppointment from './views/Manager/Appointment.vue'
  import MSettings from './views/Manager/Settings.vue'
    import MProducts from './views/Manager/Products.vue'


//import Patients from '../views/Patients.vue'
import Patient from './views/Patient.vue'
  import PHome from './views/Patient/Home.vue'
  import PUserUpdate from './views/Patient/UserUpdate.vue'
  import PInvoices from './views/Patient/Invoices.vue'
  import PInvoice from './views/Patient/Invoice.vue'
  //import PMembership from './views/Patient/Membership.vue'
  //import PPayments from './views/Patient/Payments.vue'




const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    component: Home
    //redirect: 'Signin' // Redirect for quick access for now
  },
  {
    path: '/logout',
    name: 'Logout',
    redirect: '/'
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/signin', // This page contains the Login Form
    name: 'Signin',
    component: Signin
  },
  { 
    path: '/administration',
    component: Manager,
    meta: { authorize: ["Manager"] },
    children: [
      {
        path: 'home',
        name: 'ManagerHome',
        component: MHome
      },
      {
        path: 'patients',
        component: MUsers
      },

      {
        path: 'patients/:id',
        component: MUser
      },
      {
        path: 'invoices',
        component: MInvoices
      },
      {
        path: 'invoices/:id',
        component: MInvoice
      },
      {
        path: 'payments',
        component: MPayments
      },
      {
        path: 'products',
        component: MProducts
      },
      {
        path: 'memberships',
        component: MMemberships
      },
      {
        path: 'appointments',
        component: MAppointments
      },
      {
        path: 'appointments/:id',
        component: MAppointment
      },
      {
        path: 'memberships',
        component: MMemberships
      },
      {
        path: 'settings',
        component: MSettings
      }
    ]
  },
  { 
    path: '/patient',
    name: 'Patient',
    component: Patient,
    meta: { authorize: ["Patient"] },
    children: [
      {
        path: 'home',
        name: 'PatientHome',
        component: PHome
      },{
        path: 'update-personal-infos',
        component: PUserUpdate
      },
      {
        path: 'invoices',
        component: PInvoices
      },
      {
        path: 'invoices/:id',
        component: PInvoice
      },
      
      /*{
        path: 'membership',
        component: PMembership
      },
      {
        path: 'payments',
        component: PPayments
      },*/
    ]
  },

  // otherwise redirect to Root
  {
    path: "/:catchAll(.*)",
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Is the route protected
  if(to.meta.authorize) {
    // So is the user connected ?
    if(sessionStorage.getItem("role")) {
      // And is the user authorized to be there ?
      if(to.meta.authorize.includes(sessionStorage.getItem("role"))) {
        // Alright you may enter
        next();
      } else {
        // Redirect this impostor 
        next({ name: 'Root' });
      }
    } else {
      // Redirect this impostor 
      next({ name: 'Signin' });
    }
    
    //if(sessionStorage.getItem("role"))
      //console.log("I am a "+sessionStorage.getItem("role"))

  } else {
    if(to.redirectedFrom) {

      // If user wants to log out
      if(to.redirectedFrom.name == "Logout")
        sessionStorage.clear()
    }
    next();
  }
  
})

export default router