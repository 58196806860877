
import { defineComponent, inject, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useQuery, useResult } from '@vue/apollo-composable'
import UserCreateModal from '@/components/modals/UserCreate.vue'
import InvoiceCreateModal from '@/components/modals/InvoiceCreate.vue'
import PaymentCreateModal from '@/components/modals/PaymentCreate.vue'
import ProductCreateModal from '@/components/modals/ProductCreate.vue'
import getLocationsQuery from '@/graphql/location/gets.gql'
import LocaleSwitch from '../components/LocaleSwitch.vue'

export default defineComponent({
  name: 'BackendAdmin',
  components: {
    UserCreateModal,
    InvoiceCreateModal,
    PaymentCreateModal,
    ProductCreateModal,
    LocaleSwitch
  },
  setup(){
    // get emitter
    const emitter = inject('$emitter') as Emitter
    const contentManagerUrl = inject('$ContentManagerUrl') as string

    const { result: getLocationsResult } = useQuery(getLocationsQuery)
    const locations = useResult(getLocationsResult, null, data => data.locations)

    const firstName = ref(sessionStorage.getItem('first_name'))
    const lastName = ref(sessionStorage.getItem('last_name'))
    const role = ref(sessionStorage.getItem('role'))

    const profile_picture = computed(() => {
      if (sessionStorage.getItem('profile_picture') === null)
        return null
      else
        return contentManagerUrl+sessionStorage.getItem('profile_picture')
    })

    return {
      locations,
      emitter,

      firstName,
      lastName,
      role,
      profile_picture
    }
  }
})
