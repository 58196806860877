<template>
  <template v-if="user">
    <JbUAlert type="warning" style="margin-bottom:var(--space-7);">
      <p>User</p><br/>
      <p>The view of a user where you can see all its informations and linked objects like membership, appointments...</p><br/>
      <p>A QR-code directing to this page is generated for each client to have them show their screen to the therapist and gain time by not seaching for him in the database.</p>
    </JbUAlert>
    <div class="header">
      <h1>{{ userHelpers.getFullName(user) }}</h1> <div><button class="highlighted" @click="setupEditModal(user.id)">{{ $t('ui.user.update.name') }}</button></div>
    </div>
    <ElementDisplay :user="user" theme="full"/>
    <ElementUpdateModal :elementId="updateId"/>
  </template>
  <template v-else>
    {{ $t('ui.common.loading.name') }}
  </template>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useRoute } from 'vue-router'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { userHelpers } from '@/modules/helpers'
import getElementQuery from '@/graphql/user/get.gql'
import createMembershipMutation from '@/graphql/membership/create.gql'

import ElementUpdateModal from '@/components/modals/UserUpdate.vue'
import ElementDisplay from '@/components/element_displays/User.vue'

export default defineComponent({
  name: 'Invoice',
  components: {
    ElementUpdateModal,
    ElementDisplay
  },
  setup() {
    // Get global components
    const $emitter = inject('$emitter') as Emitter
    const route = useRoute()

    ////////// GET ELEMENT DATA WITH ITS ID
    const getElementParams = reactive({id: route.params.id})
    const { result: getElement, loading: editingElementLoading, onResult: editingElement_onResult } = useQuery(getElementQuery, getElementParams)
    const element = useResult(getElement, null, data => data.user)

    // Create a membership
    const { mutate: createMembership, onDone: createMembershipDone, onError: createMembershipError } = useMutation(createMembershipMutation)
    const createMembershipStatus = reactive({
      success: false,
      error: false
    })
    createMembershipDone(() => {
      createMembershipStatus.success = true
      createMembershipStatus.error = false
    })
    createMembershipError(() => {
      createMembershipStatus.error = true
      createMembershipStatus.success = false
    })

    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      console.log(id)
      updateId.value = id
      $emitter.emit("open-modal-user-update")
    }


    return {
      user: element,
      createMembership,

      updateId,
      setupEditModal,
      userHelpers
    }
  }
})
</script>

<style scoped lang="scss">
</style>