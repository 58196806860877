export default {

  getFullName(user: { [key: string]: any }): string {
    if(user !== null) {
      const userName: any[] = []
      if (user.first_name) { userName.push(user.first_name) }
      if (user.last_name) { userName.push(user.last_name) }
      return userName.join(' ')
    } else {
      return "User is 'null'"
    }
  },

  relationshipsToStrapi(relationshipsArray: any[]): any[] {
    const convertedList: any[] = [] as any[]
    for (const relationship of relationshipsArray) {
      convertedList.push({type: relationship.type, user: relationship.user, deducedType: relationship.deducedRelationship})
    }

    return convertedList
  },
  
}