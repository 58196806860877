// General notes
// Switching from dev to prod requires a new build of strapi via "NODE_ENV=production yarn build" then "NODE_ENV=production yarn start"

// Generated
import { createApp, h, provide, ref } from 'vue'
import App from './App.vue'
import './registerServiceWorker'

import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { setContext } from '@apollo/client/link/context'
import { createI18n } from 'vue-i18n'
import VueGtag from 'vue-gtag'
import VCalendar from 'v-calendar'
import store from './store'
import router from './router'

// Hey hey hey it's me !
import JbLib from 'jb-lib'
import 'jb-lib/dist/jb-lib.esm.css'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowLeft, faArrowRight, faCalendarCheck, faBars, faPhone, faAddressCard, faSignOutAlt, faEnvelope, faFont, faPeopleArrows, faKey, faUser, faPen, faFileInvoiceDollar, faBookMedical, faFilePdf, faEye, faEyeSlash, faTimes, faTimesCircle, faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faPlus, faMinus, faCheckCircle, faExclamationCircle, faCaretDown, faCaretUp, faCaretLeft, faCaretRight, faCog, faCogs, faDollarSign, faQrcode } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft, faArrowRight, faCalendarCheck, faBars, faPhone, faAddressCard, faSignOutAlt, faEnvelope, faFont, faPeopleArrows, faKey, faUser, faPen, faFileInvoiceDollar, faBookMedical, faFilePdf, faEye, faEyeSlash, faTimes, faTimesCircle, faAngleUp, faAngleDown, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faPlus, faMinus, faCheckCircle, faExclamationCircle, faCaretDown, faCaretUp, faCaretLeft, faCaretRight, faCog, faCogs, faDollarSign, faQrcode )

// I18n messages
import { en } from './modules/translations/translations.en'
import { fr } from './modules/translations/translations.fr'
import { de } from './modules/translations/translations.de'
import { datetimeFormats } from './modules/datetimeFormats'

const messages = {fr, en, de}
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: store.getters.locale,
  fallbackLocale: ['en'],
  messages,
  datetimeFormats
})


// Event emitter
import mitt from 'mitt';
const emitter = mitt();

let contentManagerUrl = "http://192.168.1.11:6083"
if (process.env.VUE_APP_MODE == "live")
  contentManagerUrl = "https://nbc.joris-broll.com/api"
else if(process.env.VUE_APP_MODE == "test")
  contentManagerUrl = "https://nbc.joris-broll.com/api"


// Activate global properties
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $ContentManagerUrl: string;
    $AppTesting: boolean;
  }
}

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: contentManagerUrl+'/graphql',
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('jwt');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const defaultClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

const vueApp = createApp({
  data() {
    return {
      sharedState: store.state
    }
  },
  setup () {
    provide(DefaultApolloClient, defaultClient)
  },
  render() {
    return h(App)
  }
})

vueApp.config.globalProperties.$ContentManagerUrl = contentManagerUrl

if(process.env.NODE_ENV == "development" || process.env.VUE_APP_MODE == "test")
  vueApp.config.globalProperties.$AppTesting = true
else 
  vueApp.config.globalProperties.$AppTesting = false

vueApp.use(router).use(store).use(i18n).use(JbLib).use(VCalendar, {}).use(VueGtag, { config: { id: "G-K2FWMW76YY" } }, router)
vueApp.provide('$emitter', emitter)
vueApp.provide('$router', router)
vueApp.provide('$ContentManagerUrl', contentManagerUrl)
vueApp.component("font-awesome-icon", FontAwesomeIcon)
vueApp.mount('#app')
