<template>
  <JbUModal name="user-create" cancellable>
    <template v-slot:headerLeft>
      <div v-if="$AppTesting" class="test-commands"><button @click.stop="fillForm">Fill Form</button></div>
      {{ $t('ui.user.create.name') }}
    </template>
    <template v-slot:default>
      <UserForm ref="userFormRef" display="create" type="admin"/>
    </template>
    <template v-slot:footerLeft>
      <JbUAlert v-if="createStatus.error" type="error">{{ $t('ui.user.create.error') }}</JbUAlert>
    </template>
    <template v-slot:footerRight>
      <button class="highlighted" @click="submit_modal">{{ $t('ui.user.create.name') }}</button>
    </template>
  </JbUModal>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import createElementMutation from '@/graphql/user/create.gql'
import getUsersQuery from '@/graphql/user/gets.gql'

import UserForm from '@/components/forms/User.form.vue'

export default defineComponent({
  name: 'UserCreate',
  components: {
    UserForm
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter
    
    const { t, locale } = useI18n({ useScope: 'global' })

    const userFormRef = ref<null | any>(null)

    ////////// CREATE QUERY
    const { mutate: createElement, onDone: createElementDone, onError: createElementError } = useMutation(createElementMutation)
    const createStatus = reactive({
      success: false,
      error: false
    })
    createElementDone(() => {
      createStatus.success = true
      createStatus.error = false
    })
    createElementError(() => {
      createStatus.error = true
      createStatus.success = false
    })

    createElementDone((QR) => {
      $emitter.emit("create-toast", {
        props: {
          title: t('ui.user.create.confirm'),
          text: QR.data.createUser.user.last_name+" "+QR.data.createUser.user.first_name,
          icon: "check-circle",
        }
      })
      $emitter.emit("close-modal-user-create")
    })
    createElementError(() => {
      console.log('Create user Error')
    })


    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const form = userFormRef.value.form

      const relationship_list = userHelpers.relationshipsToStrapi(form.relationships)

      const queryInput = { 
        input: { 
          data: {
            username: new Date,
            first_name: form.first_name.model,
            second_name: form.second_name.model,
            last_name: form.last_name.model,
            birth_name: form.birth_name.model,
            job: form.job.model,
            phone_private: form.phone_private.model,
            cellphone: form.cellphone.model,
            email: form.email.model,
            password: form.password.model,
            problems: form.problems.model,
            medication: form.medication.model,
            hear_about_us: form.hear_about_us.model,
            recommendation: form.recommendation.model,
            selected_locale: locale.value,
            address: [{
              street: form.street.model,
              street_extra: form.street_extra.model,
              postal_code: form.postal_code.model,
              city: form.city.model,
              region:form.region.model,
              country: form.country.model
            }],

            // Without validation
            gender: form.gender.model,
            birth_date: form.birth_year.model+'-'+form.birth_month.model+'-'+form.birth_day.model,
            electronic_invoices: form.electronic_invoices.model,

            relationships: relationship_list
          }
        }
      }

      createElement(queryInput, {
        update: (cache, { data: { createUser: createElement } }) => { // Needs to have the graphQL query name
          const data: any = cache.readQuery({ query: getUsersQuery, variables: {start: 0, limit:100} })
          
          if(data) { // If we are on a page where getUsersQuery has been used
            cache.writeQuery({ query: getUsersQuery, variables: {start: 0, limit:100}, data: {
              users: [createElement.user, ...data.users]
            }})
          }
        }
      })
    }

    // Testing purposes
    const fillForm = () => {
      userFormRef.value.fillForm()
    }

    return {
      userFormRef,
      createElement,
      createStatus,

      submit_modal,
      fillForm
    }
  }
});
</script>