// disable linter camelCase BS
/*eslint-disable */
export const en = {
  language: {
    en: {
      name: "English"
    },
    fr: {
      name: "French"
    },
    de: {
      name: "German"
    }
  },
  country: {
    germany: "Germany",
    de: "Germany",
    france: "France",
    fr: "France",
    switzerland: "Switzerland",
    ch: "Switzerland",
    austria: "Austria",
    at: "Austria",
    spain: "Spain",
    sp: "Spain",
    italy: "Italy",
    it: "Italy",
    great_britain: "Great Britain",
    gb: "Great Britain",
    united_states: "United States",
    us: "United States",
  },
  ui: {
    common: {
      yes: "Yes",
      no: "No",
      date: "Date",
      see_all: "See all informations",
      last_30_days: "+{0} in the last 30 days",
      loading: {
        name: "Loading..."
      },
      error: {
        name: "Error"
      },
      refresh: {
        name: "Refresh"
      },
      connect: {
        self: "Log in"
      },
      disconnect: {
        self: "Log out"
      },
      fields: {
        write_here: "Write here..."
      }
    },
    login: {
      panelTitle: "new body concept",
      confirm: "Your account is now activated. You can log in and start using our app!"
    },
    role: {
      Owner: "Owner",
      Manager: "Manager",
      Patient: "Patient"
    },
    home: {
      name: "Homepage",
      new_user: {
        create: "Create a new account",
        create_mine: "Create my account",
        create_form: "Please fill out this form to create a new account"
      }
    },
    page: {
      settings: {
        name: "Settings",
        products: "Edit the billable products."
      }
    },
    list: {
      actions: {
        name: "Actions"
      }
    },
    element: {
      create: {
        name: "New element",
      },
      update: {
        name: "Edit an element",
      },
      delete: {
        name: "Delete an element",
        confirm: "Delete this element ?"
      }
    },
    address: {
      name: "Address",
      fields: {
        id: "Number",
        street: "Street",
        street_extra: "Extra",
        city: "City",
        postal_code: "Postal code",
        region: "Region",
        country: "Country"
      }
    },
    contact_method: {
      phone_number: "Phone number",
      mobile_phone_number: "Cellphone number",
      email: "Email",
      skype: "Skype",
      fax: "Fax",
      website: "Website"
    },
    appointment: {
      name: "Appointment | Appointments",
      self: {
        name: "My appointments",
        to_come: "{0} upcoming appointments",
        see_all: "See my appointments"
      },
      create: {
        name: "New appointment",
        confirm: "The new appointment has been created.",
        error: "The new appointment could not be created."
      },
      update: {
        name: "Edit appointment",
        confirm: "The appointment has been updated.",
        error: "The appointment could not be modified."
      },
      delete: {
        name: "Delete appointment",
        confirm: "The appointment has been deleted.",
        error: "The appointment could not be deleted."
      },
      search: {
        name: "Search appointments"
      },
      fields: {
        date: "Date",
        patient: "Patient",
        start: "Start time",
        end: "End time"
      },
      patient: {
        name: "My appointments",
        create: "Make an appointment"
      }
    },
    invoice: {
      name: "Invoice | Invoices",
      self: {
        name: "My invoices",
        to_come: "{0} invoices",
        see_all: "See my invoices"
      },
      create: {
        name: "New invoice",
        confirm: "The new invoice has been created.",
        error: "The new invoice could not be created."
      },
      update: {
        name: "Edit invoice",
        confirm: "The invoice has been updated.",
        error: "The invoice could not be modified."
      },
      delete: {
        name: "Delete invoice",
        confirm: "The invoice has been deleted successfully.",
        error: "The invoice could not be deleted."
      },
      search: {
        name: "Search invoices"
      },
      fields: {
        number: "Number",
        number_month: "Month",
        number_in_month: "Number",
        description: "Description",
        description_auto: "Appointment on the ",
        date: "Invoice date",
        due_date: "Invoice due date",
        products: "Billed products",
        use_membership_account: "Use membership account balance"
      },
      label_groups: {
        infos: "Informations",
      },
      details: {
        products: "Billed products",
        date: "Invoice date",
        due_date: "Invoice due date",
        payments: "Payments made",
        total: "Total",
        total_paid: "Total amount paid",
        rest_due: "Left to pay",
        is_paid: "This invoice is paid.",
        pdf: {
          file: "PDF File",
          see: "View PDF file",
          generate: {
            name: "Generate PDF file",
            error: "Unable to generate PDF",
            success: "PDF generated"
          },
          send_by_email: "Send PDF by Email",
        }
      },
      patient: {
        name: "My invoices"
      }
    },
    membership: {
      name: "Membership | Memberships",
      create: {
        name: "New membership",
        confirm: "The new membership has been created.",
        error: "The new membership could not be created."
      },
      update: {
        name: "Edit membership",
        confirm: "The membership has been updated successfully.",
        error: "The membership could not be updated."
      },
      delete: {
        name: "Delete membership",
        confirm: "The membership has been deleted.",
        error: "The membership could not be deleted."
      },
      product: {
        name: "Product billing"
      },
      fields: {
        id: "Number",
        balance: "Balance",
        type: "Type",
        discount_prc: "Discount percentage"
      },
      generate: {
        name: "Generate invoice",
        all: "Generate all invoices"
      },
      options: {
        name: "Invoicing options"
      },
      more: {
        past_invoices: "Last {0} invoices",
        no_invoices: "No invoices",
        past_appointments: "Last {0} months appointments",
        no_appointments: "No appointments",
        use_balance: "Use the balance of the member account to pay",
        new_balance: "New balance",
        subscribe: "Become a member"
      }
    },
    payment: {
      name: "Payment | Payments",
      self: {
        name: "My paymentsn",
        to_come: "{0} payments",
        see_all: "See my payments"
      },
      create: {
        name: "New payment",
        confirm: "The new payment has been created.",
        error: "The new payment could not be created."
      },
      update: {
        name: "Edit payment",
        confirm: "The payment has been modified successfully.",
        error: "The payment could not be modified."
      },
      delete: {
        name: "Delete payment",
        confirm: "The payment has been successfully deleted.",
        error: "The payment could not be deleted."
      },
      search: {
        name: "Search payments"
      },
      fields: {
        id: "Number",
        amount: "Amount",
        invoice: "Invoice number",
        currency: "Currency",
        use_invoice_user: "Use the user associated with the invoice"
      },
      more: {
        invoice_details: "Invoice details",
        make_a_payment: "Make an online payment via SumUp",
        payment_success: "The payment has been made successfully!",
        can_close: "You can now close this window.",
        use_form: "Last {0} invoices",
      }
    },
    payment_type: {
      name: "Payment type",
      account_balance: "Membership account balance",
      card: "Bank card",
      cash: "Cash",
      bank_transfer: "Bank transfer"
    },
    product: {
      name: "Product | Products",
      create: {
        name: "New product",
        confirm: "The new product has been created.",
        error: "The new product could not be created."
      },
      update: {
        name: "Edit product",
        confirm: "The product has been modified successfully.",
        error: "The product could not be modified."
      },
      delete: {
        name: "Delete product",
        confirm: "The product has been successfully deleted.",
        error: "The product could not be deleted."
      },
      fields: {
        name: "Name",
        category: "Category",
        price: "Price",
        quantity: "Quantity",
        currency: "Currency"
      },
      more: {
        new_custom: "New manual product"
      }
    },
    product_type: {
      name: "Product type",
      card: "Membership card",
      consultation: "Consultation",
    },
    relationship: {
      name: "Relationship | Relationships",
      create: {
        name: "New relationship"
      },
      type: {
        father: "Father",
        grandfather: "Grandfather",
        mother: "Mother",
        grandmother: "Grandmother",
        brother: "Brother",
        sister: "Sister",
        son: "Son",
        daughter: "Daughter",
        grandson: "Grandson",
        granddaughter: "Granddaughter",
        cousin: "Cousin",
        cousine: "Cousin",
        family: "Family",
        friend: "Friend"
      }
    },
    user: {
      name: "User | Users",
      create: {
        name: "New user",
        confirm: "The new user has been created.",
        error: "The new user could not be created.",
        self: {
          confirm: "Your new account has been created. You will now receive a confirmation email to the address you used to register.",
          error: "Your account could not be created. The email address is already in use.",
          form_invalid: "The form data is missing or incomplete. Please try again following the instructions."
        }
      },
      update: {
        name: "Edit user",
        confirm: "The user has been successfully edited.",
        error: "The user could not be edited.",
        self: {
          button: "Edit my informations",
          confirm: "Your account has been updated.",
          error: "Your account could not be updated."
        }
      },
      delete: {
        name: "Delete user",
        confirm: "The user has been successfully deleted.",
        error: "The user could not be deleted."
      },
      search: {
        name: "Search users"
      },
      fields: {
        last_name: "Last name",
        second_name: "Middle name(s)",
        first_name: "First name",
        birth_name: "Birth name",
        job: "Job",
        phone_private: "Private phone number",
        cellphone: "Cellphone number",
        email: "Email address",
        gender: {
          name: "Gender",
          male: "Male",
          female: "Female",
          other: "Other"
        },
        username: "User name",
        password: "Password",
        birthday: "Date of birth",
        electronic_invoices: "I would like to receive paper invoices in addition to electronic invoices.",//electronic_invoices: "I would like to receive paper invoices.",
        profile_picture: 'Profile picture',
        password_confirm: "Confirmation",
        password_confirm_ph: "Enter your password again",
        language: "Selected language",
        problems: "Complaints",
        problems_ph: "Describe the symptoms you would like to treat.",
        medication: "Do you take medications on a regular basis?",
        hear_about_us: "How did you hear about us?",
        recommendation: "I have the recommendation of",
        marketing: "I agree to receive commercial communications from “new body concept”.",
        data_optin: "By submitting this form, I agree that my personal information will be used exclusively within the “new body concept” application."
      },
      more: {
        all_person_infos: {
          title: "Personal informations",
          is_therapist: "Therapist?"
        },
        name: "Name",
        full_name: "Full name",
      },
      label_groups: {
        logins: "Login informations",
        personal_infos: "Personal informations",
        address: "Address",
        medical_questions: "Medical informations",
      },
      list: "List of users",
      imported_warning: {
        sentence: "Your account is not yet secure. Please edit your account to set a new personal password.",
      }
    }
  }
}