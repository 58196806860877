<template>
  <JbCForm :validation="form" ref="formRef">
    <div class="form-group-label">{{ $t('ui.invoice.label_groups.infos') }}</div>
    <div class="form-row">
      <label for="invoiceID"><span class="mid-dot c-orange">•</span>{{ $t('ui.invoice.name') }}</label>
      <div><JbCSearch name="invoice" v-model="form.invoiceId" :options="searchedInvoicesList" :placeholder="$t('ui.invoice.search.name')+'...'" :preselected="preselectedInvoice" @keyUp="searchInvoices" @listItemClick="updateSelectedInvoice"/></div>
    </div>
    <!--<div class="form-row" v-if="Object.keys(selectedInvoice).length !== 0">
      <label>{{ $t('ui.invoice.name') }} {{ $t('ui.invoice.details.total') }}</label>
      <JbUCard theme="input">
        <div class="generic-flexbox-column">
          <div>
            <div>{{ selectedInvoice.total }} {{ selectedInvoice.currency ? selectedInvoice.currency.symbol : '?$?' }}</div>
          </div>
        </div>
      </JbUCard>
    </div>-->
    <div v-if="selectedInvoice && selectedInvoice.user" class="form-row">
      <label><span class="mid-dot c-blue">•</span>{{ $t('ui.user.name') }}</label>
      <JbUCard theme="input" id="payment-invoice">
        <div class="generic-flexbox-column">
          <div>
            <div class="checkbox">
              <input type="checkbox" id="remember_me_checkbox" v-model="form.useInvoiceUser">
              <label for="remember_me_checkbox">{{ $t('ui.payment.fields.use_invoice_user') }}</label>
            </div>
          </div>
          <template v-if="!form.useInvoiceUser">
            <div><JbCSearch name="user" :options="searchedUsersList" :placeholder="$t('ui.user.search.name')+'...'" :preselected="preselectedUser" @keyUp="searchUsers" @listItemClick="updateSelectedUser"/></div>
            <template v-if="selectedUser.membership">
              <div>
                <div>{{ $t('ui.membership.fields.balance') }}</div><div>{{ selectedUser.membership.balance }} {{ selectedUser.membership.currency.symbol }}</div>
              </div>
              <div v-if="form.type == 'account_balance'">
                <div>{{ $t('ui.membership.more.new_balance') }}</div><div>{{ selectedUser.membership.balance - form.amount}} {{ selectedUser.membership.currency.symbol }}</div>
              </div>
            </template>
          </template>

          <template v-else>                
            <div>
              <div>{{ $t('ui.user.more.name') }}</div><div>{{ userHelpers.getFullName(selectedInvoice.user) }}</div>
            </div>
            <template v-if="selectedInvoice.user.membership">
            <div>
              <div>{{ $t('ui.membership.fields.balance') }}</div><div>{{ selectedInvoice.user.membership.balance }} {{ selectedInvoice.user.membership.currency.symbol}}</div>
            </div>
            <div v-if="form.type == 'account_balance'">
              <div>{{ $t('ui.membership.more.new_balance') }}</div><div>{{ selectedInvoice.user.membership.balance - form.amount}} {{ selectedInvoice.currency.symbol }}</div>
            </div>
            </template>
          </template>
        </div>
      </JbUCard>
    </div>
    <div class="form-row">
      <label :for="'amount-'+$.uid">{{ $t('ui.payment.fields.amount') }}</label>
      <div>
        <div class="flexbox" style="flex-wrap:nowrap;">
          <input style="flex: 1 1 auto;" type="number" step="0.01" v-model.number="form.amount" :id="'amount-'+$.uid" name="amount" :placeholder="$t('ui.payment.fields.amount')"><JbUButton @click="setAmountToTotal">{{ $t('ui.invoice.details.total') }}</JbUButton>
        </div>
      </div>
    </div>
    <div class="form-row">
      <label :for="'currencyID-'+$.uid">{{ $t('ui.payment.fields.currency') }}</label>
      <div>
        <select v-model="form.currencyId" :id="'currencyID-'+$.uid">
          <option v-for="currency of currencies" :key="currency" :value="currency.id">
            {{ currency.name }} ({{ currency.symbol }})
          </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label :for="'payment-type-'+$.uid">{{ $t('ui.payment_type.name') }}</label>
      <div>
        <select v-model="form.type" :id="'payment-type-'+$.uid">
          <option value="account_balance">{{ $t('ui.payment_type.account_balance') }}</option>
          <option value="cash">{{ $t('ui.payment_type.cash') }}</option>
          <option value="card">{{ $t('ui.payment_type.card') }}</option>
          <option value="bank_transfer">{{ $t('ui.payment_type.bank_transfer') }}</option>
        </select>
      </div>
    </div>

    <hr>
    
    <div class="row cards">
      <template v-if="selectedInvoice">
      <JbUPanel type="card" :title="$t('ui.invoice.name')" id="payment-invoice">
        <div class="generic-flexbox-column">
          <div>
            <div>{{ $t('ui.invoice.details.total') }}</div><div>{{ selectedInvoice.total }} {{ selectedInvoice.currency ? selectedInvoice.currency.symbol : '?$?' }}</div>
          </div>
        </div>
      </JbUPanel>
      </template>
    </div>    
  </JbCForm>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, userHelpers } from '@/modules/helpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'

import searchInvoicesQuery from '@/graphql/invoice/search.gql'
import searchUsersQuery from '@/graphql/user/search.gql'
import getCurrenciesQuery from '@/graphql/currency/gets.gql'

export default defineComponent({
  name: 'PaymentForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const { t, locale } = useI18n({ useScope: 'global' })

    const formRef = ref<null | HTMLFormElement>(null)

    const selectedInvoice = ref<{[key: string]: any}>({})
    const preselectedInvoice = ref({ id:0, name:'' })
    const selectedUser = ref<{[key: string]: any}>({})
    const preselectedUser = ref({ id:0, name:'' })
    
    const form: { [Key: string]: any } = reactive({
      id: '',
      amount: 0,
      type: 'cash',
      invoiceId: "0",
      currencyId: 1,

      useInvoiceUser: true
    })

    // Fields
    ////////// Field : Invoice - Search
    const { searchFunction: searchInvoices, ElementsList: searchedInvoices } = searchHelper('invoices', searchInvoicesQuery)

    const searchedInvoicesList = computed(() => {
      return toSearchList(searchedInvoices, 'invoice')
    })

    const updateSelectedInvoice = (item: {[key: string]: any}) => {
      selectedInvoice.value = helpers.getByIdInArray(item.id, searchedInvoices.value as any[])
      form.invoiceId = (selectedInvoice.value as any).id
    }

    ////////// Field : User - Search
    const { searchFunction: searchUsers, ElementsList: searchedUsers } = searchHelper('users', searchUsersQuery)

    const searchedUsersList = computed(() => {
      return toSearchList(searchedUsers, 'user')
    })

    const updateSelectedUser = (item: {[key: string]: any}) => {
      selectedUser.value = helpers.getByIdInArray(item.id, searchedUsers.value as any[])
      form.userId = (selectedUser.value as any).id
    }

    ////////// Field : Currency - Gets
    const { result: getCurrenciesResult } = useQuery(getCurrenciesQuery)
    const currencies = useResult(getCurrenciesResult, null, data => data.currencies)

    const setAmountToTotal = () => {
      if(Object.keys(selectedInvoice.value).length !== 0) {
        form.amount = selectedInvoice.value.total
      }
    }


    const validate = () => {
      if(formRef.value != null)
        return formRef.value.validateForm(form)
    }

    // Testing purposes
    const fillForm = () => {
      console.log("Signup 'Fill Form' button clicked.")
      // With validation
      form.first_name.model= 'a_test_first_name'
      form.second_name.model = 'test_second_name'
      form.last_name.model = 'a_test_last_name'
    }

    return {
      form,
      formRef,

      currencies,

      searchInvoices,
      searchedInvoicesList,
      updateSelectedInvoice,
      selectedInvoice,
      preselectedInvoice,

      searchUsers,
      searchedUsersList,
      updateSelectedUser,
      selectedUser,
      preselectedUser,

      setAmountToTotal,

      fillForm,
      validate,
      helpers, userHelpers
    }
  }
});
</script>


<style scoped lang="scss">

</style>