// disable linter camelCase BS
/*eslint-disable */
export const de = {
  language: {
    en: {
      name: "Englisch"
    },
    fr: {
      name: "Französisch"
    },
    de: {
      name: "Deutsch"
    }
  },
  country: {
    germany: "Deutschland",
    de: "Deutschland",
    france: "Frankreich",
    fr: "Frankreich",
    switzerland: "Schweiz",
    ch: "Schweiz",
    austria: "Österreich",
    at: "Österreich",
    spain: "Spanien",
    sp: "Spanien",
    italy: "Italien",
    it: "Italien",
    great_britain: "Grossbritannien",
    gb: "Grossbritannien",
    united_states: "Vereinigte Staaten",
    us: "Vereinigte Staaten",
  },
  ui: {
    common: {
      yes: "Ja",
      no: "Nein",
      date: "Datum",
      see_all: "Alle Informationen ansehen",
      last_30_days: "+{0} in den letzten 30 Tagen",
      loading: {
        name: "Wird geladen..."
      },
      error: {
        name: "Fehler"
      },
      refresh: {
        name: "Aktualisierung"
      },
      connect: {
        self: "Einloggen"
      },
      disconnect: {
        self: "Ausloggen"
      },
      fields: {
        write_here: "Hier schreiben ..."
      }
    },
    login: {
      panelTitle: "new body concept",
      confirm: "Ihr Konto ist jetzt aktiviert. Sie können sich einloggen und unsere App nutzen!"
    },
    role: {
      Owner: "Meister",
      Manager: "Manager",
      Patient: "Patient"
    },
    home: {
      name: "Startseite",
      new_user: {
        create: "Ein neues Konto erstellen",
        create_mine: "Erstelle meinen Account",
        create_form: "Bitte füllen Sie dieses Formular aus, um ein neues Konto zu erstellen"
      }
    },
    page: {
      settings: {
        name: "Einstellungen",
        products: "Abrechenbare Produkte bearbeiten."
      }
    },
    list: {
      actions: {
        name: "Aktionen"
      }
    },
    element: {
      create: {
        name: "Neuer Bestandteil",
      },
      update: {
        name: "Bestandteil bearbeiten",
      },
      delete: {
        name: "Bestandteil löschen",
        confirm: "Dieses Element löschen?"
      }
    },
    address: {
      name: "Adresse",
      fields: {
        id: "Number",
        street: "Strasse",
        street_extra: "Strasse (Zusatz)",
        city: "Stadt",
        postal_code: "Postleitzahl",
        region: "Region / Kanton",
        country: "Land"
      }
    },
    contact_method: {
      phone_number: "Telefonnummer",
      mobile_phone_number: "Handynummer",
      email: "Email",
      skype: "Skype",
      fax: "Fax",
      website: "Webseite"
    },
    appointment: {
      name: "Termin | Termine",
      self: {
        name: "Meine Termine",
        to_come: "{0} bevorstehende Termine",
        see_all: "Siehe meine Termine"
      },
      create: {
        name: "Neuer Termin",
        confirm: "Der neue Termin ist angelegt.",
        error: "Der neue Termin konnte nicht erstellt werden."
      },
      update: {
        name: "Termin bearbeiten",
        confirm: "Der Termin wurde aktualisiert.",
        error: "Der Termin konnte nicht geändert werden."
      },
      delete: {
        name: "Termin löschen",
        confirm: "Der Termin wurde gelöscht.",
        error: "Der Termin konnte nicht gelöscht werden."
      },
      search: {
        name: "Termin suchen"
      },
      fields: {
        date: "Datum",
        patient: "Patient",
        start: "Startzeit",
        end: "Endzeit"
      },
      patient: {
        name: "Meine Termine",
        create: "Termin machen"
      }
    },
    invoice: {
      name: "Rechnung | Rechnungen",
      self: {
        name: "Meine Rechnungen",
        to_come: "{0} Rechnungen",
        see_all: "Siehe meine Rechnungen"
      },
      create: {
        name: "Neue Rechnung",
        confirm: "Die neue Rechnung wurde erstellt.",
        error: "Die neue Rechnung konnte nicht erstellt werden."
      },
      update: {
        name: "Rechnung bearbeiten",
        confirm: "Die Rechnung wurde geändert.",
        error: "Die Rechnung konnte nicht geändert werden."
      },
      delete: {
        name: "Rechnung löschen",
        confirm: "Die Rechnung wurde gelöscht.",
        error: "Die Rechnung konnte nicht gelöscht werden."
      },
      search: {
        name: "Rechnung suchen"
      },
      fields: {
        number: "Nummer",
        number_month: "Monat",
        number_in_month: "Nummer",
        description: "Beschreibung",
        description_auto: "Termin von ",
        date: "Rechnungsdatum",
        due_date: "Rechnungsfälligkeitsdatum",
        products: "Abgerechnete Produkte",
        use_membership_account: "Kontostand des Mitgliedskontos verwenden"
      },
      label_groups: {
        infos: "Informationen",
      },
      details: {
        products: "Abgerechnete Produkte",
        date: "Rechnungsdatum",
        due_date: "Rechnungsfälligkeitsdatum",
        payments: "Geleistete Zahlungen",
        total: "Gesamt",
        total_paid: "Gezahlte Gesamtbeträge",
        rest_due: "Noch zu zahlen",
        is_paid: "Diese Rechnung ist bezahlt.",
        pdf: {
          file: "PDF Datei",
          see: "PDF-Datei anzeigen",
          generate: {
            name: "PDF-Datei erstellen",
            error: "PDF kann nicht generiert werden",
            success: "PDF generiert"
          },
          send_by_email: "PDF per E-Mail senden",
        }
      },
      patient: {
        name: "Meine Rechnungen"
      }
    },
    membership: {
      name: "Mitgliedschaft | Mitgliedschaften",
      create: {
        name: "Neue Mitgliedschaft",
        confirm: "Die neue Mitgliedschaft wurde erstellt.",
        error: "Die neue Mitgliedschaft konnte nicht erstellt werden."
      },
      update: {
        name: "Mitgliedschaft bearbeiten",
        confirm: "Die Mitgliedschaft wurde erfolgreich aktualisiert.",
        error: "Die Mitgliedschaft konnte nicht aktualisiert werden."
      },
      delete: {
        name: "Mitgliedschaft löschen",
        confirm: "Die Mitgliedschaft wurde gelöscht.",
        error: "Die Mitgliedschaft konnte nicht gelöscht werden."
      },
      product: {
        name: "Produktrechnung"
      },
      fields: {
        id: "Nummer",
        balance: "Kontostand",
        type: "Typ",
        discount_prc: "Rabattprozentsatz"
      },
      generate: {
        name: "Rechnung generieren",
        all: "Generieren aller Rechnungen"
      },
      options: {
        name: "Abrechnungsoptionen"
      },
      more: {
        past_invoices: "Die letzten {0} Rechnungen",
        no_invoices: "Keine Rechnung",
        past_appointments: "Termin in den letzten {0} Monaten",
        no_appointments: "Kein Termin",
        use_balance: "Verwenden Sie das Guthaben des Mitgliedskontos zum Bezahlen",
        new_balance: "Neues Kontostand",
        subscribe: "Member werden"
      }
    },
    payment: {
      name: "Zahlung | Zahlungen",
      self: {
        name: "Meine Rechnungen",
        to_come: "{0} Rechnungen",
        see_all: "Siehe meine Rechnungen"
      },
      create: {
        name: "Neue Zahlung",
        confirm: "Die neue Zahlung wurde erstellt.",
        error: "Die neue Zahlung konnte nicht erstellt werden."
      },
      update: {
        name: "Zahlung bearbeiten",
        confirm: "Die Zahlung wurde erfolgreich geändert.",
        error: "Die Zahlung konnte nicht geändert werden."
      },
      delete: {
        name: "Zahlung löschen",
        confirm: "Die Zahlung wurde erfolgreich gelöscht.",
        error: "Die Zahlung konnte nicht gelöscht werden."
      },
      search: {
        name: "Zahlung suchen"
      },
      fields: {
        id: "Nummer",
        amount: "Betrag",
        invoice: "Rechnungsnummer",
        currency: "Währung",
        use_invoice_user: "Verwenden Sie den mit der Rechnung verknüpften Benutzer"
      },
      more: {
        invoice_details: "Rechnungs-Details",
        make_a_payment: "Machen Sie eine Online-Zahlung über SumUp",
        payment_success: "Zahlung war erfolgreich!",
        can_close: "Sie können dieses Fenster nun schließen.",
        use_form: "Last {0} invoices",
      }
    },
    payment_type: {
      name: "Zahlungsart",
      account_balance: "Verrechnung",
      card: "Bankkarte",
      cash: "Kasse",
      bank_transfer: "Banküberweisung"
    },
    product: {
      name: "Produkt | Produkte",
      create: {
        name: "Neues Produkt",
        confirm: "Das neue Produkt wurde erstellt.",
        error: "Das neue Produkt konnte nicht erstellt werden."
      },
      update: {
        name: "Produkt bearbeiten",
        confirm: "Das Produkt wurde erfolgreich geändert.",
        error: "Das Produkt konnte nicht geändert werden."
      },
      delete: {
        name: "Produkt löschen",
        confirm: "Das Produkt wurde erfolgreich gelöscht.",
        error: "Das Produkt konnte nicht gelöscht werden."
      },
      fields: {
        name: "Name",
        category: "Kategorie",
        price: "Preis",
        quantity: "Menge",
        currency: "Währung"
      },
      more: {
        new_custom: "Neues manuelles Produkt"
      }
    },
    product_type: {
      name: "Zahlungsart",
      card: "Mitgliedskarte",
      consultation: "Beratung",
    },
    relationship: {
      name: "Beziehung | Beziehungen",
      create: {
        name: "Neue Beziehung"
      },
      type: {
        father: "Vater",
        grandfather: "Großvater",
        mother: "Mutter",
        grandmother: "Großmutter",
        brother: "Bruder",
        sister: "Schwester",
        son: "Sohn",
        daughter: "Tochter",
        grandson: "Enkel",
        granddaughter: "Enkelin",
        cousin: "Cousin",
        cousine: "Vetter",
        family: "Familie",
        friend: "Freund"
      }
    },
    user: {
      name: "Benutzer | Benutzer",
      create: {
        name: "Neuer Benutzer",
        confirm: "Der neue Benutzer wurde erstellt.",
        error: "Der neue Benutzer konnte nicht erstellt werden.",
        self: {
          confirm: "Ihr neues Konto wurde erstellt. Sie erhalten nun eine Bestätigungs-E-Mail an die Adresse, mit der Sie sich registriert haben.",
          error: "Ihr Konto konnte nicht erstellt werden. Die E-Mail-Adresse ist bereits vergeben.",
          form_invalid: "Die Formulardaten fehlen oder sind unvollständig. Bitte versuchen Sie es erneut, indem Sie die Anweisungen befolgen."
        }
      },
      update: {
        name: "Benutzer bearbeiten",
        confirm: "Der Benutzer wurde erfolgreich bearbeitet.",
        error: "Der Benutzer konnte nicht bearbeitet werden.",
        self: {
          button: "Bearbeiten Sie meine Informationen",
          confirm: "Dein Konto wurde aktualisiert.",
          error: "Ihr Konto konnte nicht aktualisiert werden."
        }
      },
      delete: {
        name: "Benutzer löschen",
        confirm: "Der Benutzer wurde erfolgreich gelöscht.",
        error: "Der Benutzer konnte nicht gelöscht werden."
      },
      search: {
        name: "Benutzer suchen"
      },
      fields: {
        last_name: "Name",
        second_name: "Zweiter Vorname(n)",
        first_name: "Vorname",
        birth_name: "Geburtsname",
        job: "Beruf",
        phone_private: "Private Telefonnummer",
        cellphone: "Handynummer",
        email: "E-Mail Addresse",
        gender: {
          name: "Geschlecht",
          male: "Männliche",
          female: "Weiblich",
          other: "Andere"
        },
        username: "Nutzername",
        password: "Passwort",
        birthday: "Geburtsdatum",
        electronic_invoices: "Ich möchte neben elektronischen Rechnungen auch Papierrechnungen erhalten.",//electronic_invoices: "Ich wünsche Papierrechnungen erhalten.",
        profile_picture: 'Profilbild',
        password_confirm: "Bestätigung",
        password_confirm_ph: "Geben Sie Ihr Passwort erneut ein",
        language: "Ausgewählte Sprache",
        problems: "Beschwerden",
        problems_ph: "Beschreiben Sie die Symptome, die Sie behandeln möchten.",
        medication: "Nehmen Sie regelmässig Medikamente?",
        hear_about_us: "Wie wurden Sie auf uns aufmerksam?",
        recommendation: "Ich habe die Empfehlung von",
        marketing: "Ich bin damit einverstanden, weiteres informationsmaterial von „new body concept“ zu erhalten.",
        data_optin: "Mit dem Absenden dieses Formulars erkläre ich mich damit einverstanden, dass meine personenbezogenen Daten ausschliessslich von „new body concept“ verwendet werden."
      },
      more: {
        all_person_infos: {
          title: "Persönliche Informationen",
          is_therapist: "Therapeut?"
        },
        name: "Name",
        full_name: "Vollständiger Name"
      },
      label_groups: {
        logins: "Kennungen",
        personal_infos: "Persönliche Informationen",
        address: "Adresse",
        medical_questions: "Medizinische Informationen",
      },
      list: "Liste der Benutzer",
      imported_warning: {
        sentence: "Ihr Konto ist noch nicht sicher. Bitte bearbeiten Sie Ihr Konto, um ein neues persönliches Passwort festzulegen.",
      }
    }
  }
}
