<template>
  <div class="header">
    <h1>{{ $t('ui.product.name', 2) }}</h1>
  </div>
  <JbUPanel v-if="productsOrphan && productsOrphan.length > 0" :title="$t('ui.product.name', 2)" icon="book-medical" :padding="0">
    <div v-if="getProductsError">{{ $t('ui.common.error.name') }}</div>
    <div>
      <JbUPanelList display="grid" :class="getProductsLoading ? 'fetch-loading' : ''" @updateStart="productsPaging.start = $event">
        <div class="inline-infos header">
          <div>{{ $t('ui.product.fields.name') }}</div>
          <div>{{ $t('ui.product.fields.price') }}</div>
          <div class="actions">{{ $t('ui.list.actions.name') }}</div>
        </div>
        
      <template v-for="product of productsOrphan" :key="product">
        <JbUPanelListRow editable :item-id="product.id" @edit-element="setupEditModal(product.id)">
          <template #inline-infos>
            <div>{{ product.name }}</div>
            <div>{{ product.price }} {{ product.currency ? product.currency.symbol : '' }}</div>
          </template>

          <template #all-infos>
            <h2>{{ $t('ui.product.more.invoice_details') }}</h2>
            <div>
            </div>
          </template>
        </JbUPanelListRow>
      </template>
      </JbUPanelList>
    </div>
  </JbUPanel>
  <JbUPanel v-for="productCategory of productCategories" :key="productCategory" :title="productCategory.name" icon="book-medical" :padding="0">
    <div v-if="getProductsError">{{ $t('ui.common.error.name') }}</div>
    <div v-if="productCategory.products">
      <JbUPanelList display="grid" :class="getProductsLoading ? 'fetch-loading' : ''" @updateStart="productsPaging.start = $event">
        <div class="inline-infos header">
          <div>{{ $t('ui.product.fields.name') }}</div>
          <div>{{ $t('ui.product.fields.price') }}</div>
          <div class="actions">{{ $t('ui.list.actions.name') }}</div>
        </div>
        
      <template v-for="product of productCategory.products" :key="product">
        <JbUPanelListRow editable :item-id="product.id" @edit-element="setupEditModal(product.id)">
          <template #inline-infos>
            <div>{{ product.name }}</div>
            <div>{{ product.price }} {{ product.currency ? product.currency.symbol : '' }}</div>
          </template>

          <template #all-infos>
            <h2>{{ $t('ui.product.more.invoice_details') }}</h2>
            <div>
            </div>
          </template>
        </JbUPanelListRow>
      </template>
      </JbUPanelList>
    </div>
  </JbUPanel>
  <ProductUpdateModal :elementId="updateId" :listPaging="productsPaging"/>
</template>

<script lang="ts">
import { defineComponent, inject, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getProductsQuery from '@/graphql/product/gets_byCategory.gql'

import ProductUpdateModal from '@/components/modals/ProductUpdate.vue'

export default defineComponent({
  name: 'Products',
  components: {
    ProductUpdateModal
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    // Products list
    const productsPaging = reactive({
      start: 0,
      limit: 100,
      max: 0,
      noProduct: false
    })

    const { result:getProductsQR, loading: getProductsLoading, error: getProductsError } = useQuery(getProductsQuery, () => ({start: productsPaging.start, limit:productsPaging.limit}))

    const productCategories = useResult(getProductsQR, null, data => data.productCategories)
    const productsOrphan = useResult(getProductsQR, null, data => data.products)
    const productsCount = useResult(getProductsQR, null, data => data.productsConnection.aggregate.count)
    productsPaging.max = productsCount as any


    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-product-update")
    }

    

    return {
      updateId,
      setupEditModal,

      productCategories,
      productsOrphan,
      getProductsLoading,
      getProductsError,

      productsPaging,

      helpers
    }

  }
})
</script>