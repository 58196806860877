<template>
  <JbCForm :validation="form" ref="formRef">
    <div class="form-group-label">{{ $t('ui.user.label_groups.logins') }}</div>

    <div class="form-row">
      <label :for="'email-'+$.uid">{{ $t('ui.user.fields.email') }}*</label>
      <div><input type="text" v-model="form.email.model" :id="'email-'+$.uid" name="email" :placeholder="$t('ui.user.fields.email')"></div>
    </div>
    <div class="form-row">
      <label :for="'password-'+$.uid">{{ $t('ui.user.fields.password') }}*</label>
      <div><input type="password" v-model="form.password.model" :id="'password-'+$.uid" name="password" :placeholder="$t('ui.user.fields.password')"></div>
    </div>
    <div v-if="type === 'user'" class="form-row">
      <label :for="'password_confirm-'+$.uid">{{ $t('ui.user.fields.password_confirm') }}*</label>
      <div><input type="password" v-model="form.password_confirm.model" :id="'password_confirm-'+$.uid" name="password_confirm" :placeholder="$t('ui.user.fields.password_confirm_ph')"></div>
    </div>
    
    <div class="form-group-label">{{ $t('ui.user.label_groups.personal_infos') }}</div>

    <div class="form-row">
      <label :for="'first_name-'+$.uid">{{ $t('ui.user.fields.first_name') }}*</label>
      <div><input type="text" v-model="form.first_name.model" :id="'first_name-'+$.uid" :class="form.first_name.status.class" name="first_name" :placeholder="$t('ui.user.fields.first_name')"><div v-if="form.first_name.status.class === 'error'">{{ form.first_name.status.message }}</div></div>
    </div>
    <div class="form-row">
      <label :for="'second_name-'+$.uid">{{ $t('ui.user.fields.second_name') }}</label>
      <div><input type="text" v-model="form.second_name.model" :id="'second_name-'+$.uid" name="second_name" :placeholder="$t('ui.user.fields.second_name')"></div>
    </div>
    <div class="form-row">
      <label :for="'last_name-'+$.uid">{{ $t('ui.user.fields.last_name') }}*</label>
      <div><input type="text" v-model="form.last_name.model" :id="'last_name-'+$.uid" name="last_name" :placeholder="$t('ui.user.fields.last_name')"></div>
    </div>
    <div class="form-row">
      <label :for="'birth_name-'+$.uid">{{ $t('ui.user.fields.birth_name') }}</label>
      <div><input type="text" v-model="form.birth_name.model" :id="'birth_name-'+$.uid" name="birth_name" :placeholder="$t('ui.user.fields.birth_name')"></div>
    </div>
    <div class="form-row">
      <label :for="'gender-'+$.uid">{{ $t('ui.user.fields.gender.name') }}*</label>
      <div>
        <select :id="'gender-'+$.uid" name="gender" v-model="form.gender.model">
          <option value="male">{{ $t('ui.user.fields.gender.male') }}</option>
          <option value="female">{{ $t('ui.user.fields.gender.female') }}</option>
          <option value="other">{{ $t('ui.user.fields.gender.other') }}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label :for="'birth_day-'+$.uid">{{ $t('ui.user.fields.birthday') }}*</label>
      <div class="flexbox" style="flex-wrap:nowrap;">
        <select :id="'birth_day-'+$.uid" v-model="form.birth_day.model" name="birth_day">
          <option v-for="n in 31" :key="n" :value="String(n).padStart(2,'0')">{{String(n).padStart(2,'0')}}</option>
        </select>
        <select v-model="form.birth_month.model" name="birth_month">
          <option v-for="n in 12" :key="n" :value="String(n).padStart(2,'0')">{{String(n).padStart(2,'0')}}</option>
        </select>
        <select v-model.number="form.birth_year.model" name="birth_year">
          <option v-for="n in 110" :key="n" :value="currentYear-n">{{currentYear-n}}</option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label :for="'cellphone'+$.uid">{{ $t('ui.user.fields.cellphone') }}*</label>
      <div class="flexbox" style="flex-wrap:nowrap;">
        <select v-model="form.cellphone.code">
          <option value="+41">{{ $t('country.switzerland') }} (+41)</option>
          <option value="+49">{{ $t('country.germany') }} (+49)</option>
          <option value="+33">{{ $t('country.france') }} (+33)</option>
          <option value="+43">{{ $t('country.austria') }} (+43)</option>
          <option value="+34">{{ $t('country.spain') }} (+34)</option>
          <option value="+39">{{ $t('country.italy') }} (+39)</option>
          <option value="+44">{{ $t('country.great_britain') }} (+44)</option>
          <option value="+1">{{ $t('country.united_states') }} (+1)</option>
        </select>
        <input type="tel" v-model="form.cellphone.model" :id="'cellphone'+$.uid" name="cellphone" :placeholder="$t('ui.user.fields.cellphone')">
      </div>
    </div>
    <div class="form-row">
      <label :for="'phone_private'+$.uid">{{ $t('ui.user.fields.phone_private') }}</label>
        <div class="flexbox" style="flex-wrap:nowrap;">
        <select v-model="form.phone_private.code" name="birth_day">
          <option value="+41">{{ $t('country.switzerland') }} (+41)</option>
          <option value="+49">{{ $t('country.germany') }} (+49)</option>
          <option value="+33">{{ $t('country.france') }} (+33)</option>
          <option value="+43">{{ $t('country.austria') }} (+43)</option>
          <option value="+34">{{ $t('country.spain') }} (+34)</option>
          <option value="+39">{{ $t('country.italy') }} (+39)</option>
          <option value="+44">{{ $t('country.great_britain') }} (+44)</option>
          <option value="+1">{{ $t('country.united_states') }} (+1)</option>
        </select>
        <input type="tel" v-model="form.phone_private.model" :id="'phone_private'+$.uid" name="phone_private" :placeholder="$t('ui.user.fields.phone_private')">
      </div>
    </div>

    <div class="form-group-label">{{ $t('ui.user.label_groups.address') }}</div>
    
    <div class="form-row">
      <label :for="'street-'+$.uid">{{ $t('ui.address.fields.street') }}*</label>
      <div><input type="text" v-model="form.street.model" :id="'street-'+$.uid" name="street" :placeholder="$t('ui.address.fields.street')"></div>
    </div>
    <div class="form-row">
      <label :for="'street_extra-'+$.uid">{{ $t('ui.address.fields.street_extra') }}</label>
      <div><input type="text" v-model="form.street_extra.model" :id="'street_extra-'+$.uid" name="street_extra" :placeholder="$t('ui.address.fields.street_extra')"></div>
    </div>
    <div class="form-row">
      <label :for="'postal_code-'+$.uid">{{ $t('ui.address.fields.postal_code') }}*</label>
      <div><input type="text" v-model="form.postal_code.model" :id="'postal_code-'+$.uid" name="postal_code" :placeholder="$t('ui.address.fields.postal_code')"></div>
    </div>
    <div class="form-row">
      <label :for="'city-'+$.uid">{{ $t('ui.address.fields.city') }}*</label>
      <div><input type="text" v-model="form.city.model" :id="'city-'+$.uid" name="city" :placeholder="$t('ui.address.fields.city')"></div>
    </div>
    <div class="form-row">
      <label :for="'region-'+$.uid">{{ $t('ui.address.fields.region') }}</label>
      <div><input type="text" v-model="form.region.model" :id="'region-'+$.uid" name="region" :placeholder="$t('ui.address.fields.region')"></div>
    </div>
    <div class="form-row">
      <label :for="'country-'+$.uid">{{ $t('ui.address.fields.country') }}*</label>
      <div><select v-model.number="form.country.model" :id="'country-'+$.uid"><option v-for="country of countries" :key="country" :value="country.id">{{ $t('country.'+country.code) }}</option></select></div>
    </div>

    <div class="form-group-label">{{ $t('ui.user.label_groups.medical_questions') }}</div>

    <div class="form-row">
      <label :for="'job-'+$.uid">{{ $t('ui.user.fields.job') }}</label>
      <div><input type="text" v-model="form.job.model" :id="'job-'+$.uid" name="job" :placeholder="$t('ui.user.fields.job')"></div>
    </div>
    <div class="form-row">
      <label :for="'problems-'+$.uid">{{ $t('ui.user.fields.problems') }}</label>
      <div><input type="text" v-model="form.problems.model" :id="'problems-'+$.uid" name="problems" :placeholder="$t('ui.user.fields.problems_ph')"></div>
    </div>
    <div class="form-row">
      <label :for="'medication-'+$.uid">{{ $t('ui.user.fields.medication') }}</label>
      <div><input type="text" v-model="form.medication.model" :id="'medication-'+$.uid" name="medication" :placeholder="$t('ui.common.fields.write_here')"></div>
    </div>
    <div class="form-row">
      <label :for="'hear_about_us-'+$.uid">{{ $t('ui.user.fields.hear_about_us') }}</label>
      <div><input type="text" v-model="form.hear_about_us.model" :id="'hear_about_us-'+$.uid" name="hear_about_us" :placeholder="$t('ui.common.fields.write_here')"></div>
    </div>
    <div class="form-row">
      <label :for="'recommendation-'+$.uid">{{ $t('ui.user.fields.recommendation') }}</label>
      <div><input type="text" v-model="form.recommendation.model" :id="'recommendation-'+$.uid" name="recommendation" :placeholder="$t('ui.common.fields.write_here')"></div>
    </div>

    <hr/>
    
    <div v-if="display === 'create' && type === 'user'" class="form-row row-checkbox">
      <div class="checkbox"><input type="checkbox" v-model="form.data_optin.model" :id="'data_optin'+$.uid" name="data_optin"><label :for="'data_optin'+$.uid">{{ $t('ui.user.fields.data_optin') }} *</label></div>
    </div>
    <div v-if="display === 'edit'" class="form-row row-checkbox">
      <div class="checkbox"><input type="checkbox" v-model="form.electronic_invoices.model" :id="'electronic_invoices'+$.uid" name="electronic_invoices"><label :for="'electronic_invoices'+$.uid">{{ $t('ui.user.fields.electronic_invoices') }}</label></div>
    </div>
    <div v-if="display === 'create'"  class="form-row row-checkbox">
      <div class="checkbox"><input type="checkbox" v-model="form.marketing.model" :id="'marketing'+$.uid" name="marketing"><label :for="'marketing'+$.uid">{{ $t('ui.user.fields.marketing') }}</label></div>
    </div>

    <template v-if="type === 'admin'">
      <hr/>
      <div class="form-row">
        <label>{{ $t('ui.relationship.name', 2) }}</label>
        <JbUCard theme="input">
          <div>
            <button @click="addRelationshipToFormList()" class="highlighted"><font-awesome-icon icon="plus" />{{ $t('ui.relationship.create.name') }}</button>
          </div>
          <div class="relationship_list">
            <div v-for="relationship, key of form.relationships" :key="relationship" >
              <div><JbCSearch name="user" :options="searchedUsersList" placeholder="Type here to search" :preselected="preselectedUsers[key]" @keyUp="searchUsers" @listItemClick="updateSelectedUser($event, key)"/></div>
              <select v-model="relationship.type">
                <option value="father">{{ $t('ui.relationship.type.father') }}</option>
                <option value="grandfather">{{ $t('ui.relationship.type.grandfather') }}</option>
                <option value="mother">{{ $t('ui.relationship.type.mother') }}</option>
                <option value="grandmother">{{ $t('ui.relationship.type.grandmother') }}</option>
                <option value="brother">{{ $t('ui.relationship.type.brother') }}</option>
                <option value="sister">{{ $t('ui.relationship.type.sister') }}</option>
                <option value="son">{{ $t('ui.relationship.type.son') }}</option>
                <option value="daughter">{{ $t('ui.relationship.type.daughter') }}</option>
                <option value="grandson">{{ $t('ui.relationship.type.grandson') }}</option>
                <option value="granddaughter">{{ $t('ui.relationship.type.granddaughter') }}</option>
                <option value="cousin">{{ $t('ui.relationship.type.cousin') }}</option>
                <option value="family">{{ $t('ui.relationship.type.family') }}</option>
                <option value="friend">{{ $t('ui.relationship.type.friend') }}</option>
              </select>
              <font-awesome-icon icon="people-arrows" />
              <input type="hidden" v-model="relationship.deducedRelationship"/>
              <input type="text" disabled v-model="relationship.deducedRelationshipName"/>
              <div><button class="error icon-only" @click.stop="removeRelationshipFromFormList(index)"><font-awesome-icon icon="times"/></button></div>
            </div>
          </div>
        </JbUCard>
      </div>
    </template>
  </JbCForm>
</template>

<script lang="ts">
import { defineComponent, inject, watch, watchEffect, computed, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'
import _ from "lodash";

import searchUsersQuery from '@/graphql/user/search.gql'

import getCountriesQuery from '@/graphql/country/gets.gql'

export default defineComponent({
  name: 'UserForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const currentYear = new Date().getFullYear()

    const { t, locale } = useI18n({ useScope: 'global' })

    const formRef = ref<null | HTMLFormElement>(null)
    
    const preselectedUsers = reactive([])

    const form: { [Key: string]: any } = reactive({
      email: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      password: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      password_confirm: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      first_name: {
        model: '',
        validation: {
          max: 25,
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      second_name: {
        model: '',
        validation: {
          
        }
      },
      last_name: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      birth_name: {
        model: '',
        validation: {
          
        }
      },
      gender: {
        model: 'male'
      },
      birth_day: {
        model: '01'
      },
      birth_month: {
        model: '01'
      },
      birth_year: {
        model: currentYear-1
      },
      cellphone: {
        model: '',
        code:'+41',
        validation: {
          
        }
      },
      phone_private: {
        model: '',
        code:'+41',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      street: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      street_extra: {
        model: '',
        validation: {
          
        }
      },
      postal_code: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      city: {
        model: '',
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      region: {
        model: '',
        validation: {
          
        }
      },
      job: {
        model: '',
        validation: {
          
        }
      },
      problems: {
        model: '',
        validation: {
          
        }
      },
      medication: {
        model: '',
        validation: {
          
        }
      },
      hear_about_us: {
        model: '',
        validation: {
          
        }
      },
      recommendation: {
        model: '',
        validation: {
          
        }
      },
      country: {
        model: 1
      },
      electronic_invoices: {
        model: false
      },
      marketing: {
        model: false
      },
      data_optin: {
        model: false,
        validation: {
          required: true
        },
        status: {
          class: 'neutral',
          message: ""
        }
      },
      relationships: [] as any[]
    })
    

    ////////// Field : Country - Gets
    const { result: getCountriesResult, onResult: getCountriesOnResult} = useQuery(getCountriesQuery, {locale: locale})
    const countries = useResult(getCountriesResult, null, data => data.countries)
    getCountriesOnResult((QR) => {
      form.country.model = QR.data.countries[0].id
    })

    const addRelationshipToFormList = (type: string) => {
      form.relationships.push({type: "family", user: 0, deducedRelationship: "", deducedRelationshipName: ""})
      //console.log(form.relationships)
    }
    const removeRelationshipFromFormList = (index: number) => {
      form.relationships.splice(index, 1)
    }

    const deduceRelationship = (relationship: any) => {      
      if(relationship.type === 'family') {
        relationship.deducedRelationship = 'family'
      } else if(relationship.type === 'friend') {
        relationship.deducedRelationship = 'friend'
      } else if(relationship.type === 'father' || relationship.type === 'mother') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'daughter' : 'son'
      } else if(relationship.type === 'grandfather' || relationship.type === 'grandmother') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'granddaughter' : 'grandson'
      } else if(relationship.type === 'brother' || relationship.type === 'sister') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'sister' : 'brother'
      } else if(relationship.type === 'son' || relationship.type === 'daughter') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'mother' : 'father'
      } else if(relationship.type === 'grandson' || relationship.type === 'granddaughter') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'grandmother' : 'grandfather'
      } else if(relationship.type === 'cousin' || relationship.type === 'cousine') {
        relationship.deducedRelationship = form.gender.model === 'female' ? 'cousine' : 'cousin'
      }
      relationship.deducedRelationshipName = t('ui.relationship.type.'+relationship.deducedRelationship)
    }


    watch(() => _.cloneDeep(form.relationships), () => {
      for(const relationship in form.relationships) {
        deduceRelationship(form.relationships[relationship])
      }
    })

    watchEffect(() => {
      for(const relationship in form.relationships) {
        deduceRelationship(form.relationships[relationship])
      }
    })

    ////////// Field : User - Search
    const { searchFunction: searchUsers, ElementsList: searchedUsers } = searchHelper('users', searchUsersQuery)

    const searchedUsersList = computed(() => {
      return toSearchList(searchedUsers, 'user')
    })

    const updateSelectedUser = (item: {[key: string]: any}, key: string) => {
      const selectedUser = helpers.getByIdInArray(item.id, searchedUsers.value as any[])
      form.relationships[key].user = selectedUser.id
    }
    

    const validate = () => {
      if(formRef.value != null)
        return formRef.value.validateForm(form)
    }

    // Testing purposes
    const fillForm = () => {
      console.log("Signup 'Fill Form' button clicked.")
      // With validation
      form.first_name.model= 'a_test_first_name'
      form.second_name.model = 'test_second_name'
      form.last_name.model = 'a_test_last_name'
      form.birth_name.model = 'test_birth_name'
      form.job.model = 'test_job'
      form.phone_private.model = '666666666'
      form.cellphone.model = '666666667'
      form.street.model = 'test_street'
      form.street_extra.model = 'test_street_extra'
      form.postal_code.model = 'test_postal_code'
      form.city.model = 'test_city'
      form.region.model = 'test_region'

      form.email.model ='testemail@email.fake'
      form.password.model = 'test_password'
      form.password_confirm.model = 'test_password'
      form.problems.model = 'test_problems'
      form.medication.model = 'test_medication'
      form.hear_about_us.model = 'test_hear_about_us'
      form.recommendation.model = 'test_recommendation'

      form.birth_day.model = '05'
      form.birth_month.model = '07'
      form.birth_year.model = 1991

      // Without validation
      form.gender.model = 'other'
      form.electronic_invoices.model = true
      form.data_optin.model = true
      form.marketing.model = true
    }

    return {
      form,
      formRef,

      currentYear,
      countries,
      addRelationshipToFormList, removeRelationshipFromFormList,
      deduceRelationship,
      
      searchUsers,
      searchedUsersList,
      updateSelectedUser,
      preselectedUsers,

      fillForm,
      validate
    }
  }
});
</script>


<style scoped lang="scss">
.relationship_list {
  > div { 
    margin-top:15px;
    border-bottom: 1px solid var(--space-3);
    display:flex;
    align-items: center;
    column-gap:var(--default-gap);
  }
}
.form-group-label {
  border-bottom:1px solid var(--color-hr);
  font-size:var(--text-m);
  margin:var(--space-7) var(--space-4) var(--space-5) var(--space-4);
  padding:var(--space-2);
}
</style>