
import { defineComponent, onMounted, inject, reactive, ref, computed } from 'vue'

declare global {
  interface Window {
    SumUpCard: any;
  }
}

export default defineComponent({
  name: 'SumupPayment',
  props: {
    checkout: Object
  },
  emits: ['checkout-success'],
  setup(props, { emit }) {

    
    const mountSumUpCard = () => {
      window.SumUpCard.mount({
        checkoutId: props.checkout!.id,
        onResponse: function(type: any, body: any) {
          console.log('Type', type);
          console.log('Body', body);

          if(type === "success") {
            emit('checkout-success');
          }

        }
      });
    }

    onMounted(() => {
      if (document.getElementById('sumup-card-sdk')) {
        mountSumUpCard()
      }

      const scriptTag = document.createElement("script");
      scriptTag.src = "https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js";
      scriptTag.id = "sumup-card-sdk";
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
      
      scriptTag.onload = () => {
        mountSumUpCard()
      }
    })
  }
})

