<template>
  <JbPMain>
    <JbCToasts/>
    <JbUHeader>
      <div id="header-main-icon">
        <router-link to="/administration/home"><img src="img/favicons/android-chrome-192x192.png"/></router-link>
      </div>
      <!--<div class="sidebar-toggle-btn" id="left" @click="$JbStore.leftSidebar.toggle()">
        <font-awesome-icon icon="bars"/> <font-awesome-icon icon="angle-right"/>
      </div>-->
      <div class="spacer">
      </div>
      <JbUDropdown name="dropdown" :title="$store.getters.currentLocation.name" icon="angle-down" align="right">
        <div v-for="location of locations" :key="location.id" @click="$store.commit('changeLocation', location)">
          {{ location.name }}
        </div>
      </JbUDropdown>
      <div class="sidebar-toggle-btn" id="left" @click="$JbStore.leftSidebar.toggle()"><font-awesome-icon icon="bars" /></div>
    </JbUHeader>

    <JbCSidebar side="left" :toggle="$JbStore.leftSidebar.get()">
      <JbUMenuProfile :imageUrl="profile_picture" :name="firstName+' '+lastName" :role="$t('ui.role.'+role)">
        <LocaleSwitch display="sidebar" style="margin-bottom:var(--default-gap);" />
        <router-link to="/logout"><JbUButton size="l" icon-left="sign-out-alt" fullwidth>{{ $t('ui.common.disconnect.self') }}</JbUButton></router-link>
      </JbUMenuProfile>
      <JbCMenu>
        <li><router-link to="/administration/patients"><font-awesome-icon fixed-width icon="user" />{{ $t('ui.user.name', 2) }}<div class="spacer"></div><JbUButton size="xs" icon-left="plus" color="blue" @click.prevent="emitter.emit('open-modal-user-create')"></JbUButton></router-link></li>
        <li><router-link to="/administration/invoices"><font-awesome-icon fixed-width icon="file-invoice-dollar" />{{ $t('ui.invoice.name', 2) }}<div class="spacer"></div><JbUButton size="xs" icon-left="plus" color="orange" @click.prevent="emitter.emit('open-modal-invoice-create')"></JbUButton></router-link></li>
        <li><router-link to="/administration/payments"><font-awesome-icon fixed-width icon="dollar-sign" />{{ $t('ui.payment.name', 2) }}<div class="spacer"></div><JbUButton size="xs" icon-left="plus" color="pink" @click.prevent="emitter.emit('open-modal-payment-create')"></JbUButton></router-link></li>
        <li><router-link to="/administration/appointments"><font-awesome-icon fixed-width icon="calendar-check" />{{ $t('ui.appointment.name', 2) }}<div class="spacer"></div><JbUButton size="xs" icon-left="plus" color="lime" @click.prevent="emitter.emit('open-modal-appointment-create')"></JbUButton></router-link></li>
        <li><router-link to="/administration/memberships"><font-awesome-icon fixed-width icon="address-card" />{{ $t('ui.membership.name', 2) }}</router-link></li>
        <li><router-link to="/administration/settings"><font-awesome-icon fixed-width icon="cog" />{{ $t('ui.page.settings.name') }}</router-link></li>
      </JbCMenu>
      <div style="padding:var(--space-4)">
        
        
      </div>
    </JbCSidebar>

    <JbCMainContainer>
      <router-view />
    </JbCMainContainer>

    <!--<JbCSidebar side="right" :toggle="$JbStore.rightSidebar.get()">
      <div class="sidebar-section">
        <LocaleSwitch display="sidebar"/>
        <router-link to="/logout"><button style="height:60px; width:100%; justify-content:center"><font-awesome-icon icon="sign-out-alt" />{{ $t('ui.common.disconnect.self') }}</button></router-link>
      </div>
    </JbCSidebar>-->
    <UserCreateModal/><InvoiceCreateModal/><PaymentCreateModal/><ProductCreateModal/>
  </JbPMain>
</template>

<script lang="ts">
import { defineComponent, inject, ref, computed } from 'vue'
import { Emitter } from 'mitt'
import { useQuery, useResult } from '@vue/apollo-composable'
import UserCreateModal from '@/components/modals/UserCreate.vue'
import InvoiceCreateModal from '@/components/modals/InvoiceCreate.vue'
import PaymentCreateModal from '@/components/modals/PaymentCreate.vue'
import ProductCreateModal from '@/components/modals/ProductCreate.vue'
import getLocationsQuery from '@/graphql/location/gets.gql'
import LocaleSwitch from '../components/LocaleSwitch.vue'

export default defineComponent({
  name: 'BackendAdmin',
  components: {
    UserCreateModal,
    InvoiceCreateModal,
    PaymentCreateModal,
    ProductCreateModal,
    LocaleSwitch
  },
  setup(){
    // get emitter
    const emitter = inject('$emitter') as Emitter
    const contentManagerUrl = inject('$ContentManagerUrl') as string

    const { result: getLocationsResult } = useQuery(getLocationsQuery)
    const locations = useResult(getLocationsResult, null, data => data.locations)

    const firstName = ref(sessionStorage.getItem('first_name'))
    const lastName = ref(sessionStorage.getItem('last_name'))
    const role = ref(sessionStorage.getItem('role'))

    const profile_picture = computed(() => {
      if (sessionStorage.getItem('profile_picture') === null)
        return null
      else
        return contentManagerUrl+sessionStorage.getItem('profile_picture')
    })

    return {
      locations,
      emitter,

      firstName,
      lastName,
      role,
      profile_picture
    }
  }
})
</script>

<style scoped lang="scss">
.sidebar-section{
  padding:var(--space-5);
  
  .locale-switch {
    margin-bottom:var(--space-5);
  }
}
</style>