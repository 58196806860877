<template>
  <JbUModal name="invoice-update" cancellable>
    <template v-slot:headerLeft>
      <div v-if="$AppTesting" class="test-commands"><button @click.stop="fillForm">Fill Form</button></div>
      {{ $t('ui.invoice.update.name') }} N°{{ elementId }}
    </template>
    <template v-slot:headerRight>
      <button class="error" @click="deleteElement">{{ $t('ui.invoice.delete.name') }}</button>
      <!--{{ elementId }}{{ form }}-->
    </template>
    <template v-slot:default>
      <InvoiceForm ref="invoiceFormRef" display="update" type="admin"/>
    </template>
    <template v-slot:footerLeft>
      <!--<JbUAlert v-if="updateStatus.success" type="success" :timeout="5">{{ $t('ui.user.create.confirm') }}</JbUAlert>-->
      <JbUAlert v-if="updateStatus.error" type="error" :timeout="5">{{ $t('ui.invoice.update.error') }}</JbUAlert>
    </template>
    <template v-slot:footerRight>
      <!--{{ formValues }}-->
      <button class="highlighted" @click="submit_modal">{{ $t('ui.invoice.update.name') }}</button>
    </template>
  </JbUModal>
</template>

<script lang="ts">
import { defineComponent, inject, ref, reactive, watch, computed } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { toSearchListElement, searchValueNulllIfZero } from '@/modules/search.ts'
import getElementQuery from '@/graphql/invoice/get.gql'
import updateElementMutation from '@/graphql/invoice/update.gql'
import deleteElementMutation from '@/graphql/invoice/delete.gql'

import InvoiceForm from '@/components/forms/Invoice.form.vue'

import getInvoicesQuery from '@/graphql/invoice/gets.gql'

export default defineComponent({
  name: 'InvoiceUpdate',
  props: ['elementId', 'listPaging'],
  components: {
    InvoiceForm
  },
  setup(props) {
    // get emitter
    const emitter = inject('$emitter') as Emitter

    const { t } = useI18n({ useScope: 'global' })

    const invoiceFormRef = ref<null | any>(null)

    ////////// MODAL EDIT ID
    const editingElement = reactive({id: props.elementId})
    const editingElementOptions = reactive({enabled : false})
    const { result: getElement, loading: editingElementLoading, onResult: editingElement_onResult } = useQuery(getElementQuery, editingElement, editingElementOptions)
    const updateElementInfos = useResult(getElement, null, data => data.invoice)

    watch(() => props.elementId, (newValue) => {
      editingElement.id = newValue
      editingElementOptions.enabled = true
    })
    

    editingElement_onResult(queryResult => {
      const form = invoiceFormRef.value.form

      if(queryResult.networkStatus === 7) { // 7 mean OK https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts
        //console.log("Element Fetch by id ", updateElementInfos.value.id)

        for (const [key] of Object.entries(updateElementInfos.value)) {
          if(key === 'date' || key === 'due_date') {
            form[key] = new Date(updateElementInfos.value[key])
          } else if (key === 'product_list') {
            // Dégueulasse sa mère, mais obligé car apollo retourne des objects immuables
            form[key] = helpers.unfreezeProducts(updateElementInfos.value[key])
          } else if (key === 'custom_product_list') {
            // Dégueulasse sa mère, mais obligé car apollo retourne des objects immuables
            form[key] = helpers.unfreezeProducts(updateElementInfos.value[key])
          }  else if (key === 'user' && updateElementInfos.value[key] !== null) {
            form['userId'] = updateElementInfos.value[key].id
            invoiceFormRef.value.preselectedUser = toSearchListElement(updateElementInfos.value[key], 'user')
          } else if (key === 'currency') {
            form['currencyId'] = updateElementInfos.value[key].id
          } else { // The rest is automatically set
            form[key] = updateElementInfos.value[key]
          }
        }

      } else {
        console.log("Element Fetch by id failed.")
      }
    })

    

    ////////// UPDATE QUERY
    const { mutate: updateMutation, onDone: updateMutationDone, onError: updateMutationError } = useMutation(updateElementMutation)
    const updateStatus = reactive({
      success: false,
      error: false
    })
    updateMutationDone(() => {
      updateStatus.success = true
      updateStatus.error = false
    })
    updateMutationError(() => {
      updateStatus.error = true
      updateStatus.success = false
    })
    
    updateMutationDone((QR) => {
      emitter.emit("create-toast", {
        props: {
          title: t('ui.invoice.update.confirm'),
          text: QR.data.updateInvoice.invoice.description,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-update")
    })
    updateMutationError(() => {
      console.log('Edit invoice Error')
    })

    ////////// DELETE QUERY
    const { mutate: deleteMutation, onDone: deleteMutationDone, onError: deleteMutationError } = useMutation(deleteElementMutation)
    const removeStatus = reactive({
      success: false,
      error: false
    })
    deleteMutationDone((QR) => {
      emitter.emit("create-toast", {
        props: {
          title: t('ui.invoice.delete.confirm'),
          text: QR.data.deleteInvoice.invoice.description,
          icon: "check-circle",
        }
      })
      emitter.emit("close-modal-invoice-update")
    })
    deleteMutationError(() => {
      removeStatus.error = true
      removeStatus.success = false
    })

    ////////// MODAL SUBMIT
    const submit_modal = () => {
      const form = invoiceFormRef.value.form

      const userId = searchValueNulllIfZero(form.userId)

      // Transform product array into a strapi compatible object
      const product_list: any[] = invoiceHelpers.productsToStrapi(form.product_list)
      const custom_product_list: any[] = invoiceHelpers.customProductsToStrapi(form.custom_product_list)


      const queryInput = { 
        input: { 
          where: {
            id: props.elementId,
          },
          data: {
            description: form.description,
            date: helpers.dateobjToISO(form.date),
            due_date: helpers.dateobjToISO(form.due_date),
            product_list: product_list,
            custom_product_list: JSON.parse(JSON.stringify(custom_product_list)),
            total: invoiceFormRef.value.total_afterDiscount,
            number_month: form.number_month,
            number_in_month: form.number_in_month,

            user: userId,
            currency: form.currencyId
          }
        }
      }

      //console.log(queryInput)

      updateMutation(queryInput)
    }

    ////////// MODAL DELETE BUTTON
    const deleteElement = () => {
      if (confirm(t('ui.element.delete.confirm'))) {
        const queryInput = { 
          input: { 
            where: {
              id: props.elementId,
            }
          }
        }

        deleteMutation(queryInput, {
          update: (cache, { data: { deleteInvoice: deleteMutation } }) => { // Needs to have the graphQL query name
            if(props.listPaging) { // Gotta clean this
              const invoicesCache: any = cache.readQuery({ query: getInvoicesQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit} })

              if(invoicesCache) { // If we are on a page where getInvoicesQuery has been used
                let invoicesClone = [...invoicesCache.invoices]
                invoicesClone = invoicesClone.filter((t: { id: number }) => {
                  return t.id !== deleteMutation.invoice.id;
                })

                cache.writeQuery({ query: getInvoicesQuery, variables: {start: props.listPaging.start, limit:props.listPaging.limit}, data: {
                  invoices: invoicesClone
                }})
              }
            }
          }
        })
      }
    }

    // Testing purposes
    const fillForm = () => {
      invoiceFormRef.value.fillForm()
    }

    return {
      invoiceFormRef,
      submit_modal,
      deleteElement,
      fillForm,
      updateStatus,

      invoiceHelpers,
    }
  }
});
</script>
