
import { defineComponent, inject, watch, computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers, invoiceHelpers } from '@/modules/helpers'
import { searchHelper, toSearchList } from '@/modules/search.ts'

import searchUsersQuery from '@/graphql/user/search.gql'
import getProductsQuery from '@/graphql/product/gets_byCategory.gql'
import getCurrenciesQuery from '@/graphql/currency/gets.gql'

export default defineComponent({
  name: 'InvoiceForm',
  props: ['element', 'display', 'type'],
  setup(props) {
    const formRef = ref<null | any>(null)

    const { t, locale } = useI18n({ useScope: 'global' })

    const selectedUser = ref<{[key: string]: any}>({})
    const preselectedUser = ref({ id:0, name:'' })

    const form: { [Key: string]: any } = reactive({
      id:'',
      description: t('ui.invoice.fields.description_auto'),
      date: new Date(),
      due_date: new Date(),
      product_list: [] as any[],
      custom_product_list: [] as any[],
      userId: "0",
      currencyId: 1
    })
    form.due_date = new Date(form.due_date.getTime() + (30*86400000))
    

    // Fields
    ////////// Field : User - Search
    const { searchFunction: searchUsers, ElementsList: searchedUsers } = searchHelper('users', searchUsersQuery)

    const searchedUsersList = computed(() => {
      return toSearchList(searchedUsers, 'user')
    })

    const updateSelectedUser = (item: {[key: string]: any}) => {
      selectedUser.value = helpers.getByIdInArray(item.id, searchedUsers.value as any[])
      form.userId = (selectedUser.value as any).id
    }

    ////////// Field : Products - Gets
    const { result: getProductsQR } = useQuery(getProductsQuery, {locale: locale})
    const productCategories = useResult(getProductsQR, null, data => data.productCategories)
    const productsOrphan = useResult(getProductsQR, null, data => data.products)

    ////////// Field : Currency - Gets
    const { result: getCurrenciesResult } = useQuery(getCurrenciesQuery)
    const currencies = useResult(getCurrenciesResult, null, data => data.currencies)

    const currencySymbol = computed(() => {
      let selectedCurrency = "Unknown Currency"
      let currency
      
      if(currencies) {
        currency = helpers.getByIdInArray(form.currencyId, currencies.value as any[])
        if(currency !== 0)
          selectedCurrency = currency.symbol
      }

      return selectedCurrency
    })

    const validate = () => {
      return formRef.value.validateForm(form)
    }

    const addProductToFormList = (product: any) => {
      form.product_list.push({product, quantity: 1})
      //console.log(form.product_list)
    }

    const addCustomProductToFormList = () => {
      form.custom_product_list.push({name: "", custom_text: "", price: 0, currency: { id: 1, symbol: "CHF" }, quantity: 1, type: "custom"})
      //console.log(form.custom_product_list)
    }
    

    const removeProductFromFormList = (index: number) => {
      form.product_list.splice(index, 1)
    }
    const removeCustomProductFromFormList = (index: number) => {
      form.custom_product_list.splice(index, 1)
    }

    
    const total = computed(() => {
      return invoiceHelpers.computeTotal(form.product_list) + invoiceHelpers.computeTotal(form.custom_product_list, {custom_products:true})
    })
    const total_afterDiscount = computed(() => {
      if(selectedUser.value.id && selectedUser.value.membership) {
        return invoiceHelpers.computeTotal(form.product_list, {discount_prc: selectedUser.value.membership.membership_type.discount_prc, products_filter: ['consultation']}) + invoiceHelpers.computeTotal(form.custom_product_list, {custom_products:true})
      } else return invoiceHelpers.computeTotal(form.product_list) + invoiceHelpers.computeTotal(form.custom_product_list, {custom_products:true})
    })

    // Testing purposes
    const fillForm = () => {
      form.description = 'test_description',
      (form.product_list as any).push(
        { product : { "id": "19", "name": "Erster Termin mit Robert", "price": 250, "type": "consultation", "currency": { "id": "1", "name": "Swiss Franc", "symbol": "CHF", "__typename": "Currency" }, "__typename": "Product" }, quantity: 3 },
        { product : { "id": "19", "name": "Erster Termin mit Robert", "price": 250, "type": "consultation", "currency": { "id": "1", "name": "Swiss Franc", "symbol": "CHF", "__typename": "Currency" }, "__typename": "Product" }, quantity: 1 },
        { product : { "id": "19", "name": "Erster Termin mit Robert", "price": 250, "type": "consultation", "currency": { "id": "1", "name": "Swiss Franc", "symbol": "CHF", "__typename": "Currency" }, "__typename": "Product" }, quantity: 2 }
      )
    }

    return {
      form,
      formRef,

      searchUsers,
      searchedUsersList,
      updateSelectedUser,
      selectedUser,
      preselectedUser,

      addProductToFormList,
      addCustomProductToFormList,
      removeProductFromFormList,
      removeCustomProductFromFormList,

      currencies,
      currencySymbol,

      productCategories,
      productsOrphan,

      total,
      total_afterDiscount,

      fillForm,
      validate
    }
  }
});
