
import { defineComponent, inject, reactive, ref } from 'vue'
import { Emitter } from 'mitt'
import { useI18n } from 'vue-i18n'
import { useQuery, useResult } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getProductsQuery from '@/graphql/product/gets_byCategory.gql'

import ProductUpdateModal from '@/components/modals/ProductUpdate.vue'

export default defineComponent({
  name: 'Products',
  components: {
    ProductUpdateModal
  },
  setup() {
    // get emitter
    const $emitter = inject('$emitter') as Emitter

    // Products list
    const productsPaging = reactive({
      start: 0,
      limit: 100,
      max: 0,
      noProduct: false
    })

    const { result:getProductsQR, loading: getProductsLoading, error: getProductsError } = useQuery(getProductsQuery, () => ({start: productsPaging.start, limit:productsPaging.limit}))

    const productCategories = useResult(getProductsQR, null, data => data.productCategories)
    const productsOrphan = useResult(getProductsQR, null, data => data.products)
    const productsCount = useResult(getProductsQR, null, data => data.productsConnection.aggregate.count)
    productsPaging.max = productsCount as any


    // User ID sent to Modal for query
    const updateId = ref(0)
    const setupEditModal = (id: number) => {
      updateId.value = id
      $emitter.emit("open-modal-product-update")
    }

    

    return {
      updateId,
      setupEditModal,

      productCategories,
      productsOrphan,
      getProductsLoading,
      getProductsError,

      productsPaging,

      helpers
    }

  }
})
