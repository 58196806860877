
import { defineComponent, inject, reactive, ref, watch } from 'vue'
import { Emitter } from 'mitt'
import { useRoute } from 'vue-router'
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import { helpers } from '@/modules/helpers'
import getElementQuery from '@/graphql/invoice/patient/get.gql'

import ElementDisplay from '@/components/element_displays/patient/Invoice.vue'

export default defineComponent({
  name: 'Invoice',
  components: {
    ElementDisplay
  },
  setup() {
    // Get global components
    const $emitter = inject('$emitter') as Emitter
    const route = useRoute()

    ////////// GET ELEMENT DATA WITH ITS ID
    const getElementParams = reactive({id: route.params.id, user: sessionStorage.getItem('id')})
    const { result: getElement, loading: getElementLoading, onResult: getElement_onResult } = useQuery(getElementQuery, getElementParams)
    const elements = useResult(getElement, null, data => data.invoices)
    const element = ref<{} | null>(null)

    watch(elements, (newValue) => {
      if (!elements.value) {
        return;
      }
      element.value = newValue[0]
    }, { immediate: true })
    
    return {
      invoice: element,
    }
  }
})
